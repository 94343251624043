<script lang="ts" setup>
import { onMounted, onUnmounted, watch } from 'vue';
import VButton from '@/components/Forms/VButton.vue';
import VCheckbox from '@/components/Forms/VCheckbox.vue';
import VDates from '@/components/Search/Dates.vue';
import VPlaces from '@/components/Search/Places.vue';
import { useCioRouter } from '@/composables/useCioRouter';
import useMenu from '@/composables/useMenu';
import screen from '@/plugins/screen';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import useFilterListStore from '@/stores/query/filter-list-store';
import useSearchBarStore from '@/stores/query/search-bar-store';
import useVehicleListStore from '@/stores/query/vehicle-list-store';
import emitter from '@/utils/emitter';

const filterListStore = useFilterListStore();
const deliveryPointStore = useDeliveryPointStore();
const deliveryTimeStore = useDeliveryTimeStore();
const searchBarStore = useSearchBarStore();
const vehicleListStore = useVehicleListStore();
const discountCodeStore = useDiscountCodeStore();

const emit = defineEmits(['onSearch']);

const props = withDefaults(
    defineProps<{
        circleButton?: boolean;
        couponAvailable?: boolean;
        extendsVisible?: boolean;
        jumpToListOnSearch?: boolean;
        summaryVisible?: boolean;
    }>(),
    {
        circleButton: false,
        couponAvailable: true,
        extendsVisible: true,
        jumpToListOnSearch: false,
        summaryVisible: true
    }
);

const cioRouter = useCioRouter();
const menu = useMenu();

onMounted(async () => {
    // filterListStore.loadFilters();
    // await deliveryPointStore.initialize();
    // await deliveryTimeStore.initialize();

    if (vehicleListStore.loadedImmediately && !props.jumpToListOnSearch) {
        handleSearch();
    }
});

onUnmounted(() => {
    searchBarStore.closeAllDialogs();
    discountCodeStore.closeDialog();
    emitter.off('currency:change', handleCurrencyChanged);
    emitter.off('locale-code:changed', handleLocaleChanged);
    emitter.off('delivery-point-pickup-dialog:opened', handleDeliveryPointPickupDialogOpened);
    emitter.off('delivery-point-drop-dialog:opened', handleDeliveryPointDropDialogOpened);
});

const handleSearch = (evt: undefined | Event = undefined) => {
    if (typeof evt !== 'undefined') {
        evt.preventDefault();
    }

    if (deliveryPointStore.getSelectedPickupDeliveryPoint === null) {
        searchBarStore.openDeliveryPointPickupDialog();
        return;
    }

    if (deliveryPointStore.getUseDifferentDropLocation && deliveryPointStore.getSelectedDropDeliveryPoint === null) {
        searchBarStore.openDeliveryPointDropDialog();
        return;
    }

    // go to vehicle list page
    if (props.jumpToListOnSearch) {
        const searchPageLink = menu.getStaticRoute('vehicleSearch');

        if (typeof searchPageLink === 'undefined') {
            console.error('searchPageLink not found');
        } else {
            // router.push(useCioRouter(null, router).prepareStaticSlug('page', 'vehicle-price-list'));
            cioRouter.prepareOpen(searchPageLink.routeRoot, searchPageLink.routeName, { slug: searchPageLink.url });
        }
    }

    // emit search event
    emit('onSearch');

    // fetch vehicles
    vehicleListStore.fetchVehicles();
};

watch(
    () => searchBarStore.getIsAnyDialogOpened,
    (isOpened) => {
        if (isOpened) {
            document.body.classList.add('search-dialog-opened');
        } else {
            document.body.classList.remove('search-dialog-opened');
        }
    }
);

const handleCurrencyChanged = () => {
    if (deliveryPointStore.getSelectedPickupDeliveryPoint === null || (deliveryPointStore.getUseDifferentDropLocation && deliveryPointStore.getSelectedDropDeliveryPoint === null)) {
        return;
    }
    vehicleListStore.fetchVehicles();
};

const handleLocaleChanged = () => {
    filterListStore.loadFilters();
};

const handleDeliveryPointPickupDialogOpened = () => {
    if (deliveryPointStore.pickupDeliveryPoints.length === 0) {
        deliveryPointStore.loadPickupDeliveryPoints();
        filterListStore.loadFilters();
    }
};

const handleDeliveryPointDropDialogOpened = () => {
    if (deliveryPointStore.dropDeliveryPoints.length === 0) {
        deliveryPointStore.loadDropDeliveryPoints(deliveryPointStore.getSelectedPickupDeliveryPoint?.id || 0);
    }
};

const handleSummaryClick = () => {
    emitter.emit('open-bottom-navigaiton-menu-search-dialog');
};

emitter.on('delivery-point-pickup-dialog:opened', handleDeliveryPointPickupDialogOpened);
emitter.on('delivery-point-drop-dialog:opened', handleDeliveryPointDropDialogOpened);
emitter.on('currency:change', handleCurrencyChanged);
emitter.on('locale-code:changed', handleLocaleChanged);
</script>

<template>
    <template v-if="screen.isMobile && vehicleListStore.getVehicles.length > 0 && summaryVisible">
        <div class="v-search-bar-summary" @click="handleSummaryClick">
            <div class="summary">
                <div class="summary-label">{{ $t('searchBar.pickupPlace') }}</div>
                <div class="summary-date">{{ deliveryTimeStore.getPickupDateTimeFullText }}</div>
                <div class="summary-place">{{ deliveryPointStore.getSelectedPickupDeliveryPoint?.name }}</div>
            </div>
            <div class="summary">
                <div class="summary-label">{{ $t('searchBar.dropPlace') }}</div>
                <div class="summary-date">{{ deliveryTimeStore.getDropDateTimeFullText }}</div>
                <div class="summary-place">
                    {{ deliveryPointStore.getSelectedDropDeliveryPoint !== null ? deliveryPointStore.getSelectedDropDeliveryPoint.name : deliveryPointStore.getSelectedPickupDeliveryPoint?.name }}
                </div>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="v-search-bar" :class="{ active: searchBarStore.getIsAnyDialogOpened }">
            <VPlaces />
            <VDates />
            <!-- <template v-show="screen.isDesktop || !searchBarStore.getIsAnyDialogOpened"> -->
            <VButton class="search-btn" icon="search-2-line" pill v-if="circleButton" @click="handleSearch" />
            <VButton class="search-btn" prepend-icon="search-2-line" :label="$t('searchBar.search')" :full-width="screen.isMobile" v-if="!circleButton" @click="handleSearch" />
            <!-- </template> -->
        </div>
        <div class="v-search-bar-bottom" v-if="extendsVisible">
            <VCheckbox :label="$t('searchBar.dropOffDifferentLocation')" :value="deliveryPointStore.getUseDifferentDropLocation" @update="deliveryPointStore.setUseDifferentDropLocation($event)" />
            <VButton v-if="couponAvailable" append-icon="gift-2-line" :color="discountCodeStore.isValid ? 'primary' : 'secondary'" variant="link" @click="discountCodeStore.openDialog()">
                {{ discountCodeStore.isValid ? $t('discountCode.usedDiscountCode', { code: discountCodeStore.code }) : $t('discountCode.useDiscountCode') }}
            </VButton>
        </div>
        <Transition name="fade">
            <div class="v-search-bar-overlay" @click="searchBarStore.closeAllDialogs()" v-if="searchBarStore.getIsAnyDialogOpened"></div>
        </Transition>
    </template>
</template>
