import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export interface Priorities {
    isExists: boolean;
    name: string;
    summary: string;
    content: string;
    items: Item[];
}

export interface Item {
    icon: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface WhatDoWeItem {
    icon: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface VisionMissionItem {
    image: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface BottomSection {
    name: string;
    content: string;
    orderRank: number;
}

export interface PStore {
    loaded: boolean;
    loading: boolean;
    data: {
        priorities: Priorities;
        whatDoWeItems: WhatDoWeItem[];
        visionMissionItems: VisionMissionItem[];
        bottomSections: BottomSection[];
    };
}

export const usePageContactStore = defineStore('page-contact', {
    actions: {
        setData(value: PStore) {
            this.$patch(value);
        },
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<PStore>) => {
                    this.loaded = true;
                    this.loading = false;
                    this.$patch(response.data.data);
                    status = true;
                })
                .catch(() => {
                    status = false;
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                    pageLoaderStore.unsubscribe(this);
                });

            return status;
        }
    },

    getters: {
        getData(state) {
            return {
                data: state.data,
                loaded: state.loaded,
                loading: state.loading
            };
        }
    },

    state: () =>
        ({
            data: {
                bottomSections: [],
                priorities: {
                    content: '',
                    isExists: false,
                    items: [],
                    name: '',
                    summary: ''
                },
                visionMissionItems: [],
                whatDoWeItems: []
            },
            loaded: false,
            loading: false
        }) as PStore
});
