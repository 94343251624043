<script lang="ts" setup>
import { useAppStore } from '@/stores/app-store';
import { onBeforeMount, onBeforeUnmount, watch } from 'vue';
import VModal from '@/components/Modal/Modal.vue';
import VTextInput from '@/components/Forms/VTextInput.vue';
import VButton from '@/components/Forms/VButton.vue';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import useReservationCreateStore from '@/stores/reservation/create-store';
import { humanReadableMoney } from '@/utils/money-format';
import VAlert from '@/components/Forms/VAlert.vue';

const appStore = useAppStore();
const discountCodeStore = useDiscountCodeStore();
const reservationCreateStore = useReservationCreateStore();

onBeforeMount(() => {});

onBeforeUnmount(() => {});

watch(
    () => discountCodeStore.isDialogOpened,
    (isOpened) => {
        if (isOpened) {
            appStore.addBackRouteBlocker({
                fn: () => {
                    discountCodeStore.closeDialog();
                },
                key: 'discount-modal'
            });
        } else {
            appStore.removeBackRouteBlocker('discount-modal');
        }
    }
);
</script>

<template>
    <VModal :active="discountCodeStore.getIsDialogOpened" @close="discountCodeStore.closeDialog()">
        <template #header>
            <h3>{{ $t('discountCode.title') }}</h3>
        </template>
        <template #default>
            <p v-if="discountCodeStore.checkMode === 'half'">
                {{ $t('discountCode.description') }}
            </p>
            <br />
            <VTextInput
                :label="$t('discountCode.codeLabel')"
                :placeholder="$t('discountCode.codePlaceholder')"
                prepend-icon="coupon-3-line"
                full-width
                :value="discountCodeStore.code"
                @input="discountCodeStore.code = $event"
                :disabled="discountCodeStore.getIsValidationLoading || discountCodeStore.isValid"
                @keyup-enter="discountCodeStore.validate()"
            /><br />
            <VTextInput
                :label="$t('discountCode.emailLabel')"
                :placeholder="$t('discountCode.emailPlaceholder')"
                type="email"
                name="email"
                prepend-icon="mail-line"
                full-width
                :value="discountCodeStore.email"
                @input="discountCodeStore.email = $event"
                :disabled="discountCodeStore.getIsValidationLoading || discountCodeStore.isValid"
                @keyup-enter="discountCodeStore.validate()"
                v-if="discountCodeStore.isEmailFieldVisible"
            />
            <template v-if="discountCodeStore.isValid">
                <hr />
                <template v-if="discountCodeStore.checkMode === 'full'">
                    <hr />
                    <template v-if="reservationCreateStore.created?.vehicle.prices.discountStatus">
                        <VAlert type="success">
                            {{ $t('discountCode.codeApplied') }}
                        </VAlert>
                        <dl v-if="discountCodeStore.payload.type === 'discount'">
                            <dt>{{ $t('discountCode.amount') }}</dt>
                            <dd>{{ humanReadableMoney(reservationCreateStore.created?.vehicle.prices.discount.totalSaving) }}</dd>
                        </dl>
                    </template>
                    <VAlert v-else type="warning">
                        {{ $t('discountCode.codeInvalidForChoose') }}
                    </VAlert>
                </template>
                <VAlert type="info" v-else>
                    {{ $t('discountCode.codeApplied') }}
                </VAlert>
            </template>
        </template>
        <template #footer>
            <VButton color="secondary" size="large" full-width @click="discountCodeStore.clear()" v-if="discountCodeStore.isValid">
                {{ $t('discountCode.clear') }}
            </VButton>
            <VButton color="secondary" size="large" full-width :loading="discountCodeStore.getIsValidationLoading" @click="discountCodeStore.validate()" v-else>
                {{ $t('discountCode.validate') }}
            </VButton>
        </template>
    </VModal>
</template>
