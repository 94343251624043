import { ref, type Ref } from 'vue';
import emitter from '@/utils/emitter';
import scrollToElement from '@/utils/scroll-to-element';

export interface ITestResult {
    isValid: boolean;
    error?: string;
}

export interface IField {
    name?: string;
    enabled?: boolean;
    test: () => ITestResult;
    error?: string;
    component?: Ref<any>;
    element?: Ref<any>;
    isValid?: boolean;
}

export interface IError {
    name: string;
    message: string;
}

export const YupTest = (schema: any, value: string): ITestResult => {
    try {
        schema.validateSync(value);
        return { error: undefined, isValid: true };
    } catch (error: any) {
        return { error: error.message || 'error', isValid: false };
    }
};

export const tcTest = (tc: string): boolean => {
    if (tc.length != 11) {
        return false;
    }
    let total = 0;
    for (let i = 0; i < 10; i++) {
        total += parseInt(tc[i]);
    }
    return total % 10 == parseInt(tc[10]);
};

export class Validator {
    // fields bir objedir ve keyleri string türünde ve value'ları IField türündedir.
    fields: IField[] = [];
    faileds = ref<IField[]>([]);
    errors = ref<Record<string, string>>({});
    isValid = ref<boolean>(false);

    constructor(fields: Record<string, IField>) {
        for (const key in fields) {
            this.fields.push({
                component: ref<any>(),
                element: ref<any>(),
                enabled: false,
                isValid: false,
                name: key,
                ...fields[key]
            });
        }

        this.isValid.value = false;

        // this.faileds = faileds;
    }

    enableValidation(name: string) {
        const field = this.fields.find((field) => field.name === name);
        if (field) {
            field.enabled = true;
        }
        this.run();
    }

    run() {
        // console.log(this.isValid)
        // console.log(this);
        this.isValid = ref<boolean>(false);
        for (const field of this.fields) {
            if (field.enabled) {
                const result = field.test();
                field.isValid = true;
                field.error = result.isValid ? undefined : result.error;
            }
        }

        this.faileds.value = this.fields.filter((field) => field.error !== undefined);
        this.errors.value = this.faileds.value.reduce((acc: Record<string, string>, field) => {
            if (field.error !== undefined && field.name) {
                acc[field.name] = field.error;
            }
            return acc;
        }, {});
        this.isValid.value = this.faileds.value.length === 0;
    }

    reset() {
        for (const field of this.fields) {
            field.error = undefined;
            field.enabled = false;
        }
        this.faileds.value = [];
        this.errors.value = ref<Record<string, string>>({}).value;
        this.isValid.value = false;
    }

    validate(): boolean {
        // tümünü enable et
        this.fields.forEach((field) => (field.enabled = true));
        this.run();
        const firsErroredField: IField | undefined = this.fields.find((field) => field.error !== undefined);

        if (firsErroredField) {
            emitter.emit('validation:error', firsErroredField);
            if (firsErroredField.component && firsErroredField.component.value) {
                try {
                    // firsErroredField.component.value?.$el?.focus();
                    // console.log(firsErroredField.name);
                    if (typeof firsErroredField.component.value.focus === 'function') {
                        firsErroredField.component.value.focus();
                    } else {
                        scrollToElement(firsErroredField.component.value.$el, 0, true).then(() => {
                            if (typeof firsErroredField.component !== 'undefined') {
                                if (typeof firsErroredField.component.value.$el.focus === 'function') {
                                    firsErroredField.component.value.$el.focus();
                                }
                            }
                        });
                    }
                } catch (err) {
                    // console.log('error scrollToElement:', err);
                }
            }
            // if (firsErroredField.element) {
            //     console.log(firsErroredField.component);
            //     firsErroredField.element.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // }
        }

        return firsErroredField === undefined;
    }

    getIsValid(name: string): boolean {
        for (const field of this.fields) {
            if (field.name === name) {
                return field.isValid || false;
            }
        }
        return false;
    }
}
