<script lang="ts" setup>
import { computed } from 'vue';

import { useRoute } from 'vue-router';

import { useCioRouter } from '@/composables/useCioRouter';
import useMenu from '@/composables/useMenu';
import { useNodeStore } from '@/stores/node-store';

import VBreadcrumbsItem from './VBreadcrumbsItem.vue';

const menu = useMenu();
const nodeStore = useNodeStore();
const cioRouter = useCioRouter();
const route = useRoute();

type IItem = {
    label: string | null;
    to: string | null;
};

const props = defineProps<{
    /**
     * Items
     * @type {Array<VBreadcrumbsItem>}
     */
    items?: Array<IItem>;
    standalone?: boolean;

    routeRoot?: string;
    routeGroup?: string;

    forBlog?: boolean;
    forAccount?: boolean;
}>();

const getItems = computed(() => {
    let list: Array<IItem> = [];
    let home = menu.getStaticRoute('homepage');
    if (home) {
        let homeUrl = cioRouter.prepareUrl(home.routeRoot, home.routeName);
        list.push({ label: home.label, to: homeUrl });
    }

    if (props.forBlog) {
        let blog = menu.getStaticRoute('blog');
        if (blog) {
            let blogUrl = cioRouter.prepareUrl(blog.routeRoot, blog.routeName);
            list.push({ label: blog.label, to: blogUrl });
        }
    }

    if (props.forAccount) {
        let account = menu.getStaticRoute('account');
        if (account) {
            let accountUrl = cioRouter.prepareUrl(account.routeRoot, account.routeName);
            list.push({ label: account.label, to: accountUrl });
        }
    }

    if (props.standalone) {
        if (nodeStore.getData.breadcrumb) {
            nodeStore.getData.breadcrumb.forEach((item) => {
                list.push({
                    label: item.name,
                    to: cioRouter.prepareUrl(props.routeRoot || 'app', props.routeGroup || 'page', { slug: item.slug })
                });
            });
        }
    }

    if (props.standalone) {
        list.push({
            label: nodeStore.getData.name,
            to: route.path
        });
        return list;
    }

    return list;
});

const classes = computed(() => ({
    'v-breadcrumbs': true
}));
</script>

<template>
    <div :class="classes">
        <ul>
            <li v-for="(item, i) in getItems" :key="i">
                <VBreadcrumbsItem :label="item.label" :to="item.to" :active="i === getItems.length - 1" />
            </li>
        </ul>
    </div>
</template>
