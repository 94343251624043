import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import { usePageLoaderStore } from '@/stores/page-loader-store';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useFilterListStore from '@/stores/query/filter-list-store';
import useSearchBarStore from '@/stores/query/search-bar-store';
import type { ApiAxiosResponse } from '@/types/general';
import type { FilterGroup, FilterItem } from '@/types/query/filter-list';
import type { Message, Vehicle, VehicleResponse } from '@/types/query/vehicle-list';
import dateFormat from '@/utils/date-format';
import emitter from '@/utils/emitter';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import { setToLocalStorage, clearLocalStorage } from '@/utils/search-store-helper';
import type { IBubbleMessage } from '@/components/BubbleMessage/BubbleMessage.vue';
import useReservationCreateStore from '@/stores/reservation/create-store';

// import { debounce } from '@/utils/debounce';

export interface VehicleListStore {
    isOfferEnabled: boolean;
    loaded: boolean;
    loadedImmediately: boolean;
    loading: boolean;
    messages: Message[];
    queryId: number;
    rentalDay: number;
    vehicles: Vehicle[];
    discountCodeIsValid: boolean;

    // stopPropagation: boolean; // its from pageLoaderStore
    // searchImmediately: boolean; // its from queryFormParams.searchImmediately
    // searchDebounce: any;
}

const useVehicleListStore = defineStore('vehicle-list-store', {
    actions: {
        clearVehicles() {
            this.vehicles = [];
            this.messages = [];
            this.isOfferEnabled = false;
            this.queryId = 0;
            this.rentalDay = 0;
            this.loaded = false;
        },
        async fetchVehicles() {
            this.loadedImmediately = false;

            if (this.loading) {
                return;
            }

            localStorage.removeItem('RESERVATION_CREATE_REQUEST_PAYLOAD');
            localStorage.removeItem('RESERVATION_CREATE_RESPONSE_AT');
            localStorage.removeItem('RESERVATION_CREATE_RESPONSE_PAYLOAD');
            localStorage.removeItem('RESERVATION_STORE_REQUEST_PAYLOAD');

            this.loading = true;
            emitter.emit('vehicle-list:loading');

            const pageLoaderStore = usePageLoaderStore();
            const deliveryPointStore = useDeliveryPointStore();
            const deliveryTimeStore = useDeliveryTimeStore();
            const searchBarStore = useSearchBarStore();
            const filterListStore = useFilterListStore();
            const discountCodeStore = useDiscountCodeStore();

            if (!deliveryTimeStore.getIsInitialized) {
                searchBarStore.openDeliveryDatePickupDialog();
                this.loading = false;
                return;
            }
            if (deliveryPointStore.selectedPickupDeliveryPointId == null) {
                // console.log('openDeliveryPointPickupDialog because deliveryPointStore.selectedPickupDeliveryPointId == null');
                searchBarStore.openDeliveryPointPickupDialog();
                this.loading = false;
                return;
            }
            if (!filterListStore.loaded) {
                filterListStore.loadFilters();
            }

            pageLoaderStore.subscribe(this);
            searchBarStore.closeAllDialogs();

            let requestOptions = {
                dropAt: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                pickupAt: dateFormat.toString(deliveryTimeStore.getPickupDateTime),
                pickupPointId: deliveryPointStore.selectedPickupDeliveryPointId
            } as {
                dropAt: string;
                dropPointId?: number;
                pickupAt: string;
                pickupPointId: number;
                discount?: {
                    code: string;
                    email: string;
                };
            };

            if (deliveryPointStore.getSelectedDropDeliveryPoint) {
                requestOptions = Object.assign(requestOptions, {
                    dropPointId: deliveryPointStore.selectedDropDeliveryPointId
                });
            }

            if (discountCodeStore.isValid) {
                requestOptions.discount = {
                    code: discountCodeStore.code,
                    email: discountCodeStore.email
                };
            }

            this.vehicles = [];
            this.messages = [];
            this.isOfferEnabled = false;
            this.queryId = 0;
            this.rentalDay = 0;

            // if (Capacitor.isNativePlatform()) {
            //     await FirebaseAnalytics.logEvent({
            //         name: 'vehicle_search',
            //         params: {
            //             dropAt: requestOptions.dropAt,
            //             dropPointId: requestOptions.dropPointId ?? requestOptions.pickupPointId,
            //             pickupAt: requestOptions.pickupAt,
            //             pickupPointId: requestOptions.pickupPointId
            //         }
            //     });
            // } else {
            //     useGtm()?.trackEvent({
            //         event: 'vehicle-list:loaded',
            //         eventAction: 'vehicle-search',
            //         eventCategory: 'vehicle-list',
            //         eventLabel: 'Vehicle list loaded with search parameters',
            //         eventParams: {
            //             dropAt: requestOptions.dropAt,
            //             dropPointId: requestOptions.dropPointId ?? requestOptions.pickupPointId,
            //             pickupAt: requestOptions.pickupAt,
            //             pickupPointId: requestOptions.pickupPointId
            //         },
            //         eventValue: 1
            //     });
            // }

            setToLocalStorage();

            api.post('/query/vehicles', requestOptions)
                .then((response: ApiAxiosResponse<VehicleResponse>) => {
                    this.vehicles = response.data.data.vehicles;
                    this.messages = response.data.data.messages;
                    this.isOfferEnabled = response.data.data.isOfferEnabled;
                    this.queryId = response.data.data.queryId;
                    this.rentalDay = response.data.data.rentalDay;
                    this.discountCodeIsValid = response.data.data.discountCodeIsValid;

                    if (!this.discountCodeIsValid && discountCodeStore.isValid) {
                        // @todo toast bilgi gerekli
                        discountCodeStore.clear();
                    }

                    if (this.vehicles.length === 0) {
                        clearLocalStorage();
                    }

                    emitter.emit('vehicle-list:loaded');
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                    pageLoaderStore.unsubscribe(this);
                    useReservationCreateStore().$reset();
                });
        },
        setLoadedImmediately(value: boolean) {
            this.loadedImmediately = value;
        }
    },

    getters: {
        getAlerts(state) {
            return state.messages.filter((message: Message) => message.type === 'alert');
        },
        getMessages(state) {
            return state.messages
                .filter((message: Message) => message.type === 'dialog')
                .map((message: Message): IBubbleMessage => {
                    return {
                        icon: message.icon || null,
                        message: message.message,
                        theme: message.theme,
                        title: message.title || null
                    };
                });
        },
        getVehicles(state) {
            if (state.loading) {
                return [];
            }
            // let vehicles = ;
            const filterListStore = useFilterListStore();

            const usedGroupCodes = filterListStore.groups
                .filter((group: FilterGroup) => {
                    return group.items.find((item: FilterItem) => item.isSelected) !== undefined;
                })
                .map((group: FilterGroup) => group.code);

            const usedFilterIds = [] as string[];
            filterListStore.groups.map((group: FilterGroup) => {
                return group.items
                    .filter((item: FilterItem) => item.isSelected)
                    .map((item: FilterItem) => {
                        usedFilterIds.push(group.code + '-' + item.id);
                    });
            });

            return state.vehicles.filter((vehicle: Vehicle) => {
                if (usedGroupCodes.includes('group') && usedFilterIds.includes('group-' + vehicle.group.id) === false) {
                    return false;
                }
                if (usedGroupCodes.includes('transmissionType') && usedFilterIds.includes('transmissionType-' + vehicle.transmissionType.id) === false) {
                    return false;
                }
                if (usedGroupCodes.includes('fuelType') && usedFilterIds.includes('fuelType-' + vehicle.fuelType.id) === false) {
                    return false;
                }
                return true;
            });
        },
        isLoaded(state) {
            return state.loaded;
        },
        isLoading(state) {
            return state.loading;
        }
    },

    state: () =>
        ({
            discountCodeIsValid: false,
            isOfferEnabled: false,
            loaded: false,
            loadedImmediately: false,
            loading: false,
            messages: [],
            queryId: 0,
            rentalDay: 0,
            vehicles: []
        }) as VehicleListStore
});

export default useVehicleListStore;
