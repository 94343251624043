import blogData from '@/definitions/blog-slugs.json';
import localeData from '@/definitions/locales.json';
import pageData from '@/definitions/page-slugs.json';
import paginationsData from '@/definitions/pagination-slugs.json';
import settings from '@/definitions/settings.json';
import staticData from '@/definitions/static-slugs.json';

// import type { ILocale } from '@/types/locale';
// import type { IStaticRouteTranslation } from '@/types/route';
// import type { IDynamicSlugPath, ISlugList, IStaticSlug } from '@/types/slug';
// import type { Menu } from '@/types/settings/menu';

type ValidLocalePaths = (typeof settings.enabledLocales)[number];

interface IDynamicSlugPath {
    path: string;
    sitemapGroup: null | string;
    ssgRender: boolean;
    updatedAt: string;
}

interface ILocale {
    id: number;
    code: string;
    defaultForCountryCode: string;
    isDefault: boolean;
    isActive: boolean;
    nativeName: string;
    path: ValidLocalePaths;
}

interface ISlugList {
    [key: string]: {
        locale: string;
        sitemapGroup: null | string;
        slug: string;
        updatedAt: string;
        ssgRender: boolean;
    }[];
}

interface IStaticSlug {
    locale: string;
    nodeGroup: string;
    sitemapGroup: null | string;
    slug: string;
    template: string;
    updatedAt: string;
    ssgRender: boolean;
}
interface IStaticRouteTranslation {
    [key: string]: {
        [key: string]: {
            [key: string]: string;
        };
    };
}
interface IPaginationSlug {
    locale: string;
    slug: string;
    updatedAt: string;
    nodeGroup: string;
    isRoot: boolean;
    name: string;
    page: number;
}

const locales: ILocale[] = localeData.locales;
const enabledLocales: string[] = localeData.enabledPaths;

const blogs: ISlugList | never = Array.isArray(blogData) ? {} : blogData;
const pages: ISlugList | never = Array.isArray(pageData) ? {} : pageData;
const statics: IStaticSlug[] = staticData;
const paginations: IPaginationSlug[] = paginationsData;

const staticRouteTranslations: IStaticRouteTranslation = {};

for (const staticSlug of statics) {
    if (typeof staticRouteTranslations[staticSlug.locale] === 'undefined') {
        staticRouteTranslations[staticSlug.locale] = {};
    }

    if (typeof staticRouteTranslations[staticSlug.locale][staticSlug.nodeGroup] === 'undefined') {
        staticRouteTranslations[staticSlug.locale][staticSlug.nodeGroup] = {};
    }

    if (staticSlug.nodeGroup === 'page' && staticSlug.template === 'home') {
        continue;
    }

    staticRouteTranslations[staticSlug.locale][staticSlug.nodeGroup][staticSlug.template] = staticSlug.slug;
}

const getBlogPaginationPaths = async (locale: string, pathTemplate: string): Promise<IDynamicSlugPath[]> => {
    return paginations
        .filter((pagination) => pagination.locale === locale)
        .filter((pagination) => pagination.slug === 'blog')
        .map((pagination) => {
            return {
                path: pathTemplate.replace(':page(\\d+)', pagination.page.toString()),
                sitemapGroup: 'blog',
                ssgRender: true,
                updatedAt: pagination.updatedAt
            };
        });
};

const getBlogCategoryPaginationPaths = async (locale: string, pathTemplate: string): Promise<IDynamicSlugPath[]> => {
    return paginations
        .filter((pagination) => pagination.locale === locale)
        .filter((pagination) => pagination.slug !== 'blog')
        .map((pagination) => {
            return {
                path: pathTemplate.replace(':slug', pagination.slug).replace(':page(\\d+)', pagination.page.toString()),
                sitemapGroup: pagination.slug,
                ssgRender: true,
                updatedAt: pagination.updatedAt
            };
        });
};

const getBlogSlugPaths = async (locale: string, pathTemplate: string): Promise<IDynamicSlugPath[]> => {
    if (typeof blogs[locale] === 'undefined') {
        return [];
    }

    return blogs[locale].map((blog) => {
        return {
            // @ts-ignore
            path: pathTemplate.replace(':slug', blog.slug),
            sitemapGroup: blog.sitemapGroup,
            ssgRender: blog.ssgRender,
            updatedAt: blog.updatedAt
        };
    });
};

const getDynamicPageSlugPaths = async (locale: string, pathTemplate: string): Promise<IDynamicSlugPath[]> => {
    if (typeof pages[locale] === 'undefined') {
        return [];
    }

    return pages[locale].map((page) => {
        return {
            // @ts-ignore
            path: pathTemplate.replace(':slug', page.slug),
            sitemapGroup: page.sitemapGroup,
            ssgRender: page.ssgRender,
            updatedAt: page.updatedAt
        };
    });
};

// static pages for index pages
const getStaticPageSlugPaths = async (locale: string, pathTemplate: string): Promise<IDynamicSlugPath[]> => {
    return (
        statics
            .filter((page) => page.locale === locale && page.nodeGroup === 'page' && page.template !== 'home')
            // .filter((page) => page.locale === locale && page.sitemapGroup !== null && page.nodeGroup === 'page' && page.template !== 'home')
            .map((page) => {
                return {
                    path: pathTemplate.replace(':slug', page.slug),
                    sitemapGroup: page.sitemapGroup,
                    ssgRender: page.ssgRender,
                    updatedAt: page.updatedAt
                };
            })
    );
};

const getStaticPageData = async (locale: string, path: string): Promise<{ sitemapGroup: string; ssgRender: boolean; updatedAt: string } | null> => {
    const page = statics.find((page) => page.locale === locale && page.sitemapGroup !== null && page.slug === path);

    if (typeof page === 'undefined' || page.sitemapGroup === null) {
        return null;
    }

    return {
        sitemapGroup: page.sitemapGroup,
        ssgRender: page.ssgRender,
        updatedAt: page.updatedAt
    };
};

const documentNodeIds: { [key: string]: number } = settings.documentNodeIds;
const getDocumentNodeId = (key: string): number => {
    return documentNodeIds[key];
};

const enabledCurrencies: string[] = settings.enabledCurrencies;
const defaultCurrency: string = settings.defaultCurrency;
// const defaultLocale: string = settings.defaultLocale;
// const menuHeaderItems: Menu[] = settings.menus.header;
// const menuFooterCorporateItems: Menu[] = settings.menus.footer.corporate;
// const menuFooterSeoItems: Menu[] = settings.menus.footer.seo;
// const menuFooterPoliciesItems: Menu[] = settings.menus.footer.policies;

export {
    defaultCurrency,
    enabledCurrencies,
    enabledLocales,
    getBlogCategoryPaginationPaths,
    getBlogPaginationPaths,
    getBlogSlugPaths,
    getDocumentNodeId,
    getDynamicPageSlugPaths,
    getStaticPageData,
    getStaticPageSlugPaths,
    type IDynamicSlugPath,
    type ILocale,
    locales,
    staticRouteTranslations
};
