import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

export type IBlogNodeCategoryNode = {
    color: string;
    name: string;
    slug: string;
};
export type IBlogNode = {
    category: IBlogNodeCategoryNode;
    image: null | string;
    id: number;
    name: string;
    publishedAt: string;
    slug: string;
    summary: null | string;
    template: string;
};
export type IBlogCategoryNode = {
    image: null | string;
    name: string;
    orderRank: number;
    slug: string;
    summary: null | string;
    template: string;
};

export type IBlogPagination = {
    remainingPageCount: number;
    totalCount: number;
    totalPageCount: number;
    viewedItemRange: number[];
    viewedPage: number;
};

export interface IBlogHomeTemplateContentResponse {
    categories: IBlogCategoryNode[];
    blogs: IBlogNode[];
    pagination: IBlogPagination;
}

export interface PStore extends IBlogHomeTemplateContentResponse {
    currentPage: number;
    loaded: boolean;
    loading: boolean;
}
export const useBlogHomeStore = defineStore('blog-home', {
    actions: {
        setData(value: PStore) {
            this.$patch(value);
        },
        async setFormApi(route: RouteLocationNormalizedLoaded): Promise<boolean> {
            const currentPage = parseInt(route.params?.page as string) || 1;

            // if (currentPage === this.currentPage && this.loaded) {
            //     return true;
            // }

            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', Object.assign(nodeStore.getTemplateContentPayload, { page: currentPage }))
                .then((response: ApiAxiosResponse<IBlogHomeTemplateContentResponse>) => {
                    this.$patch(response.data.data);
                    this.$patch({
                        currentPage: currentPage,
                        loaded: true,
                        loading: false
                    });
                    status = true;
                })
                .catch(() => {
                    status = false;
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                    pageLoaderStore.unsubscribe(this);
                });

            return status;
        }
    },

    getters: {
        getData(state) {
            return {
                blogs: state.blogs,
                categories: state.categories,
                loaded: state.loaded,
                loading: state.loading,
                pagination: state.pagination
            };
        }
    },

    state: () =>
        ({
            blogs: [],
            categories: [],
            currentPage: 1,
            loaded: false,
            loading: false,
            pagination: {
                remainingPageCount: 0,
                totalCount: 0,
                totalPageCount: 0,
                viewedItemRange: [],
                viewedPage: 0
            }
        }) as PStore
});
