import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import useSearchBarStore from '@/stores/query/search-bar-store';
import type { DeliveryPoint, DeliveryPointDetails, DeliveryPointGroup, DeliveryPointState } from '@/types/query/delivery-point';
import emitter from '@/utils/emitter';

export interface DeliveryPointStoreState {
    initialized: boolean;

    pickupDeliveryPointsState: DeliveryPointState;
    pickupDeliveryPoints: DeliveryPoint[];
    pickupDeliveryPointGroups: DeliveryPointGroup[];
    pickupDeliveryPointGroupsFilter: string;
    selectedPickupDeliveryPoint: DeliveryPoint | null;
    selectedPickupDeliveryPointId: number | null;
    selectedPickupDeliveryPointDetails: DeliveryPointDetails | null;

    dropDeliveryPointsState: DeliveryPointState;
    dropDeliveryPoints: DeliveryPoint[];
    dropDeliveryPointGroups: DeliveryPointGroup[];
    dropDeliveryPointGroupsFilter: string;
    selectedDropDeliveryPoint: DeliveryPoint | null;
    selectedDropDeliveryPointId: number | null;
    selectedDropDeliveryPointDetails: DeliveryPointDetails | null;

    useDifferentDropLocation: boolean;

    hasManualSelected: boolean;
    // requestedPickupPointId: number | null;
    // requestedDropPointId: number | null;
}

const typeCodeToIcon = (typeCode: string): string => {
    switch (typeCode) {
        case 'airport':
            return 'plane-fill';
        case 'hotel':
            return 'hotel-bed-line';
        case 'office':
            return 'building-line';
        case 'address':
            return 'map-pin-range-line';
        case 'bus-station':
            return 'bus-fill';
        default:
            return 'map-pin-line';
    }
};

const fetchDeliveryPoints = async (
    pickupPointId = 0
): Promise<{
    deliveryPoints: DeliveryPoint[];
    deliveryPointGroups: DeliveryPointGroup[];
}> => {
    const payload = Object.assign({}, pickupPointId !== 0 ? { pickupPointId } : {});

    const response = await api.post('/query/delivery-points', payload);
    const deliveryPoints: DeliveryPoint[] = response.data.data.deliveryPoints;
    // prepare KEYWORDS for search
    deliveryPoints.forEach((deliveryPoint: DeliveryPoint) => {
        deliveryPoint.keywords =
            deliveryPoint.keywords +
            ',' +
            deliveryPoint.keywords.toLocaleLowerCase() +
            ',' +
            deliveryPoint.keywords.toLocaleUpperCase() +
            ',' +
            deliveryPoint.code +
            ',' +
            deliveryPoint.name +
            ',' +
            deliveryPoint.name.toLocaleLowerCase() +
            ',' +
            deliveryPoint.name.toLocaleUpperCase() +
            ',' +
            deliveryPoint.cityName +
            ',' +
            deliveryPoint.cityName.toLocaleLowerCase() +
            ',' +
            deliveryPoint.cityName.toLocaleUpperCase();
        deliveryPoint.icon = typeCodeToIcon(deliveryPoint.typeCode);
    });
    const deliveryPointGroups: DeliveryPointGroup[] = [];
    // group delivery points by city
    deliveryPoints.forEach((deliveryPoint: DeliveryPoint) => {
        const groupIndex = deliveryPointGroups.findIndex((group: DeliveryPointGroup) => group.name === deliveryPoint.cityName);
        if (groupIndex === -1) {
            deliveryPointGroups.push({
                deliveryPoints: [deliveryPoint],
                name: deliveryPoint.cityName
            });
        } else {
            deliveryPointGroups[groupIndex].deliveryPoints.push(deliveryPoint);
        }
    });
    return {
        deliveryPointGroups,
        deliveryPoints
    };
};
const filterDeliveryPointGroups = (filter: string, deliveryPointGroups: DeliveryPointGroup[]): DeliveryPointGroup[] => {
    // filter = filter.toLowerCase();
    const filteredGroups: DeliveryPointGroup[] = [];
    // console.log(filter);
    deliveryPointGroups.forEach((group: DeliveryPointGroup) => {
        const filteredDeliveryPoints = group.deliveryPoints.filter((deliveryPoint: DeliveryPoint) => {
            // console.log(deliveryPoint.keywords);
            return deliveryPoint.keywords.includes(filter);
        });
        if (filteredDeliveryPoints.length) {
            filteredGroups.push({
                deliveryPoints: filteredDeliveryPoints,
                name: group.name
            });
        }
    });
    return filteredGroups;
};
const fetchDeliveryPointDetails = async (deliveryPointId: number): Promise<DeliveryPointDetails> => {
    const response = await api.get(`/query/delivery-points/${deliveryPointId}`);
    return response.data.data.deliveryPoint;
};

const useDeliveryPointStore = defineStore('delivery-point-store', {
    actions: {
        // async initialize(){
        //     if(this.initialized){ return; }
        //     await this.loadPickupDeliveryPoints();
        //     this.initialized = true;
        // },
        async loadDropDeliveryPoints(pickupPointId: number): Promise<void> {
            this.dropDeliveryPointsState.loading = true;
            return new Promise((resolve) => {
                fetchDeliveryPoints(pickupPointId)
                    .then((result) => {
                        this.dropDeliveryPoints = result.deliveryPoints;
                        this.dropDeliveryPointGroups = result.deliveryPointGroups;
                        this.dropDeliveryPointsState.loaded = true;
                        this.dropDeliveryPointsState.loading = false;

                        if (this.selectedDropDeliveryPointId !== null) {
                            const point = this.dropDeliveryPoints.find((deliveryPoint: DeliveryPoint) => deliveryPoint.id === this.selectedDropDeliveryPointId);
                            if (point) {
                                this.selectDropDeliveryPoint(point);
                            } else {
                                this.selectedDropDeliveryPointId = null;
                            }
                        }
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        },
        async loadPickupDeliveryPoints(): Promise<void> {
            if (this.pickupDeliveryPointsState.loading) return;
            this.pickupDeliveryPointsState.loading = true;
            return new Promise((resolve, reject) => {
                fetchDeliveryPoints()
                    .then((result) => {
                        this.pickupDeliveryPoints = result.deliveryPoints;
                        this.pickupDeliveryPointGroups = result.deliveryPointGroups;
                        this.pickupDeliveryPointsState.loaded = true;
                        this.pickupDeliveryPointsState.loading = false;

                        if (this.selectedPickupDeliveryPointId !== null) {
                            const point = this.pickupDeliveryPoints.find((deliveryPoint: DeliveryPoint) => deliveryPoint.id === this.selectedPickupDeliveryPointId);
                            if (point) {
                                this.selectPickupDeliveryPoint(point);
                            } else {
                                this.selectedPickupDeliveryPointId = null;
                            }
                        }
                        // useDeliveryTimeStore().initialize();
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        },
        selectDropDeliveryPoint(deliveryPoint: DeliveryPoint, silent?: boolean): void {
            this.hasManualSelected = true;

            if (this.selectedDropDeliveryPoint !== null && this.selectedDropDeliveryPoint.id === deliveryPoint.id) {
                return;
            }

            this.selectedDropDeliveryPoint = deliveryPoint;
            this.selectedDropDeliveryPointId = deliveryPoint.id;

            fetchDeliveryPointDetails(deliveryPoint.id).then((details) => {
                this.selectedPickupDeliveryPointDetails = details;
                this.selectedDropDeliveryPointDetails = details;
                emitter.emit('drop-delivery-point-details:changed', details);
            });
        },
        selectPickupDeliveryPoint(deliveryPoint: DeliveryPoint, silent?: boolean): void {
            this.hasManualSelected = true;

            if (this.selectedPickupDeliveryPoint !== null && this.selectedPickupDeliveryPoint.id === deliveryPoint.id) {
                return;
            }

            this.selectedPickupDeliveryPoint = deliveryPoint;
            this.selectedPickupDeliveryPointId = deliveryPoint.id;

            fetchDeliveryPointDetails(deliveryPoint.id).then((details) => {
                this.selectedPickupDeliveryPointDetails = details;
                emitter.emit('pickup-delivery-point-details:changed', details);
                if (!this.useDifferentDropLocation) {
                    emitter.emit('drop-delivery-point-details:changed', details);
                }
            });

            // clear drop points
            this.dropDeliveryPoints = [];
            this.dropDeliveryPointGroups = [];
            this.selectedDropDeliveryPoint = null;
            this.selectedDropDeliveryPointId = null;
            this.selectedDropDeliveryPointDetails = null;
        },
        setDropDeliveryPointGroupsFilter(filter: string): void {
            this.dropDeliveryPointGroupsFilter = filter;
        },
        setHasManualSelected(value: boolean): void {
            this.hasManualSelected = value;
        },
        setPickupDeliveryPointGroupsFilter(filter: string): void {
            this.pickupDeliveryPointGroupsFilter = filter;
        },
        setQueryParams(pickupPoint: DeliveryPointDetails | null, dropPoint: DeliveryPointDetails | null): void {
            if (this.hasManualSelected) return;
            if (pickupPoint !== null) {
                this.selectedPickupDeliveryPointId = pickupPoint.id;
                this.selectedPickupDeliveryPointDetails = pickupPoint;
                this.selectedPickupDeliveryPoint = {
                    cityId: pickupPoint.cityId,
                    cityName: pickupPoint.cityName,
                    code: pickupPoint.code,
                    icon: typeCodeToIcon(pickupPoint.typeCode),
                    id: pickupPoint.id,
                    isDropEnabled: true,
                    isPickupEnabled: true,
                    keywords: '',
                    name: pickupPoint.name,
                    orderRank: 0,
                    typeCode: pickupPoint.typeCode
                };
            }
            if (dropPoint !== null) {
                this.selectedDropDeliveryPointId = dropPoint.id;
                this.selectedDropDeliveryPointDetails = dropPoint;
                this.selectedDropDeliveryPoint = {
                    cityId: dropPoint.cityId,
                    cityName: dropPoint.cityName,
                    code: dropPoint.code,
                    icon: typeCodeToIcon(dropPoint.typeCode),
                    id: dropPoint.id,
                    isDropEnabled: true,
                    isPickupEnabled: true,
                    keywords: '',
                    name: dropPoint.name,
                    orderRank: 0,
                    typeCode: dropPoint.typeCode
                };
                this.useDifferentDropLocation = true;
            }
        },
        setUseDifferentDropLocation(useDifferentDropLocation: boolean): void {
            const searchbarStore = useSearchBarStore();
            this.useDifferentDropLocation = useDifferentDropLocation;
            if (this.useDifferentDropLocation) {
                //this.loadDropDeliveryPoints(this.selectedPickupDeliveryPoint?.id || 0);
                if (this.selectedPickupDeliveryPoint === null) {
                    searchbarStore.openDeliveryPointPickupDialog();
                } else if (this.selectedDropDeliveryPoint === null || this.selectedPickupDeliveryPoint === this.selectedDropDeliveryPoint) {
                    searchbarStore.openDeliveryPointDropDialog();
                }
            } else {
                if (this.selectedDropDeliveryPoint !== null && this.selectedPickupDeliveryPoint !== null) {
                    this.selectDropDeliveryPoint(this.selectedPickupDeliveryPoint);
                }
                if (this.selectedPickupDeliveryPointDetails !== null) {
                    emitter.emit('drop-delivery-point-details:changed', this.selectedPickupDeliveryPointDetails);
                }
            }
        }
    },
    getters: {
        getDropDeliveryPointGroupsFilter(state): string {
            return state.dropDeliveryPointGroupsFilter;
        },
        getDropDeliveryPointsState(state): DeliveryPointState {
            return state.dropDeliveryPointsState;
        },
        getFilteredDropDeliveryPointGroups(state): DeliveryPointGroup[] {
            return filterDeliveryPointGroups(state.dropDeliveryPointGroupsFilter, state.dropDeliveryPointGroups);
        },
        // FILTERED GROUPS
        getFilteredPickupDeliveryPointGroups(state): DeliveryPointGroup[] {
            return filterDeliveryPointGroups(state.pickupDeliveryPointGroupsFilter, state.pickupDeliveryPointGroups);
        },
        // FILTER STRING
        getPickupDeliveryPointGroupsFilter(state): string {
            return state.pickupDeliveryPointGroupsFilter;
        },
        // DELIVERY POINTS STATE
        getPickupDeliveryPointsState(state): DeliveryPointState {
            return state.pickupDeliveryPointsState;
        },
        getSelectedDropDeliveryPoint(state): DeliveryPoint | null {
            return state.selectedDropDeliveryPoint;
        },
        getSelectedDropDeliveryPointDetails(state): DeliveryPointDetails | null {
            return state.selectedDropDeliveryPointDetails;
        },
        // SELECTED DELIVERY POINT
        getSelectedPickupDeliveryPoint(state): DeliveryPoint | null {
            return state.selectedPickupDeliveryPoint;
        },
        // SELECTED DELIVERY POINT DETAILS
        getSelectedPickupDeliveryPointDetails(state): DeliveryPointDetails | null {
            return state.selectedPickupDeliveryPointDetails;
        },
        // USE DIFFERENT DROP LOCATION
        getUseDifferentDropLocation(state): boolean {
            if (state.selectedPickupDeliveryPointDetails !== null) {
                if (state.selectedPickupDeliveryPointDetails.rules !== null) {
                    if (!state.selectedPickupDeliveryPointDetails.rules.isDifferentDropEnabled) {
                        return false;
                    }
                }
            }
            return state.useDifferentDropLocation;
        }
    },
    state: (): DeliveryPointStoreState => ({
        dropDeliveryPointGroups: [],
        dropDeliveryPointGroupsFilter: '',
        dropDeliveryPoints: [],
        dropDeliveryPointsState: {
            loaded: false,
            loading: false
        },
        hasManualSelected: false,
        initialized: false,
        pickupDeliveryPointGroups: [],
        pickupDeliveryPointGroupsFilter: '',
        pickupDeliveryPoints: [],
        pickupDeliveryPointsState: {
            loaded: false,
            loading: false
        },
        selectedDropDeliveryPoint: null,
        selectedDropDeliveryPointDetails: null,
        selectedDropDeliveryPointId: null,
        selectedPickupDeliveryPoint: null,
        selectedPickupDeliveryPointDetails: null,
        selectedPickupDeliveryPointId: null,
        useDifferentDropLocation: false
        // requestedPickupPointId: null,
        // requestedDropPointId: null,
    })
});

export default useDeliveryPointStore;
