import { defineStore } from 'pinia';
import { defaultCurrency, enabledCurrencies } from '@/definitions';
import { setApiCurrency } from '@/plugins/axios';
import emitter from '@/utils/emitter';

export type ICurrency = {
    code: string;
    name: string;
    symbol: string;
};

interface PStore {
    currency: ICurrency;
    currencyList: ICurrency[];
    loaded: boolean;
}

const codeToCurrency = (code: string): ICurrency => {
    switch (code) {
        case 'TRY':
            return {
                code: 'TRY',
                name: 'Turkish Lira',
                symbol: '₺'
            };
        case 'USD':
            return {
                code: 'USD',
                name: 'US Dollar',
                symbol: '$'
            };
        case 'EUR':
            return {
                code: 'EUR',
                name: 'Euro',
                symbol: '€'
            };
        case 'GBP':
            return {
                code: 'GBP',
                name: 'British Pound',
                symbol: '£'
            };
        case 'CHF':
            return {
                code: 'CHF',
                name: 'Swiss Franc',
                symbol: 'CHF'
            };

        default:
            return {
                code: 'TRY',
                name: 'Turkish Lira',
                symbol: '₺'
            };
    }
};
const useCurrencyStore = defineStore('currency-store', {
    actions: {
        initialize() {
            if (this.loaded) {
                return;
            }
            let localStoredCurrencyCode = localStorage.getItem('currency');
            if (!localStoredCurrencyCode) {
                localStoredCurrencyCode = defaultCurrency;
            }
            this.currency = codeToCurrency(localStoredCurrencyCode);
            this.currencyList = enabledCurrencies.map((code) => codeToCurrency(code));
            setApiCurrency(this.currency.code);
        },

        setActiveCurrency(currency: ICurrency) {
            const hasChange = currency.code !== this.currency.code;
            this.currency = currency;
            localStorage.setItem('currency', currency.code);
            setApiCurrency(this.currency.code);

            if (hasChange) {
                emitter.emit('currency-code:changed');
            }
        },

        setActiveCurrencyFromCode(code: string) {
            this.setActiveCurrency(codeToCurrency(code));
        }
    },

    getters: {
        getCurrency(state) {
            return state.currency;
        },
        getCurrencyList(state) {
            return state.currencyList;
        }
    },

    state: () =>
        ({
            currency: {} as ICurrency,
            currencyList: [] as ICurrency[],
            loaded: false
        }) as PStore
});

export default useCurrencyStore;
