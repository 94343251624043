<script lang="ts" setup>
import { computed } from 'vue';
import CurrencySwitcher from '@/components/CurrencySwitcher/Horizontal.vue';
import VNewsletterSubscribe from '@/components/Newsletter/Subscribe.vue';
import useMenu from '@/composables/useMenu';
import appSettings from '@/definitions/settings.json';
import screen from '@/plugins/screen';
import { useAppStore } from '@/stores/app-store';

const appStore = useAppStore();
const locale = computed(() => appStore.locale.path);

const { getFooterCorporateMenuItems, getFooterSeoMenuItems, getFooterPoliciesMenuItems } = useMenu();

const companyDescriptions: { [key: string]: string } = appSettings.company.description;
const companyDescription = computed(() => (typeof companyDescriptions[locale.value] === 'undefined' ? appSettings.company.description.default : companyDescriptions[locale.value]));

const year = new Date().getFullYear();
</script>

<template>
    <footer class="v-footer">
        <div class="container">
            <div class="top">
                <div class="l">
                    <div class="logo">
                        <img v-cto="{ name: 'home', root: 'app' }" alt="Elitcar" :src="`${appStore.cdnUrl}/images/logo/black.svg`" loading="lazy" />
                    </div>
                    <div class="slg">
                        {{ companyDescription }}
                    </div>
                    <div class="m">
                        <ul>
                            <template>
                                <li v-for="(item, i) in getFooterCorporateMenuItems" :key="i">
                                    <router-link :to="item.url">{{ item.label }}</router-link>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="r">
                    <VNewsletterSubscribe />

                    <!--                    <div class="b">-->
                    <!--                        <VButton color="secondary" prepend-icon="apple-fill" pill full-width>App Store</VButton>-->
                    <!--                        <VButton color="secondary" prepend-icon="google-play-fill" pill full-width>Google Play</VButton>-->
                    <!--                    </div>-->

                    <CurrencySwitcher v-if="screen.isDesktop" />
                </div>
            </div>
            <div class="mid">
                <div class="box" v-for="(item, itemIndex) in getFooterSeoMenuItems" :key="itemIndex">
                    <div class="t">
                        <span v-if="item.url === ''" v-text="item.label" />
                        <router-link :to="item.url" v-else v-text="item.label" />
                    </div>
                    <ul v-if="item.childs.length > 0">
                        <li v-for="(child, childIndex) in item.childs" :key="childIndex">
                            <router-link :to="child.url" v-text="child.label" />
                        </li>
                    </ul>
                </div>

                <!-- <ul>
                    <template :key="i" v-for="(item, i) in getFooterSeoMenuItems">
                        <li>
                            <router-link :to="item.url">{{ item.label }}</router-link>
                        </li>
                    </template>
                </ul> -->
            </div>
            <div class="bottom">
                <div>© {{ year }} Elitcar. All rights reserved.</div>
                <ul>
                    <template :key="i" v-for="(item, i) in getFooterPoliciesMenuItems">
                        <li>
                            <router-link :to="item.url">{{ item.label }}</router-link>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </footer>
</template>
