<script lang="ts" setup>
import { onMounted } from 'vue';

import CDialogProxy from '@/components/CDialogProxy.vue';
import VPlaceDialog from '@/components/Search/PlaceDialog.vue';
import VPlaceSlot from '@/components/Search/PlaceSlot.vue';
import screen from '@/plugins/screen';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useSearchBarStore from '@/stores/query/search-bar-store';

const deliveryPointStore = useDeliveryPointStore();
const searchBarStore = useSearchBarStore();

onMounted(() => {});
</script>

<template>
    <div class="v-search-places">
        <div class="inputs">
            <VPlaceSlot
                tag="pickup"
                :label="deliveryPointStore.getUseDifferentDropLocation ? $t('searchBar.pickupPlace') : $t('searchBar.pickupDropPlace')"
                @click="searchBarStore.openDeliveryPointPickupDialog()"
            />
            <VPlaceSlot tag="drop" :label="$t('searchBar.dropPlace')" @click="searchBarStore.openDeliveryPointDropDialog()" v-if="deliveryPointStore.getUseDifferentDropLocation" />
        </div>

        <CDialogProxy>
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VPlaceDialog
                    v-if="searchBarStore.getIsDeliveryPointPickupDialogOpened"
                    tag="pickup"
                    :label="deliveryPointStore.getUseDifferentDropLocation ? $t('searchBar.pickupPlace') : $t('searchBar.pickupDropPlace')"
                />
            </transition>
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VPlaceDialog v-if="searchBarStore.getIsDeliveryPointDropDialogOpened && deliveryPointStore.getUseDifferentDropLocation" tag="drop" :label="$t('searchBar.dropPlace')" />
            </transition>
        </CDialogProxy>
    </div>
</template>
