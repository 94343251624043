<script lang="ts" setup>
import VButton from '@/components/Forms/VButton.vue';
import useAuthModalStore from '@/stores/auth/modal-store';

const props = defineProps<{
    vertical?: boolean;
}>();

const authModalStore = useAuthModalStore();
</script>

<template>
    <div class="v-auth-buttons" :class="{ vertical: props.vertical }">
        <VButton variant="tonal" color="secondary" :label="$t('auth.login.title')" @click="authModalStore.openLoginModal" :full-width="props.vertical" />
        <VButton :label="$t('auth.register.title')" @click="authModalStore.openRegisterModal" :full-width="props.vertical" />
    </div>
</template>
