<script setup lang="ts">
import { computed, onMounted, type Ref, ref, watch } from 'vue';
import { EffectFade, Pagination } from 'swiper/modules';
import type { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import VButton from '@/components/Forms/VButton.vue';
import VIcon from '@/components/UI/VIcon.vue';

export interface IBubbleMessage {
    icon: null | string;
    message: string;
    title: null | string;
    theme: string;
    isDisplayed?: boolean;
}

const props = defineProps<{
    active: boolean;
    messages: IBubbleMessage[];
}>();

const emit = defineEmits(['close']);

// const isLoaded = ref(false);
const swiperRef = ref<SwiperClass | undefined>(undefined);

const internalMessages: Ref<IBubbleMessage[]> = ref([]);

const activeIndex = computed(() => {
    return swiperRef.value?.realIndex ?? 0;
});

const activeType = computed(() => {
    if (props.messages.length === 0) {
        return 'info';
    }
    return props.messages[activeIndex.value].theme;
});

const activeIcon = computed(() => {
    if (props.messages.length > 0 && props.messages[activeIndex.value].icon !== null) {
        return props.messages[activeIndex.value].icon ?? 'information-line';
    }
    switch (activeType.value) {
        case 'success':
            return 'checkbox-circle-fill';
        case 'info':
            return 'information-line';
        case 'warning':
            return 'alert-fill';
        case 'error':
            return 'spam-2-fill';
    }
    return 'information-line';
});

const classes = computed(() => {
    return {
        'v-bubble-message': true,
        // 'v-bubble-message--loaded': isLoaded.value,
        [`type-${activeType.value}`]: true
    };
});

onMounted(() => {
    updateInternalMessages();
});

watch(
    () => props.messages,
    () => {
        updateInternalMessages();
    }
);

watch(
    activeIndex,
    (activeIndexValue) => {
        if (typeof internalMessages.value[activeIndexValue] !== 'undefined') {
            internalMessages.value[activeIndexValue].isDisplayed = true;
        }
    },
    { immediate: true }
);

const updateInternalMessages = () => {
    internalMessages.value = props.messages.map((message, index) => Object.assign({ isDisplayed: index === 0 }, message));
};

const handleClose = () => {
    if (internalMessages.value.filter((m) => m.isDisplayed === false).length > 0) {
        handleNext();
        return;
    }

    emit('close');
};

const onSwiperInit = (swiper: SwiperClass) => {
    swiperRef.value = swiper;
};
const handlePrev = () => {
    if (typeof swiperRef.value === 'undefined') {
        return;
    }

    swiperRef.value.slidePrev();
};
const handleNext = () => {
    if (typeof swiperRef.value === 'undefined') {
        return;
    }

    swiperRef.value.slideNext();
};
</script>

<template>
    <Teleport to="#app">
        <Transition name="to-up">
            <div :class="classes" v-if="props.active">
                <div class="v-bubble-message-wrapper">
                    <!-- add custom navigation -->
                    <Swiper :modules="[Pagination, EffectFade]" :space-between="30" :pagination="true" :effect="'fade'" :fadeEffect="{ crossFade: true }" :loop="true" @init="onSwiperInit">
                        <template v-for="(message, index) in internalMessages" :key="index">
                            <swiper-slide>
                                <div class="v-bubble-message-content">
                                    <div class="v-bubble-message-title" v-if="message.title !== null">
                                        <span>{{ message.title }}</span>
                                    </div>
                                    <div class="v-bubble-message-message" v-html="message.message" />
                                </div>
                            </swiper-slide>
                        </template>
                    </Swiper>
                    <div class="v-bubble-message-navigation" v-if="internalMessages.length > 1">
                        <VButton icon="arrow-left-line" variant="text" pill color="secondary" @click="handlePrev" />
                        <VButton icon="arrow-right-line" variant="text" pill color="secondary" @click="handleNext" />
                    </div>
                    <div class="v-bubble-message-icon">
                        <VIcon :name="activeIcon" :size="24" />
                    </div>
                    <div class="v-bubble-message-close">
                        <VButton icon="close-line" variant="text" pill color="secondary" @click="handleClose" />
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>
