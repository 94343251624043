export interface Payload {
    bonusIds: number[];
    customer: Customer;
    discount?: {
        code: string;
        email: string;
    };
    drop: Drop;
    extras: Extras;
    note: null | string;
    offerId: number | null;
    payment: Payment;
    pickup: Pickup;
    queryId: null | number;
    vehicle: Vehicle;
    [property: string]: any;
}

export interface Customer {
    address: string;
    birthDate: string;
    cityId: null | string;
    drivingLicenseAge: number;
    email: string;
    firstName: string;
    gender: null | string;
    lastName: string;
    passportNo: null | string;
    phoneNumber: PhoneNumber;
    tcNo?: null | string;
    turkeyAddress: null | string;
    [property: string]: any;
}

// export enum DrivingLicenseAge {
//     The1 = '1',
//     The2 = '2',
//     The3 = '3',
//     The4 = '4',
//     The5 = '5',
//     DrivingLicenseAge5 = '5+'
// }

export interface PhoneNumber {
    countryCode: string;
    id?: number | null;
    number: string;
    [property: string]: any;
}

export interface Drop {
    dateTime: string;
    description: null | string;
    detail: null | string;
    pointId: number;
    [property: string]: any;
}

export interface Extras {
    paid: Paid[];
    [property: string]: any;
}

export interface Paid {
    id: number;
    piece: number;
    totalPrice: number;
    [property: string]: any;
}

export interface Payment {
    type: PaymentTypeKey;
    [property: string]: any;
}

export enum PaymentTypeKey {
    Later = 'later',
    Now = 'now'
}

export interface Pickup {
    dateTime: string;
    description: null | string;
    detail: null | string;
    pointId: number;
    [property: string]: any;
}

export interface Vehicle {
    id: number;
    totalPrice: number;
    [property: string]: any;
}
