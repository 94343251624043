<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onMounted, type Ref, ref, watch } from 'vue';
import { initializeApp } from 'firebase/app';
import { RouterView, useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import type { Link, Meta } from 'zhead';
import VAuthModals from '@/components/Auth/Modals.vue';
import VBottomNavigationMenu from '@/components/BottomNavigationMenu/BottomNavigationMenu.vue';
import VFooter from '@/components/Layout/Footer/VFooter.vue';
import VHeader from '@/components/Layout/Header/VHeader.vue';
import VPageLoader from '@/components/PageLoader/PageLoader.vue';
import { useCaptureThingsManager } from '@/composables/useCaptureThingsManager';
import { useCioRouter } from '@/composables/useCioRouter';
import headDefinitions from '@/definitions/head.json';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import screen, { browserIsMobile } from '@/plugins/screen';
import { type ICapacitor, useAppStore } from '@/stores/app-store';
import useCurrencyStore from '@/stores/app/currency-store';
import useAuthStore from '@/stores/auth/auth-store';
import { usePageLoaderStore } from '@/stores/page-loader-store';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import type { ApiAxiosResponse } from '@/types/general';
import emitter from '@/utils/emitter';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseMessaging, type GetTokenOptions, type GetTokenResult, type PermissionStatus as INotificationPermissionStatus } from '@capacitor-firebase/messaging';
import { App, type AppInfo, type URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import * as Sentry from '@sentry/browser';
import { useHead, type UseHeadInput } from '@vueuse/head';
import { getFromLocalStorage as searchFormReadFromLocalStorage } from '@/utils/search-store-helper';
import queryParamsHelper from '@/utils/query-params-helper';
import DiscountModal from '@/components/Search/DiscountModal.vue';
import { Keyboard } from '@capacitor/keyboard';
import VBubbleMessage, { type IBubbleMessage } from '@/components/BubbleMessage/BubbleMessage.vue';

// import VButton from './components/Forms/VButton.vue';
// import VCheckbox from './components/Forms/VCheckbox.vue';
// import VTextInput from './components/Forms/VTextInput.vue';
// import VPopularCities from './components/Widgets/VPopularCities.vue';
// import VWidgetHeader from './components/Widgets/Common/VWidgetHeader.vue';
// import { SchemaOrgDebug } from '@vueuse/schema-org';

// ! TODO !
interface IAnnouncement {
    id: number;
    position: 'left top' | 'left middle' | 'left bottom' | 'center top' | 'center middle' | 'center bottom' | 'right top' | 'right middle' | 'right bottom';
    content: string;
    dailyLimit: number;
    allTimeLimit: number;
    colors: string[];
    type: 'toast' | 'dialog' | 'bubble';
    colorTheme?: 'info' | 'success' | 'error' | 'warning' | 'loading' | 'default';
    title?: string;
    icon?: string;
    autoCloseTimeout: number;
}

const route = useRoute();
const router = useRouter();
const cioRouter = useCioRouter();
const appStore = useAppStore();
const currencyStore = useCurrencyStore();
const authStore = useAuthStore();
const discountCodeStore = useDiscountCodeStore();
const pageLoaderStore = usePageLoaderStore();
const ctm = useCaptureThingsManager();
const { checkAllQueryParams } = queryParamsHelper();

appStore.setBaseUrl(import.meta.env.VITE_APP_URL);
appStore.setCdnUrl(import.meta.env.VITE_CDN_URL);
appStore.setSsrMode(import.meta.env.SSR);

if (import.meta.env.SSR) {
    appStore.setServerRendered(true);
}

let appInfo: AppInfo = {
    build: '',
    id: '',
    name: '',
    version: ''
};
const showBubbleMessages = ref(false);
const bubbleMessages: Ref<IBubbleMessage[]> = ref([]);

onBeforeMount(async () => {
    sessionStorage.clear();
    searchFormReadFromLocalStorage();
    appStore.setCapacitorIsNative(Capacitor.isNativePlatform());
    appStore.setCapacitorPlatform(Capacitor.getPlatform() as ICapacitor['platform']);
    // appStore.setCapacitorPlatform('android');

    if (appStore.capacitor.isNative) {
        appInfo = await App.getInfo();
    }

    await prepareHeadDefinitions();

    if (import.meta.env.SSR) {
        appStore.setReady(true);
        sessionStorage.setItem('liftoff', 'true');
    } else {
        await liftoff();

        try {
            await notificationActions();
        } catch (error) {
            Sentry.withScope(function (scope) {
                scope.setTag('action-name', 'push-notification-register--' + (appStore.capacitor.isNative ? 'mobile' : 'web'));
                scope.setLevel('error');
                Sentry.captureException(error);
            });
        }

        if (appStore.capacitor.isNative || browserIsMobile) {
            appStore.mobileMode = true;
            document.body.classList.add('mobile-mode');

            if (appStore.capacitor.isNative) {
                document.body.classList.add('native-mode');
            }
        }
    }

    if (appStore.capacitor.isNative) {
        await capacitorMobileActions();
    }

    discountCodeStore.initialize();
});

onMounted(() => {
    checkAllQueryParams();
    emitter.on('node:loaded', ctm.captureNodePageView);
    emitter.on('vehicle-list:loaded', ctm.captureVehicleSearch);
    emitter.on('vehicle-detail:loaded', ctm.captureVehicleView);
    emitter.on('reservation-create:loaded', ctm.captureVehicleSelect);
    emitter.on('reservation:stored-before', ctm.captureReservation);
    emitter.on('customer:loaded', ctm.setCustomer);
    emitter.on('locale-code:changed', ctm.setVisitorLocaleCode);
    emitter.on('currency-code:changed', ctm.setVisitorCurrencyCode);
});

onBeforeUnmount(() => {
    emitter.off('node:loaded', ctm.captureNodePageView);
    emitter.off('vehicle-list:loaded', ctm.captureVehicleSearch);
    emitter.off('vehicle-detail:loaded', ctm.captureVehicleView);
    emitter.off('reservation-create:loaded', ctm.captureVehicleSelect);
    emitter.off('reservation:stored-before', ctm.captureReservation);
    emitter.off('customer:loaded', ctm.setCustomer);
    emitter.off('locale-code:changed', ctm.setVisitorLocaleCode);
    emitter.off('currency-code:changed', ctm.setVisitorLocaleCode);
    emitter.off('currency-code:changed', ctm.setVisitorCurrencyCode);
});

watch(
    () => route.name,
    () => {
        showBubbleMessages.value = false;

        if (typeof route.name === 'string') {
            appStore.setRoute({
                name: route.name,
                params: typeof route.params === 'object' ? route.params : {}
            });
        }

        const rootRoute = route.matched[0] || null;

        if (
            rootRoute !== null &&
            typeof rootRoute.meta !== 'undefined' &&
            typeof rootRoute.meta.locale === 'object' &&
            rootRoute.meta.locale !== null &&
            'id' in rootRoute.meta.locale &&
            typeof rootRoute.meta.locale.id === 'number'
        ) {
            appStore.setLocaleWithId(rootRoute.meta.locale.id);
        }
    },
    { immediate: true }
);

// watch(
//     () => route.fullPath,
//     () => {
//         if (appStore.capacitor.isNative) {
//             ctm.sendToFirebaseAnalytics('page_view', {
//                 name: (route.name as string | null) ?? null,
//                 path: route.fullPath
//             });
//         } else {
//             ctm.sendToGoogleDatalayer('virtual_page_view', 'params', {
//                 name: (route.name as string | null) ?? null,
//                 path: route.fullPath
//             });
//         }
//     },
//     { immediate: true }
// );
// emitter.on('node:loaded', () => {
//     window.scrollTo({ behavior: 'smooth', top: 0 });
// });

const capacitorLiveUpdate = async () => {
    if (import.meta.env.VITE_LIVE_UPDATE_ENABLED === 'true') {
        try {
            const result = await LiveUpdates.sync();

            if (result.activeApplicationPathChanged) {
                await LiveUpdates.reload();
            }
        } catch (error) {
            Sentry.withScope(function (scope) {
                scope.setTag('action-name', 'mobile-live-update');
                scope.setLevel('error');
                Sentry.captureException(error);
            });
        }
    }
};

const capacitorMobileActions = async () => {
    await capacitorSetNativeFirebaseAnalytics();
    await capacitorCheckNativeUpdate();
    await capacitorRegisterNativeBackButton();
    appStore.setReady(true);
    pageLoaderStore.unsubscribe(this);
    await capacitorRegisterNativeOpenUrl();
    await capacitorRegisterKeyboardTracker();
};

let backToCloseNativeAppEnabled = false;

const capacitorRegisterNativeBackButton = async () => {
    await App.addListener('backButton', () => {
        if (appStore.getFirstBackRouteBlocker !== null) {
            appStore.getFirstBackRouteBlocker.fn();
        } else if (appStore.backRoutePath === null) {
            if (backToCloseNativeAppEnabled) {
                App.exitApp();
            } else {
                backToCloseNativeAppEnabled = true;
                toast(i18n.global.t('app.backToCloseMessage'), {
                    autoClose: 2000,
                    type: 'info'
                });
                setTimeout(() => {
                    backToCloseNativeAppEnabled = false;
                }, 2750);
            }
        } else {
            appStore.setBackRequestCall(true);
            cioRouter.openPath(appStore.backRoutePath);
        }
    });
};

const capacitorRegisterNativeOpenUrl = async () => {
    await App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
        const url = new URL(event.url);
        const queryParams: { [key: string]: string } = {};

        url.searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });

        const hasQueryParams = Object.keys(queryParams).length > 0;

        if (url.pathname) {
            router.push({
                path: url.pathname,
                query: hasQueryParams ? queryParams : undefined
            });

            if (hasQueryParams) {
                setTimeout(() => {
                    checkAllQueryParams();
                }, 500);
            }
        }
    });
};

const capacitorRegisterKeyboardTracker = async () => {
    await Keyboard.addListener('keyboardWillShow', (info) => {
        document.body.classList.add('native-keyboard-open');
        // document.body.style.setProperty('--footer-height', '0px');
        document.body.style.setProperty('--native-keyboard-height', `${info.keyboardHeight}px`);
    });

    await Keyboard.addListener('keyboardWillHide', () => {
        document.body.classList.remove('native-keyboard-open');
        // document.body.style.removeProperty('--footer-height');
        document.body.style.removeProperty('--native-keyboard-height');
    });
};

const capacitorCheckNativeUpdate = async () => {
    try {
        const result = await AppUpdate.getAppUpdateInfo();

        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            await capacitorLiveUpdate();
            return;
        }

        if (result.immediateUpdateAllowed) {
            await AppUpdate.performImmediateUpdate();
        } else {
            await AppUpdate.openAppStore();
        }
    } catch (error) {
        Sentry.withScope(function (scope) {
            scope.setTag('action-name', 'mobile-native-update');
            scope.setLevel('error');
            Sentry.captureException(error);
        });
        await capacitorLiveUpdate();
    }
};

const capacitorSetNativeFirebaseAnalytics = async () => {
    await FirebaseAnalytics.setEnabled({
        enabled: true
    });
};

const notificationActions = async () => {
    await FirebaseMessaging.addListener('notificationReceived', (event) => {
        console.log('notificationReceived: ', { event });
    });
    await FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
        console.log('notificationActionPerformed: ', { event });
    });

    const getTokenOptions: GetTokenOptions = {
        vapidKey: 'BJfV68_NLbDBApF244ffnEt1VNHldvA9K7yIKS_SUrbX7r0Th3hdiI4ycIrz6C9WEUkYb-SwmDmbrSmkTBrCxD0'
    };

    let permission: null | INotificationPermissionStatus = null;

    if (Capacitor.isNativePlatform()) {
        permission = await FirebaseMessaging.requestPermissions();
    } else {
        initializeApp({
            apiKey: 'AIzaSyA2Tvy9iLLkIYq1isiyD0b4mRDlaIZAVho',
            appId: '1:502563610746:web:3ddf0cc5b427b6990e5bc3',
            authDomain: 'elitcar-rental-fortnite.firebaseapp.com',
            measurementId: 'G-65F1L5PZS2',
            messagingSenderId: '502563610746',
            projectId: 'elitcar-rental-fortnite',
            storageBucket: 'elitcar-rental-fortnite.appspot.com'
        });

        getTokenOptions.serviceWorkerRegistration = await navigator.serviceWorker?.register('/firebase-messaging-sw.js');

        if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker?.addEventListener('message', (event: any) => {
                console.log('serviceWorker message: ', { event });

                const notification = new Notification(event.data.notification.title, {
                    body: event.data.notification.body
                });

                notification.onclick = (event) => {
                    console.log('serviceWorker notification clicked: ', { event });
                };
            });

            permission = await FirebaseMessaging.requestPermissions();
        }
    }

    if (permission !== null && permission.receive === 'granted') {
        await FirebaseMessaging.getToken(getTokenOptions).then((value: GetTokenResult) => {
            if (localStorage.getItem('notificationId') !== value.token) {
                api.post('/auth/update-notification-id', {
                    provider: 'firebase',
                    token: value.token
                })
                    .then(() => {
                        localStorage.setItem('notificationId', value.token);
                    })
                    .catch((error: any) => {
                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'notification-id-update');
                            scope.setLevel('warning');
                            Sentry.captureException(error);
                        });
                    });
            }
        });
    }
};

const liftoff = async () => {
    pageLoaderStore.subscribe(this);
    const referrerSearch = window.document.referrer === '' ? '' : new URL(window.document.referrer).search;

    await api
        .post('/liftoff', {
            appCode: appStore.capacitor.isNative ? appInfo.id : import.meta.env.VITE_APP_DOMAIN,
            appVersion: appStore.capacitor.isNative ? appInfo.version : null,
            referrerParams: referrerSearch === '' ? [] : JSON.parse('{"' + decodeURI(referrerSearch).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        })
        .then(
            async (
                response: ApiAxiosResponse<{
                    blockMessage: null | string;
                    country: string;
                    locale: string;
                    currency: string;
                    announcements: IAnnouncement[];
                }>
            ) => {
                let _currency = localStorage.getItem('currency');
                if (_currency) {
                    currencyStore.setActiveCurrencyFromCode(_currency);
                } else {
                    currencyStore.setActiveCurrencyFromCode(response.data.data.currency);
                }

                appStore.setCountryCode(response.data.data.country);
                ctm.setVisitor();

                //TODO! - dialog type, alert type, dailyLimit and allTimeLimit for showing
                response.data.data.announcements
                    .filter((a) => a.type === 'toast')
                    .forEach((announcement) => {
                        toast(announcement.content, {
                            autoClose: announcement.autoCloseTimeout === 0 ? undefined : announcement.autoCloseTimeout,
                            dangerouslyHTMLString: true,
                            type: announcement.colorTheme ?? 'info'
                        });
                    });

                bubbleMessages.value = response.data.data.announcements
                    .filter((a) => a.type === 'bubble')
                    .map((announcement) => {
                        return {
                            icon: announcement.icon || null,
                            message: announcement.content,
                            theme: announcement.colorTheme || 'default',
                            title: announcement.title || null
                        };
                    });

                if (bubbleMessages.value.length > 0) {
                    showBubbleMessages.value = true;
                }

                appStore.setBlockMessage(response.data.data.blockMessage);
            }
        )
        .catch(() => {
            // console.log(error);
        })
        .finally(async () => {
            sessionStorage.setItem('liftoff', 'true');
            authStore.fetchCustomerIfNotFetched();

            if (!appStore.capacitor.isNative) {
                appStore.setReady(true);
                pageLoaderStore.unsubscribe(this);
            }
        });
};

const prepareHeadDefinitions = async () => {
    if (!appStore.capacitor.isNative) {
        const headData: { link?: Link[]; meta?: Meta[] } = {};

        if (headDefinitions.link.length > 0) {
            if (typeof headData.link === 'undefined') {
                headData.link = [];
            }

            for (let i = 0; i < headDefinitions.link.length; i++) {
                headData.link.push({
                    href: headDefinitions.link[i].href ?? undefined,
                    rel: headDefinitions.link[i].rel ?? undefined,
                    sizes: headDefinitions.link[i].sizes ?? undefined,
                    type: headDefinitions.link[i].type ?? undefined
                });
            }
        }

        if (headDefinitions.meta.length > 0) {
            if (typeof headData.meta === 'undefined') {
                headData.meta = [];
            }

            for (let i = 0; i < headDefinitions.meta.length; i++) {
                headData.meta.push({
                    content: headDefinitions.meta[i].content,
                    name: headDefinitions.meta[i].name ?? undefined,
                    property: headDefinitions.meta[i].property ?? 's'
                });
            }
        }

        if (Object.keys(headData).length > 0) {
            useHead(headData as UseHeadInput);
        }
    }
};

if (import.meta.env.SSR) {
    prepareHeadDefinitions();
}
</script>

<template>
    <v-header />
    <Suspense>
        <!-- <router-view v-slot="{ Component }" v-if="screen.isMobile">
            <transition name="list">
                <component :is="Component" />
            </transition>
        </router-view>
        <router-view v-else /> -->
        <router-view />
        <template #fallback>
            <main>
                <VPageLoader embedded />
            </main>
        </template>
    </Suspense>
    <v-footer v-if="!appStore.hideFooter" />
    <VAuthModals />
    <DiscountModal />
    <VPageLoader />
    <VBubbleMessage :messages="bubbleMessages" :active="showBubbleMessages" @close="showBubbleMessages = false" />
    <VBottomNavigationMenu v-if="screen.isMobile" />
</template>
