import { defineStore } from 'pinia';

import { setQueryFormParams } from '@/composables/useSetSearchFormContent';
import { api } from '@/plugins/axios';
import { useNodeStore } from '@/stores/node-store';
import type { ApiAxiosResponse } from '@/types/general';
import type { ISearchFormPageTemplateContent } from '@/types/template/base';

export interface ISimpleDefaultTemplateContent extends ISearchFormPageTemplateContent {
    images: {
        banner: null | string;
    };
}

export interface ISimpleDefaultStore extends ISimpleDefaultTemplateContent {
    loaded: boolean;
    loading: boolean;
}

export const useSimpleDefaultStore = defineStore('simple-default-store', {
    actions: {
        setData(value: ISimpleDefaultStore) {
            this.$patch(value);
            setQueryFormParams(value);
        },
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<ISimpleDefaultTemplateContent>) => {
                    this.setData(
                        Object.assign(
                            {
                                loaded: true,
                                loading: false
                            },
                            response.data.data
                        )
                    );

                    status = true;
                })
                .catch(() => {
                    status = false;
                    this.$reset();
                });

            return status;
        }
    },

    getters: {
        getData(state) {
            return state;
        }
    },

    state: () =>
        ({
            images: {
                banner: null
            },
            loaded: false,
            loading: false,
            queryFormParams: {
                dropAt: null,
                dropPointId: null,
                dropPointItem: null,
                pickupAt: null,
                pickupPointId: null,
                pickupPointItem: null,
                searchImmediately: false
            },
            vehicleFilters: [],
            relationCards: []
        }) as ISimpleDefaultStore
});
