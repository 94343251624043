// Bu fonksiyon bir elementin görünür olup olmadığını kontrol eder
export const isElementInViewport = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
};

// Bu fonksiyon, verilen elementin atalarından hangilerinin scroll alanına sahip olduğunu bulur
export const findScrollableAncestor = (el: null | Element) => {
    while (el) {
        // console.log(el);
        if (el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth) {
            return el;
        }

        el = el.parentElement;
    }

    return null;
};

// Belirli bir elementin hangi scrollable elementin içinde olduğunu bulur
export const getScrollContainer = (el: Element) => {
    let currentElement: null | Element = el;

    while (currentElement) {
        // console.log(currentElement);
        if (isElementInViewport(currentElement)) {
            const scrollableAncestor = findScrollableAncestor(currentElement);
            if (scrollableAncestor) {
                return scrollableAncestor;
            }
        }

        currentElement = currentElement.parentElement;
    }

    return document.body; // En üst düzeyde body elementini döndürüyoruz
};

export const getScrollContainerDirect = (element: null | Element) => {
    while (element) {
        const style = getComputedStyle(element);

        if (style.overflowY === 'scroll' || style.overflowY === 'auto' || element.scrollHeight - element.clientHeight > 2) {
            return element;
        }

        element = element.parentElement;
    }
    return document.body; // En üst düzeyde body elementini döndürüyoruz
};

export default {
    findScrollableAncestor,
    getScrollContainer,
    getScrollContainerDirect,
    isElementInViewport
};
