import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { locales, staticRouteTranslations } from '@/definitions';
import AccountLayout from '@/views/AccountLayout.vue';
// import { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import AppLayout from '@/views/AppLayout.vue';
import BlogLayout from '@/views/BlogLayout.vue';

const routeList: RouteRecordRaw[] = [];

for (const locale of locales) {
    if (locale.isActive && locale.path !== null) {
        // Default locale redirect
        if (locale.isDefault) {
            routeList.push({
                path: '/' + locale.path,
                redirect: '/'
            });

            routeList.push({
                path: '/' + locale.path + '/:afterUser(.*)',
                redirect: (to) => ({
                    path: to.path.replace('/' + locale.path, '/').replace('//', '/')
                })
            });
        }

        //     // App
        const appRootPath = '/' + (locale.isDefault ? '' : locale.path);
        const appRootPathPrefix = appRootPath === '/' ? '/' : appRootPath + '/';
        const appRoute = {
            children: [
                // Home
                {
                    component: () => import('@/views/app/HomeView.vue'),
                    name: 'app.' + locale.code + '.home',
                    path: appRootPath
                }
            ],
            component: AppLayout,
            meta: { locale: locale },
            name: 'app.' + locale.code,
            path: appRootPath
        };

        // console.log(staticRouteTranslations)

        if (
            typeof staticRouteTranslations[locale.path] !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page.sitemap !== 'undefined'
        ) {
            // Sitemap Page
            appRoute.children.push({
                component: () => import('@/views/app/SitemapView.vue'),
                name: 'app.' + locale.code + '.sitemap',
                path: appRootPathPrefix + staticRouteTranslations[locale.path].page.sitemap
            });
        }

        // [Reservation Page, Reservation View, Reservation Find]
        if (
            typeof staticRouteTranslations[locale.path] !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page.sitemap !== 'undefined'
        ) {
            // Reservation Page
            appRoute.children.push({
                component: () => import('@/views/reservation/CreateView.vue'),
                name: 'reservation.' + locale.code + '.create',
                path: appRootPathPrefix + staticRouteTranslations[locale.path].page['reservation-create-default']
            });
            // Reservation Find
            appRoute.children.push({
                component: () => import('@/views/reservation/QueryView.vue'),
                name: 'reservation.' + locale.code + '.query',
                path: appRootPathPrefix + staticRouteTranslations[locale.path].page['reservation-query-default']
            });
            // Reservation View
            appRoute.children.push({
                component: () => import('@/views/reservation/ShowView.vue'),
                name: 'reservation.' + locale.code + '.show',
                path: appRootPathPrefix + staticRouteTranslations[locale.path].page['reservation-show-default']
            });
        }

        // [Payment]
        if (
            typeof staticRouteTranslations[locale.path] !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page !== 'undefined' &&
            typeof staticRouteTranslations[locale.path].page.sitemap !== 'undefined'
        ) {
            // Payment Page
            appRoute.children.push({
                component: () => import('@/views/payment/HomeView.vue'),
                name: 'payment.' + locale.code + '.home',
                path: appRootPathPrefix + staticRouteTranslations[locale.path].page['payment-default']
                // path: appRootPathPrefix + staticRouteTranslations[locale.path].page.payment_home
            });
        }

        // Dynamic Pages
        appRoute.children.push({
            component: () => import('@/views/app/PageView.vue'),
            name: 'app.' + locale.code + '.page',
            path: appRootPathPrefix + ':slug'
        });

        // Not Found
        appRoute.children.push({
            component: () => import('@/views/app/Error404View.vue'),
            name: 'app.' + locale.code + '.not-found',
            path: appRootPathPrefix + ':catchAll(.*)*'
        });

        routeList.push(appRoute);

        // Blog
        if (typeof staticRouteTranslations[locale.path] !== 'undefined' && typeof staticRouteTranslations[locale.path].blog !== 'undefined') {
            const blogRootPath = '/' + (locale.isDefault ? staticRouteTranslations[locale.path].blog['blog-home'] : locale.path + '/' + staticRouteTranslations[locale.path].blog['blog-home']);
            const blogRootPathPrefix = blogRootPath + '/';
            const blogRoute = {
                children: [
                    // Blog Home
                    {
                        component: () => import('@/views/blog/HomeView.vue'),
                        name: 'blog.' + locale.code + '.home',
                        path: blogRootPath
                    },
                    {
                        component: () => import('@/views/blog/HomeView.vue'),
                        name: 'blog.' + locale.code + '.pagination',
                        path: blogRootPath + '/:page(\\d+)',
                        props: (route: RouteLocationNormalized) => ({
                            page: parseInt(route.params.page.toString()) || 1
                        })
                    }
                ],
                component: BlogLayout,
                meta: { locale: locale },
                name: 'blog.' + locale.code,
                path: blogRootPath
            };

            if (typeof staticRouteTranslations[locale.path].blog['blog-search'] !== 'undefined' && typeof staticRouteTranslations[locale.path].blog['blog-search'] !== null) {
                // Blog Search
                blogRoute.children.push({
                    component: () => import('@/views/blog/SearchView.vue'),
                    name: 'blog.' + locale.code + '.search',
                    path: blogRootPathPrefix + staticRouteTranslations[locale.path].blog['blog-search']
                });
            }

            // Blog Detail
            blogRoute.children.push({
                component: () => import('@/views/blog/BlogView.vue'),
                name: 'blog.' + locale.code + '.blog',
                path: blogRootPathPrefix + ':slug'
            });
            blogRoute.children.push({
                component: () => import('@/views/blog/BlogView.vue'),
                name: 'blog.' + locale.code + '.category.pagination',
                path: blogRootPathPrefix + ':slug/:page(\\d+)',
                props: (route: RouteLocationNormalized) => ({
                    slug: route.params.slug,
                    page: parseInt(route.params.page.toString()) || 1
                })
            });

            // Pagination
            // for(let i = 1; i <= 30; i++) {
            //     blogRoute.children.push({
            //         component: () => import('@/views/blog/PaginationView.vue'), // Change this to the actual component for pagination
            //         name: 'blog.' + locale.code + '.pagination.' + i,
            //         path: blogRootPathPrefix + i
            //     });
            // }

            // Not Found @todo tag, search harici ara dizin sonrası için açılmalı
            // blogRoute.children.push({
            //   component: () => import('@/views/app/Error404View.vue'),
            //   name: 'blog.' + locale.code + '.not-found',
            //   path: blogRootPathPrefix + ':catchAll(.*)*'
            // });

            routeList.push(blogRoute);
        }

        // Account
        if (typeof staticRouteTranslations[locale.path] !== 'undefined' && typeof staticRouteTranslations[locale.path].account !== 'undefined') {
            const accountRootPath = '/' + (locale.isDefault ? staticRouteTranslations[locale.path].account['account-home'] : locale.path + '/' + staticRouteTranslations[locale.path].account['account-home']);
            const accountRootPathPrefix = accountRootPath + '/';
            const accountRoute = {
                children: [
                    // Invitations
                    {
                        component: () => import('@/views/account/InvitationView.vue'),
                        name: 'account.' + locale.code + '.account-invitations',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-invitations']
                    },
                    // Messages
                    {
                        component: () => import('@/views/account/MessagesView.vue'),
                        name: 'account.' + locale.code + '.account-messages',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-messages']
                    },
                    // Offers
                    {
                        component: () => import('@/views/account/ReservationOffersView.vue'),
                        name: 'account.' + locale.code + '.account-offers',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-offers']
                    },
                    // Payments
                    {
                        component: () => import('@/views/account/PaymentsView.vue'),
                        name: 'account.' + locale.code + '.account-payments',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-payments']
                    },
                    // Personal Info
                    {
                        component: () => import('@/views/account/PersonalInfoView.vue'),
                        name: 'account.' + locale.code + '.account-personal-information',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-personal-information']
                    },
                    // Personal Info
                    {
                        component: () => import('@/views/account/ReservationsView.vue'),
                        name: 'account.' + locale.code + '.account-reservations',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-reservations']
                    },
                    // Earns
                    {
                        component: () => import('@/views/account/EarnsView.vue'),
                        name: 'account.' + locale.code + '.account-earnings',
                        path: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-earnings']
                    },
                    // Not Found
                    {
                        component: () => import('@/views/account/NotFoundView.vue'),
                        name: 'account.' + locale.code + '.not-found',
                        path: accountRootPathPrefix + ':catchAll(.*)*'
                    }
                ],
                component: AccountLayout,
                meta: { locale: locale },
                name: 'account.' + locale.code + '.account-home',
                path: accountRootPath,
                redirect: accountRootPathPrefix + staticRouteTranslations[locale.path].account['account-personal-information']
            };

            routeList.push(accountRoute);
        }
    }
}

// routeList.push({
//   path: '/:catchAll(.*)*',
//   component: () => () => import('@/views/Error404View.vue')
// });

export const routes: RouteRecordRaw[] = routeList;
