<script lang="ts" setup>
import { computed } from 'vue';

import VIcon from '@/components/UI/VIcon.vue';

const props = withDefaults(
    defineProps<{
        /**
         * Append icon to the button. Icon will be on the right side
         * @type string
         * @required false
         * @default undefined
         */
        appendIcon?: string;

        /**
         * The color of the button.
         * @type 'primary' | 'secondary'
         * @required false
         * @default primary
         */
        color?: 'primary' | 'secondary';

        /**
         * Is the button disabled. Cannot be clicked and has a grayed out look
         * @type boolean
         * @required false
         * @default false
         */
        disabled?: boolean;

        /**
         * Is the button full width
         * @type boolean
         * @required false
         * @default false
         */
        fullWidth?: boolean;

        /**
         * Is the button full width (without content width)
         * @type boolean
         * @required false
         * @default false
         */
        fillWidth?: boolean;

        /**
         * Icon only button. Circle button and no label
         * @type string
         * @required false
         * @default undefined
         */
        icon?: string;

        /**
         * The label of the button
         * @type string
         * @required false
         */
        label?: string;

        /**
         * Is the button loading. Shows a loading spinner
         * @type boolean
         * @required false
         * @default false
         */
        loading?: boolean;

        /**
         * Is pill button. Fully rounded edges
         * @type boolean
         * @required false
         * @default false
         */
        pill?: boolean;

        /**
         * Prepend icon to the button. Icon will be on the left side
         * @type string
         * @required false
         * @default undefined
         */
        prependIcon?: string;

        /**
         * Is rounded button. Soft rounded edges
         * @type boolean
         * @required false
         * @default true
         */
        rounded?: boolean;

        /**
         * The size of the button
         * @type 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
         * @required false
         * @default medium
         */
        size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

        /**
         * Type of the button
         * @type 'button' | 'submit' | 'reset'
         * @required false
         * @default button
         */
        type?: 'button' | 'submit' | 'reset';

        /**
         * The variant of the button
         * @type 'default' | 'outlined' | 'tonal' | 'text' | 'link'
         * @required false
         * @default default
         */
        variant?: 'default' | 'outlined' | 'tonal' | 'text' | 'link';
    }>(),
    {
        appendIcon: undefined,
        color: 'primary',
        disabled: false,
        fillWidth: false,
        fullWidth: false,
        icon: undefined,
        label: undefined,
        loading: false,
        pill: false,
        prependIcon: undefined,
        rounded: true,
        size: 'medium',
        type: 'button',
        variant: 'default'
    }
);

const emit = defineEmits(['click']);

const classes = computed(() => ({
    disabled: props.disabled,
    'fill-width': props.fillWidth,
    'full-width': props.fullWidth,
    icon: props.icon,
    loading: props.loading,
    pill: props.pill, // 9999px
    rounded: props.rounded,
    'v-button': true,
    [`variant--${props.variant || 'default'}`]: true,
    [`color--${props.color || 'primary'}`]: true,
    [`size--${props.size || 'medium'}`]: true
}));

const handleClick = (e: Event) => {
    if (props.loading || props.disabled) {
        return;
    }

    emit('click', e);
};
</script>

<template>
    <button :type="props.type" :class="classes" @click="handleClick" :disabled="props.disabled">
        <div class="button-content">
            <VIcon :name="props.prependIcon" v-if="props.prependIcon" class="prepend-icon" />
            <VIcon :name="props.icon" v-if="props.icon" class="icon" />

            <template v-if="!props.icon">
                <!-- if slot named "prepend" exisit -->
                <template v-if="$slots.prepend">
                    <div class="prepend-slot"><slot name="prepend"></slot></div>
                </template>

                <template v-if="props.label">
                    <span class="label">{{ props.label }}</span>
                </template>
                <template v-else>
                    <slot />
                </template>

                <!-- if slot named "append" exisit -->
                <template v-if="$slots.append">
                    <div class="append-slot"><slot name="append"></slot></div>
                </template>
            </template>
            <VIcon :name="props.appendIcon" v-if="props.appendIcon" class="append-icon" />
        </div>
        <!-- For loading spinner -->
        <div class="loading-spinner">
            <VIcon name="loader-4-fill" />
        </div>
    </button>
</template>
