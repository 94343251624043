import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import i18n from '@/locales/locale';
import { toast } from 'vue3-toastify';
import * as Sentry from '@sentry/browser';
import { useRoute } from 'vue-router';
import useAuthStore from '@/stores/auth/auth-store';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import { useCioRouter } from '@/composables/useCioRouter';
import useMenu from '@/composables/useMenu';
import useAuthModalStore from '@/stores/auth/modal-store';
import usePasswordRestoreStore from '@/stores/auth/password-restore-store';

const queryParamsHelper = () => {
    const route = useRoute();
    const authStore = useAuthStore();
    const cioRouter = useCioRouter();
    const discountCodeStore = useDiscountCodeStore();
    const authModalStore = useAuthModalStore();
    const passwordRestoreStore = usePasswordRestoreStore();

    const checkQueryAutoLoginToken = async (): Promise<void> => {
        if (typeof route.query.autoLoginToken === 'string') {
            while (!authStore.loaded || authStore.loading) {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }

            await api
                .post('/auth/auto-login-with-token', {
                    token: route.query.autoLoginToken
                })
                .then(
                    (
                        response: ApiAxiosResponse<{
                            accessToken?: string;
                            authProvider?: string;
                            description: null | string;
                            mode: 'exists' | 'login';
                            redirect: null | {
                                locale: string;
                                page: string;
                                param?: string;
                            };
                            redirectPath: null | string;
                            tokenType?: string;
                        }>
                    ) => {
                        if (
                            response.data.data.mode === 'login' &&
                            typeof response.data.data.accessToken !== 'undefined' &&
                            typeof response.data.data.authProvider !== 'undefined' &&
                            typeof response.data.data.tokenType !== 'undefined'
                        ) {
                            authStore.setLogin({
                                accessToken: response.data.data.accessToken,
                                authProvider: response.data.data.authProvider,
                                message: response.data.data.description || i18n.global.t('auth.login.success'),
                                tokenType: response.data.data.tokenType
                            });
                        } else if (response.data.data.description !== null) {
                            toast.success(response.data.data.description);
                        }

                        setTimeout(() => {
                            if (response.data.data.redirectPath !== null) {
                                cioRouter.openPath(response.data.data.redirectPath);
                            }
                        }, 500);
                    }
                )
                .catch((error: any) => {
                    Sentry.withScope(function (scope) {
                        scope.setTag('action-name', 'auto-login-with-token');
                        scope.setLevel('error');
                        Sentry.captureException(error);
                    });
                });
        }
    };

    const checkQueryDiscountCode = async () => {
        if (typeof route.query.discountCode === 'string') {
            discountCodeStore.setCode(route.query.discountCode);

            if (localStorage.getItem('accessToken') === null) {
                discountCodeStore.openDialog();
            } else {
                discountCodeStore.validate();
            }
        }
    };

    const checkQueryPasswordResetToken = async () => {
        if (typeof route.query.passwordResetToken === 'string') {
            authModalStore.openPasswordRestoreModal();
            passwordRestoreStore.setPayloadToken(route.query.passwordResetToken);

            if (typeof route.query.email === 'string' && route.query.email.length > 0) {
                passwordRestoreStore.setPayloadEmail(route.query.email);
                passwordRestoreStore.setEmailFieldIsDisabled(true);
            }
        }
    };

    const checkQueryPaymentToken = async () => {
        if (typeof route.query.paymentToken === 'string') {
            const { getStaticRoute } = useMenu();
            const paymentPageRoute = getStaticRoute('payment');

            if (typeof paymentPageRoute !== 'undefined') {
                cioRouter.prepareOpen(paymentPageRoute.routeRoot, paymentPageRoute.routeName, { slug: paymentPageRoute.url }, null, { token: route.query.paymentToken });
            }
        }
    };

    const checkQuerySurveyToken = async () => {
        if (typeof route.query.surveyToken === 'string') {
            const { getStaticRoute } = useMenu();
            const surveyPageRoute = getStaticRoute('survey');

            if (typeof surveyPageRoute !== 'undefined') {
                cioRouter.prepareOpen(surveyPageRoute.routeRoot, surveyPageRoute.routeName, { slug: surveyPageRoute.url }, null, { token: route.query.surveyToken });
            }
        }
    };

    const checkQueryUnsubscribeWhatsappCode = async () => {
        if (typeof route.query.unsubscribeWhatsappCode === 'string') {
            await api
                .delete(`/subscriptions/whatsapp/${route.query.unsubscribeWhatsappCode}`)
                .then((response: ApiAxiosResponse<{}>) => {
                    toast.success(response.data.message || ''); // TODO! i18n.global.t('message.success')
                })
                .catch((error: any) => {
                    Sentry.withScope(function (scope) {
                        scope.setTag('action-name', 'unsubscribe-whatsapp-code');
                        scope.setLevel('error');
                        Sentry.captureException(error);
                    });
                });
        }
    };

    const checkAllQueryParams = () => {
        checkQueryAutoLoginToken();
        checkQueryDiscountCode();
        checkQueryPasswordResetToken();
        checkQueryPaymentToken();
        checkQuerySurveyToken();
        checkQueryUnsubscribeWhatsappCode();
    };

    return {
        checkAllQueryParams,
        checkQueryAutoLoginToken,
        checkQueryDiscountCode,
        checkQueryPasswordResetToken,
        checkQueryPaymentToken,
        checkQuerySurveyToken,
        checkQueryUnsubscribeWhatsappCode
    };
};

export default queryParamsHelper;
