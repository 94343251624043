<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue';

const props = withDefaults(
    defineProps<{ 
        /**
         * The name of the icon
         * @type string
         * @required true
         * @default 'heart'
         */ 
        name: string | undefined,

        /**
         * The size of the icon. Can be a number or a string (e.g. '10px', '2em')
         * @type number | undefined
         * @required false
         * @default undefined 
         */ 
        size?: number | undefined
 
    }>(), 
    {
        name: 'heart',
        size: undefined,
    },
); 

const iconClass = computed(() => {
    return `ec ec-${props.name}`; 
});   

const styles = computed(() => { 
    const styleList = {} as any;
    if (props.size) {
        styleList.fontSize = `${props.size}px`; 
    }
    return styleList;
});
</script>

<template> 
    <i :class="iconClass" :style="styles"></i> 
</template> 