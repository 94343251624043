<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import scrollToElement from '@/utils/scroll-to-element';

const props = withDefaults(
    defineProps<{
        /**
         * The label of the checkbox
         * @default undefined
         */
        label?: string;

        /**
         * Name of the checkbox
         * @default undefined
         */
        name?: string;

        /**
         * Value of the checkbox. Can be true or false
         * @default false
         */
        value?: boolean;

        /**
         * Is disabled checkbox. Will be grayed out
         * @default false
         */
        disabled?: boolean;

        /**
         * Is required checkbox. Will show a red asterisk
         * @default false
         */
        required?: boolean;
    }>(),
    {
        label: undefined,
        name: undefined,
        value: false,
        disabled: false,
        required: false
    }
);

// define emits
const emit = defineEmits(['update', 'checked', 'unchecked']);

// define internal value for v-model
const internalValue = ref(props.value);

// define elements
const inputElement = ref<HTMLInputElement>();

// watch for changes to props.value and update internalValue
watch(
    () => props.value,
    (value) => {
        internalValue.value = value;
    }
);

const focus = () => {
    // inputElement.value?.scrollIntoView({ behavior: 'smooth' });
    if (inputElement.value) {
        scrollToElement(inputElement.value, 200, true).then(() => {
            inputElement.value?.focus();
        });
        // inputElement.value?.focus();
    }
};

// handle click event of checkbox
const handleClick = (event: Event) => {
    event.preventDefault();
    if (props.disabled) {
        return;
    }
    internalValue.value = !internalValue.value;
    emit('update', internalValue.value);
    if (internalValue.value) {
        emit('checked');
    } else {
        emit('unchecked');
    }
};

const classes = computed(() => ({
    checked: internalValue.value,
    disabled: props.disabled,
    'v-checkbox': true
}));
defineExpose({
    focus
});
</script>

<template>
    <div :class="classes" @click="handleClick">
        <input ref="inputElement" type="checkbox" :checked="internalValue" :disabled="props.disabled" :required="props.required" />
        <label v-if="props.label || $slots.default" :for="props.name"> {{ props.label }}<slot /> </label>
    </div>
</template>
