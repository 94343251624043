<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import moment from 'moment/min/moment-with-locales';
import VueDatePicker, { type DatePickerInstance, type DatePickerMarker, type Highlight, type RangeConfig } from '@vuepic/vue-datepicker';
import { addMonths, differenceInCalendarMonths, endOfMonth, subMonths } from 'date-fns';
import CDialogProxy from '@/components/CDialogProxy.vue';
import VButton from '@/components/Forms/VButton.vue';
import VDateSlot from '@/components/Search/DateSlot.vue';
import VSearchDialog from '@/components/Search/Dialog.vue';
import VIcon from '@/components/UI/VIcon.vue';
import screen from '@/plugins/screen';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useSearchBarStore from '@/stores/query/search-bar-store';
import type { DeliveryPointDetails } from '@/types/query/delivery-point';
import type { Clock, DateRange, Hour, HourRange } from '@/types/search/dates';
import { clockToMinutes, minutesToClock } from '@/utils/date-format';
import emitter from '@/utils/emitter';
import i18n from '@/locales/locale';
import { useAppStore } from '@/stores/app-store';
import scrollToElement from '@/utils/scroll-to-element';
import VAlert from '@/components/Forms/VAlert.vue';

const appStore = useAppStore();
const deliveryTimeStore = useDeliveryTimeStore();
const searchBarStore = useSearchBarStore();
const { t } = i18n.global;

const earliestReservationHour = ref<number>(2);
const minRentalHour = ref<number>(4);

const calendarMinDate = ref<Date>(new Date());
// const calendarMaxDate = computed((): undefined | Date => (screen.value.isDesktop ? undefined : endOfMonth(addMonths(new Date(), 15))));
const calendarMaxDate = computed((): undefined | Date => endOfMonth(addMonths(new Date(), 14))); // @todo apiden alınabilir
const calendarIsRange = ref<boolean>(true);
const calendarIsActive = ref<boolean>(false);

// en erken alış vakti
const ruleMinPickupMoment = computed(() => {
    //  (api kuralı)
    let m = moment().add(earliestReservationHour.value, 'hours');
    // günün en erken saati
    let todayMinPickupMoment = moment().startOf('day').add(pickupAvailableHours.value.start.minutes, 'minutes');
    // eğer en erken alış vakti bugünün en erken saatinden önceyse, en erken alış vaktini bugünün en erken saatine eşitle.
    if (m.isBefore(todayMinPickupMoment)) {
        m = todayMinPickupMoment;
    }
    // günün en geç alış vakti
    let todayMaxPickupMoment = moment().startOf('day').add(pickupAvailableHours.value.end.minutes, 'minutes');
    // eğer en erken alış vakti bugünün en geç saatinden sonra ise, en erken alış vaktini yarınki en erken saatine eşitle.
    if (m.isAfter(todayMaxPickupMoment)) {
        m = todayMaxPickupMoment.add(1, 'days').startOf('day').add(pickupAvailableHours.value.start.minutes, 'minutes');
    }
    // console.log('MIN PICKUP', m.format('YYYY-MM-DD HH:mm'))
    return m;
});

// en erken teslim vakti
const ruleMinDropMoment = computed(() => {
    let pickupMoment = moment(ruleMinPickupMoment.value);
    if (deliveryTimeStore.pickupDateTime != null) {
        pickupMoment = moment(deliveryTimeStore.pickupDateTime);
    }
    //  (api kuralı)
    let m = moment(pickupMoment).add(minRentalHour.value, 'hours');
    // günün en erken saati
    let todayMinDropMoment = moment().startOf('day').add(dropAvailableHours.value.start.minutes, 'minutes');
    // eğer en erken teslim vakti bugünün en erken saatinden önceyse, en erken teslim vaktini bugünün en erken saatine eşitle.
    if (m.isBefore(todayMinDropMoment)) {
        m = todayMinDropMoment;
    }
    // günün en geç teslim vakti
    let todayMaxDropMoment = moment().startOf('day').add(dropAvailableHours.value.end.minutes, 'minutes');
    // eğer en erken teslim vakti bugünün en geç saatinden sonra ise, en erken teslim vaktini yarınki en erken saatine eşitle.
    if (m.isAfter(todayMaxDropMoment)) {
        let yesterdayStart = todayMaxDropMoment.add(1, 'days').startOf('day').add(dropAvailableHours.value.start.minutes, 'minutes');
        if (m.isBefore(yesterdayStart)) {
            m = yesterdayStart;
        }
    }
    // console.log('MIN DROP', m.format('YYYY-MM-DD HH:mm'))
    return m;
});

const calendarInstance = ref<DatePickerInstance | null>(null);
const rangeOptions = computed((): undefined | RangeConfig => {
    if (!calendarIsRange.value) return undefined;

    return {};
});
const calendarViewDate = computed((): Date => {
    if (screen.value.isMobile) {
        return new Date();
    }

    const pickupBasedDate = deliveryTimeStore.getPickupDateTime === null ? calendarMinDate.value : deliveryTimeStore.getPickupDateTime;

    if (calendarIsRange.value) {
        return pickupBasedDate;
    }

    if (deliveryTimeStore.getDropDateTime === null) {
        return pickupBasedDate;
    }

    if (differenceInCalendarMonths(deliveryTimeStore.getDropDateTime, pickupBasedDate) < 2) {
        return pickupBasedDate;
    }

    return subMonths(deliveryTimeStore.getDropDateTime, 1);
});
const internalModel = ref(<null | Date | Date[]>null);
const multiCalendarOptions = computed(() => {
    return { count: screen.value.isDesktop ? 2 : 15, static: true };
});
const monthChangeOnScroll = computed((): boolean => screen.value.isDesktop);

// just for month selection
const getAvailableMonths = computed(() => {
    let list = [];
    let startMoment = moment().locale(appStore.locale.code);

    for (let i = 0; i < 12; i++) {
        list.push({
            date: startMoment.format('DD.MM.YYYY'),
            month: startMoment.format('MMMM'),
            year: startMoment.format('YYYY')
        });
        startMoment.add(1, 'month');
    }
    return list;
});

onMounted(() => {
    emitter.on('pickup-delivery-point-details:changed', onPickupPointDetailsChanged);
    emitter.on('drop-delivery-point-details:changed', onDropPointDetailsChanged);
    // emitter.on('delivery-time:pickup-date-set',onDeliveryTimePickupDateSet)
    // emitter.on('delivery-time:drop-date-set',onDeliveryTimeDropDateSet)
    sync();
});

onUnmounted(() => {
    emitter.off('pickup-delivery-point-details:changed', onPickupPointDetailsChanged);
    emitter.off('drop-delivery-point-details:changed', onDropPointDetailsChanged);
    // emitter.off('delivery-time:pickup-date-set',onDeliveryTimePickupDateSet)
    // emitter.off('delivery-time:drop-date-set',onDeliveryTimeDropDateSet)
});

watch(
    () => searchBarStore.getIsDeliveryDateDialogOpened,
    (newValue) => {
        if (newValue) {
            if (searchBarStore.getIsDeliveryDatePickupDialogOpened) {
                switchCalendarToPickup();
            } else if (searchBarStore.getIsDeliveryDateDropDialogOpened) {
                switchCalendarToDrop();
            }
        }
    }
);
watch(
    () => searchBarStore.getIsDeliveryClockDialogOpened,
    (newValue) => {
        if (newValue) {
            setTimeout(() => {
                let activeClock = document.querySelector('.clocks .active');
                if (activeClock != null && screen.value.isMobile) {
                    activeClock.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 10);
        }
    }
);
watch(calendarIsActive, (calendarIsActiveValue) => {
    if (calendarIsActiveValue && screen.value.isMobile) {
        nextTick(() => {
            setTimeout(() => {
                let focusDate = deliveryTimeStore.getPickupDateTime === null ? calendarMinDate.value : deliveryTimeStore.getPickupDateTime;

                if (!calendarIsRange.value && deliveryTimeStore.getDropDateTime !== null) {
                    focusDate = subMonths(deliveryTimeStore.getDropDateTime, 1);
                }

                scrollToElement(document.getElementById(`calendar-year-month-${focusDate.getFullYear()}-${focusDate.getMonth()}`), 250, false, undefined, document.getElementById('calendar-container'));
            }, 100);
        });
    }
});

const hour = (clock: string): Hour => {
    return {
        label: clock,
        minutes: clockToMinutes(clock)
    };
};

const pickupAvailableHours = ref<HourRange>({ end: hour('23:59'), start: hour('00:00') });
const dropAvailableHours = ref<HourRange>({ end: hour('23:59'), start: hour('00:00') });

const hourRange = (start?: string, end?: string): HourRange => {
    return {
        end: hour(end || '23:59'),
        start: hour(start || '00:00')
    };
};
const dayClocks = (day: Date, availableHours: HourRange, dateRange: DateRange | null, match: Date | string | null, step: number = 30): Clock[] => {
    let clocks: Clock[] = [];
    let dayMoment = moment(day).startOf('day');
    let minMoment = moment(dateRange?.start || moment(day).startOf('day'));
    let maxMoment = moment(dateRange?.end || moment(day).endOf('day'));
    for (let i = 0; i < 1440; i += step) {
        let isActive = i >= availableHours.start.minutes && i <= availableHours.end.minutes;
        let hour: Hour = {
            label: minutesToClock(i),
            minutes: i
        };
        let clockMoment = moment(dayMoment).add(i, 'minutes');
        if (clockMoment.isBefore(minMoment) || clockMoment.isAfter(maxMoment)) {
            isActive = false;
        }
        let isSelected = false;
        if (match != null && isActive) {
            if (typeof match == 'string' && match.length == 5) {
                isSelected = hour.label == match;
            } else {
                isSelected = clockMoment.isSame(moment(match));
            }
        }
        // disable 00:00
        if (i == 0) {
            isActive = false;
        }
        clocks.push({
            hour: hour,
            isActive: isActive,
            isSelected: isSelected,
            moment: clockMoment
        });
    }
    return clocks;
};
const getAvailableClock = (day: Date, availableHours: HourRange, dateRange: DateRange | null, match: Date | string | null, maxDay: number = 3): Clock => {
    let clocks: Clock[] = [];
    while (maxDay > 0) {
        clocks = dayClocks(day, availableHours, dateRange, match);
        let activeClocks: Clock[] = clocks.filter((c) => c.isActive);
        let matchClock = clocks.find((c) => c.isSelected);
        if (matchClock != null) {
            return matchClock;
        }
        if (activeClocks != null && activeClocks.length > 0) {
            return activeClocks[0];
        }
        day = moment(day).add(1, 'days').toDate();
        maxDay--;
    }
    return clocks[0];
};
const setPickupDate = (date: Date) => {
    let availableClock = getAvailableClock(date, pickupAvailableHours.value, { end: null, start: ruleMinPickupMoment.value.toDate() }, date);
    deliveryTimeStore.pickupDateTime = availableClock.moment.toDate();
};
const setDropDate = (date: Date) => {
    let availableClock = getAvailableClock(date, dropAvailableHours.value, { end: null, start: ruleMinDropMoment.value.toDate() }, date);
    deliveryTimeStore.dropDateTime = availableClock.moment.toDate();
};

const sync = () => {
    if (deliveryTimeStore.getPickupDateTime == null) {
        setPickupDate(new Date());
    }
    if (deliveryTimeStore.getDropDateTime == null) {
        setDropDate(moment(deliveryTimeStore.getPickupDateTime).add(10, 'days').toDate());
    }

    // if (calendar.value === null) return;
    calendarMinDate.value = ruleMinPickupMoment.value.toDate();
};

const onPickupPointDetailsChanged = (e: unknown) => {
    let deliveryPointDetails = e as DeliveryPointDetails;
    if (deliveryPointDetails == null) return;

    pickupAvailableHours.value = hourRange(deliveryPointDetails.rules?.availableHours[0], deliveryPointDetails.rules?.availableHours[1]);

    sync();
};
const onDropPointDetailsChanged = (e: unknown) => {
    let deliveryPointDetails = e as DeliveryPointDetails;
    if (deliveryPointDetails == null) return;

    dropAvailableHours.value = hourRange(deliveryPointDetails.rules?.availableHours[0], deliveryPointDetails.rules?.availableHours[1]);
    sync();
};

const getModelValue = computed(() => {
    if (!calendarIsRange.value) return null;

    return [deliveryTimeStore.getPickupDateTime || new Date(), deliveryTimeStore.getDropDateTime || new Date()];
});

const switchCalendarToPickup = () => {
    highlightedOptions.dates = [];
    calendarIsRange.value = true;
    calendarMinDate.value = ruleMinPickupMoment.value.toDate();
    calendarIsActive.value = false;
    nextTick(() => {
        setTimeout(() => {
            calendarIsActive.value = true;
        }, 20);
    });
};

const highlightedOptions: Highlight = reactive({
    dates: [],
    months: [],
    options: { highlightDisabled: false },
    quarters: [],
    weekdays: [],
    years: []
});

const getDayClass = (date: Date) => {
    if (calendarIsRange.value || deliveryTimeStore.getPickupDateTime === null) {
        return '';
    }

    const classList: string[] = [];
    const dataJSText = moment(date).format('YYYY/MM/DD');

    if (dataJSText === deliveryTimeStore.getPickupDateJSText) {
        classList.push('dp__range_start');
    }

    if (dataJSText > deliveryTimeStore.getPickupDateJSText && dataJSText < deliveryTimeStore.getDropDateJSText) {
        classList.push('dp__range_between');
    }

    if (dataJSText === deliveryTimeStore.getDropDateJSText) {
        classList.push('dp__range_end');
    }

    return classList.join(' ');
};

const getDisabledDates = computed((): Date[] => {
    return [];
    // const dates = [];
    //
    // return dates;
});

const getCalendarMarkers = computed((): DatePickerMarker[] => {
    if (screen.value.isMobile) {
        return [];
    }

    const markers: DatePickerMarker[] = [];
    let pickupDateTime: null | Date = null;
    let dropDateTime: null | Date = null;

    if (calendarIsRange.value) {
        if (Array.isArray(internalModel.value)) {
            pickupDateTime = internalModel.value.length > 0 ? internalModel.value[0] : null;
            dropDateTime = internalModel.value.length === 2 ? internalModel.value[1] : null;
        }
    } else {
        pickupDateTime = deliveryTimeStore.getPickupDateTime;
        dropDateTime = deliveryTimeStore.getDropDateTime;
    }

    if (pickupDateTime !== null && deliveryTimeStore.getPickupDateJSText === deliveryTimeStore.getDropDateJSText) {
        markers.push({
            date: pickupDateTime,
            tooltip: [{ color: '#FF6D00', text: t('searchBar.pickupDropDate') }],
            type: 'line'
        });
    } else {
        if (pickupDateTime !== null) {
            markers.push({
                date: pickupDateTime,
                tooltip: [{ color: '#00E676', text: t('searchBar.pickupDate') }],
                type: 'line'
            });
        }

        if (dropDateTime !== null) {
            markers.push({
                date: dropDateTime,
                tooltip: [{ color: '#FF3D00', text: t('searchBar.dropDate') }],
                type: 'line'
            });
        }
    }

    return markers;
});

const switchCalendarToDrop = () => {
    if (deliveryTimeStore.getPickupDateTime === null) {
        searchBarStore.openDeliveryDatePickupDialog();
        return;
    }

    calendarIsRange.value = false;
    calendarMinDate.value = ruleMinDropMoment.value.toDate();
    calendarIsActive.value = false;
    nextTick(() => {
        setTimeout(() => {
            calendarIsActive.value = true;
        }, 20);

        // nextTick(() => {
        //     const dropDateTimeValue = deliveryTimeStore.getDropDateTime;
        //     calendarInstance.value?.setMonthYear({ month: dropDateTimeValue?.getMonth(), year: dropDateTimeValue?.getFullYear() });
        // });
    });
};

const handlePickupDateClick = () => {
    searchBarStore.openDeliveryDatePickupDialog();
    switchCalendarToPickup();
};
const handleDropDateClick = () => {
    if (deliveryTimeStore.getPickupDateTime == null) {
        searchBarStore.openDeliveryDatePickupDialog();
        return;
    }
    searchBarStore.openDeliveryDateDropDialog();
    switchCalendarToDrop();
};

const handlePickupClockClick = () => {
    searchBarStore.openDeliveryClockPickupDialog();
};
const handleDropClockClick = () => {
    searchBarStore.openDeliveryClockDropDialog();
};

const onClockSelected = (clockString: Clock) => {
    if (searchBarStore.getIsDeliveryClockDropDialogOpened) {
        let dropMoment = moment(deliveryTimeStore.getDropDateTime).startOf('day').add(clockString.hour.minutes, 'minutes');
        setDropDate(dropMoment.toDate());
        //     setDateRange(
        //         moment(deliveryTimeStore.pickupDateTime).toDate(),
        //         moment(dropMoment.format('YYYY-MM-DD') + ' ' + clockString, 'YYYY-MM-DD HH:mm').toDate()
        //     );
        searchBarStore.closeAllDialogs();
    }
    if (searchBarStore.getIsDeliveryClockPickupDialogOpened) {
        let pickupMoment = moment(deliveryTimeStore.getPickupDateTime).startOf('day').add(clockString.hour.minutes, 'minutes');
        setPickupDate(pickupMoment.toDate());
        //     setDateRange(
        //         moment(pickupMoment.format('YYYY-MM-DD') + ' ' + clockString, 'YYYY-MM-DD HH:mm').toDate(),
        //         moment(deliveryTimeStore.dropDateTime).toDate()
        //     );
        searchBarStore.closeAllDialogs();
        // searchBarStore.openDeliveryClockDropDialog();
    }
};

enum IDateDialogSection {
    CALENDAR = 'calendar',
    MONTHS = 'months'
}
const dateDialogSection = ref(IDateDialogSection.CALENDAR);
const setDateDialogSection = (section: IDateDialogSection) => {
    dateDialogSection.value = section;
};
const handleMonthClick = (monthDateText: string) => {
    setDateDialogSection(IDateDialogSection.CALENDAR);
    const monthDate = moment(monthDateText, 'DD.MM.YYYY').toDate();
    calendarInstance.value?.setMonthYear({ month: monthDate.getMonth(), year: monthDate.getFullYear() });
};

const getAvailableClocks = computed(() => {
    let clocks: Clock[] = [];
    if (searchBarStore.getIsDeliveryClockPickupDialogOpened) {
        clocks = dayClocks(deliveryTimeStore.getPickupDateTime || new Date(), pickupAvailableHours.value, { end: null, start: ruleMinPickupMoment.value.toDate() }, deliveryTimeStore.getPickupClockText);
    }
    if (searchBarStore.getIsDeliveryClockDropDialogOpened) {
        clocks = dayClocks(deliveryTimeStore.getDropDateTime || new Date(), dropAvailableHours.value, { end: null, start: ruleMinDropMoment.value.toDate() }, deliveryTimeStore.getDropClockText);
    }
    return clocks;
});

const onPickerModelUpdated = (value: null | Date | Date[]) => {
    if (value === null) return;

    let start = moment(deliveryTimeStore.getPickupDateTime);
    let end = null;

    if (!calendarIsRange.value && !Array.isArray(value)) {
        end = moment(value);
    } else if (Array.isArray(value)) {
        start = moment(value[0]);
        end = moment(value[1]);
    }

    if (end === null) return;

    // eğer tarhiler değişmedi ise çık
    if (start.startOf('day').isSame(moment(deliveryTimeStore.getPickupDateTime).startOf('day')) && end.startOf('day').isSame(moment(deliveryTimeStore.getDropDateTime).startOf('day'))) {
        return;
    }
    // start ve end in sadece gün,ay,yıl kısmını al ve pickupDateTime ın saatini ekleyerek yeni bir tarih oluştur.
    let pickupDateTime = moment(start.format('YYYY-MM-DD') + ' ' + deliveryTimeStore.getPickupClockText, 'YYYY-MM-DD HH:mm');
    let dropDateTime = moment(end.format('YYYY-MM-DD') + ' ' + deliveryTimeStore.getDropClockText, 'YYYY-MM-DD HH:mm');

    setPickupDate(pickupDateTime.toDate());
    setDropDate(dropDateTime.toDate());

    searchBarStore.closeAllDialogs();
};

const onPickerInternalModelUpdated = (value: null | Date | Date[]) => {
    internalModel.value = value;
};
</script>

<template>
    <div class="v-search-dates">
        <div class="inputs">
            <VDateSlot
                tag="pickup"
                :label="$t('searchBar.pickupDateTime')"
                :date="deliveryTimeStore.getPickupDateText"
                :clock="deliveryTimeStore.getPickupClockText"
                @on-date-click="handlePickupDateClick"
                @on-clock-click="handlePickupClockClick"
            />
            <VDateSlot
                tag="drop"
                :label="$t('searchBar.dropDateTime')"
                :date="deliveryTimeStore.getDropDateText"
                :clock="deliveryTimeStore.getDropClockText"
                @on-date-click="handleDropDateClick"
                @on-clock-click="handleDropClockClick"
            />
        </div>

        <CDialogProxy>
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VSearchDialog v-if="searchBarStore.getIsDeliveryClockDialogOpened" class="v-search-dialog-date v-search-dialog-date--time">
                    <template #title>
                        <span v-if="searchBarStore.getIsDeliveryClockPickupDialogOpened">
                            {{ $t('searchBar.definePickupClock') }}
                        </span>
                        <span v-if="searchBarStore.getIsDeliveryClockDropDialogOpened">
                            {{ $t('searchBar.defineDropClock') }}
                        </span>
                    </template>
                    <template #body>
                        <div class="clocks">
                            <template v-for="(clock, ci) in getAvailableClocks" :key="ci">
                                <div
                                    :class="{
                                        active: clock.isSelected,
                                        disabled: !clock.isActive
                                    }"
                                    @click="clock.isActive && onClockSelected(clock)"
                                >
                                    {{ clock.hour.label }}
                                </div>
                            </template>
                        </div>
                    </template>
                </VSearchDialog>
            </transition>
            <!-- </CDialogProxy> -->

            <!-- <CDialogProxy> -->
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VSearchDialog v-if="searchBarStore.getIsDeliveryDateDialogOpened" class="v-search-dialog-date v-search-dialog-date--date">
                    <template #title>
                        <span v-if="searchBarStore.getIsDeliveryDatePickupDialogOpened">
                            {{ $t('searchBar.pickupDropDate') }}
                        </span>
                        <span v-if="searchBarStore.getIsDeliveryDateDropDialogOpened">
                            {{ $t('searchBar.dropDate') }}
                        </span>
                    </template>
                    <template #interaction>
                        <div v-if="screen.isDesktop" class="section-switchers">
                            <VButton
                                :label="$t('searchBar.dates')"
                                :color="dateDialogSection === IDateDialogSection.CALENDAR ? 'primary' : 'secondary'"
                                :variant="dateDialogSection === IDateDialogSection.CALENDAR ? 'text' : 'text'"
                                @click="setDateDialogSection(IDateDialogSection.CALENDAR)"
                            />
                            <VButton
                                :label="$t('searchBar.months')"
                                :color="dateDialogSection === IDateDialogSection.MONTHS ? 'primary' : 'secondary'"
                                :variant="dateDialogSection === IDateDialogSection.MONTHS ? 'text' : 'text'"
                                @click="setDateDialogSection(IDateDialogSection.MONTHS)"
                            />
                        </div>
                        <div v-else id="dp__calendar_header_items" role="row"></div>
                    </template>
                    <template #body>
                        <div v-show="dateDialogSection === IDateDialogSection.CALENDAR" id="calendar-container" class="calendar">
                            <VueDatePicker
                                v-if="calendarIsActive"
                                ref="calendarInstance"
                                auto-apply
                                :config="{ noSwipe: true }"
                                :day-class="getDayClass"
                                :disabled-dates="getDisabledDates"
                                focus-start-date
                                :hide-navigation="['time']"
                                hide-offset-dates
                                :highlight="highlightedOptions"
                                :inline="{ input: false }"
                                :locale="appStore.locale.code"
                                :range="rangeOptions"
                                :min-date="calendarMinDate"
                                :markers="getCalendarMarkers"
                                :max-date="calendarMaxDate"
                                :model-value="getModelValue"
                                :month-change-on-scroll="monthChangeOnScroll"
                                month-name-format="long"
                                :multi-calendars="multiCalendarOptions"
                                no-today
                                prevent-min-max-navigation
                                :start-date="calendarViewDate"
                                :transitions="screen.isDesktop"
                                @update:model-value="onPickerModelUpdated"
                                @internal-model-change="onPickerInternalModelUpdated"
                            >
                                <template v-if="screen.isMobile" #month-year="{ month, year, months }">
                                    <div :id="`calendar-year-month-${year}-${month || -1}`" class="month-year-title">
                                        <div v-if="typeof months !== 'undefined' && typeof month !== 'undefined'">{{ months[month].text }}</div>
                                        <div>{{ year }}</div>
                                    </div>
                                </template>
                                <template v-if="screen.isMobile" #calendar-header="{ day }">
                                    <Teleport to="#dp__calendar_header_items">
                                        <div class="dp__calendar_header_item">
                                            {{ day }}
                                        </div>
                                    </Teleport>
                                </template>
                            </VueDatePicker>
                        </div>

                        <div v-show="dateDialogSection === IDateDialogSection.MONTHS" class="months">
                            <div v-for="(month, mi) in getAvailableMonths" :key="mi" class="month" @click="handleMonthClick(month.date)">
                                <VIcon name="calendar-event-line" :size="24" />
                                <div class="month-name">
                                    {{ month.month }}
                                </div>
                                <div class="year">
                                    {{ month.year }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen.isMobile && (internalModel !== null || !calendarIsRange)" #footer>
                        <VAlert v-if="calendarIsRange && Array.isArray(internalModel) && (internalModel.length === 1 || internalModel[1] === null)">{{ $t('searchBar.defineDropDate') }}</VAlert>
                        <template v-else>
                            <VAlert v-if="calendarIsRange">{{ $t('searchBar.definePickupDateInfo') }}</VAlert>
                            <VAlert v-else>{{ $t('searchBar.defineDropDateInfo') }}</VAlert>
                            <VButton :label="$t('searchBar.ok')" variant="tonal" @click="searchBarStore.closeAllDialogs" />
                        </template>
                    </template>
                </VSearchDialog>
            </transition>
        </CDialogProxy>
    </div>
</template>
