import { createI18n } from 'vue-i18n';

import translations from './translations.json';

const i18n = createI18n({
    fallbackLocale: 'tr',
    legacy: false, // Vue 2 desteği kapalı
    locale: 'tr', // Varsayılan dil
    messages: translations
});

export default i18n;
