import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import type * as IAccountProfileResponse from '@/types/account/profile/response';
import type { ApiAxiosResponse } from '@/types/general';
import emitter from '@/utils/emitter';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

interface ISetLoginParams {
    tokenType: string;
    authProvider: string;
    accessToken: string;
    message?: string;
}

interface PStore {
    loaded: boolean;
    loading: boolean;
    customer: IAccountProfileResponse.Customer | null;

    loginModalOpened: boolean;
    registerModalOpened: boolean;
    forgotPasswordModalOpened: boolean;
    resetPasswordModalOpened: boolean;
}

const useAuthStore = defineStore('auth-auth-store', {
    actions: {
        fetchCustomer() {
            // console.log('fetching customer');
            this.loading = true;
            api.get('/customer-panel/profile')
                .then(async (response: ApiAxiosResponse<IAccountProfileResponse.Payload>) => {
                    if (response.data.status_code === 200) {
                        this.customer = response.data.data.customer as IAccountProfileResponse.Customer;
                        emitter.emit('customer:loaded');
                    } else {
                        this.customer = null;
                    }
                })
                .catch((error: any) => {
                    const statusCode = error?.response?.data?.status_code || error?.response?.status || 401;

                    if (statusCode === 401) {
                        this.customer = null;
                        this.setLogout();
                    }
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                });
        },
        fetchCustomerIfNotFetched() {
            if (this.loaded || this.loading) {
                return;
            }
            if (!localStorage.getItem('accessToken')) {
                this.loaded = true;
                return;
            }
            this.fetchCustomer();
        },
        logout() {
            this.loading = true;
            api.get('/auth/logout')
                .then(() => {
                    toast(i18n.global.t('auth.logout.success'), {
                        autoClose: 1000,
                        type: 'success'
                    });
                })
                .catch(() => {})
                .finally(() => {
                    this.setLogout();
                    this.loading = false;
                    this.customer = null;
                });
        },
        setLogin(params: ISetLoginParams) {
            localStorage.setItem('accessToken', params.accessToken);
            localStorage.setItem('authProvider', params.authProvider);
            // localStorage.setItem('tokenType', params.tokenType);

            this.fetchCustomer();
            toast.success(typeof params.message === 'undefined' ? i18n.global.t('auth.login.success') : params.message);
            emitter.emit('auth:login');
        },
        async setLogout() {
            const authProvider = localStorage.getItem('authProvider');

            if (authProvider !== null && authProvider !== 'elitcar') {
                await FirebaseAuthentication.signOut();
            }

            localStorage.removeItem('accessToken');
            localStorage.removeItem('authProvider');
            // localStorage.removeItem('tokenType');

            emitter.emit('auth:logout');
        }
    },

    getters: {
        getCustomer: (state) => state.customer,
        getEmail: (state) => {
            if (state.customer === null) {
                return null;
            }
            return state.customer.email;
        },
        getFullName: (state) => {
            if (state.customer === null) {
                return null;
            }
            return state.customer.firstName + ' ' + state.customer.lastName;
        },
        isAuthed: (state) => state.loaded && state.customer !== null,
        isLoaded: (state) => state.loaded,
        isLoading: (state) => state.loading
    },

    state: () =>
        ({
            customer: null,
            loaded: false,
            loading: false
        }) as PStore
});

export default useAuthStore;
