import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export interface ISitemapNodeItem {
    definition: string;
    name: string;
    slug: null | string;
    nodes?: ISitemapNodeItem[];
}

export interface IPageSitemapStore {
    loaded: boolean;
    loading: boolean;
    nodes: ISitemapNodeItem[];
}

export const usePageSitemapStore = defineStore('page-sitemap', {
    actions: {
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;
            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<IPageSitemapStore>) => {
                    this.$patch(
                        Object.assign(
                            {
                                loaded: true,
                                loading: false
                            },
                            response.data.data
                        )
                    );
                    status = true;
                })
                .catch(() => {
                    this.$reset();
                    status = false;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });
            return status;
        }
    },

    getters: {
        getData(state: IPageSitemapStore): IPageSitemapStore {
            return state;
        },
        getNodes(): ISitemapNodeItem[] {
            return this.nodes;
        }
    },

    state: () =>
        ({
            loaded: false,
            loading: false,
            nodes: []
        }) as IPageSitemapStore
});
