<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import moment from 'moment';
import { Calendar, DatePicker } from 'v-calendar';
import type { CalendarDay } from 'v-calendar/dist/types/src/utils/page.js';
import CDialogProxy from '@/components/CDialogProxy.vue';
import VButton from '@/components/Forms/VButton.vue';
import VDateSlot from '@/components/Search/DateSlot.vue';
import VSearchDialog from '@/components/Search/Dialog.vue';
import VIcon from '@/components/UI/VIcon.vue';
import screen from '@/plugins/screen';
import { useAppStore } from '@/stores/app-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useSearchBarStore from '@/stores/query/search-bar-store';
import type { DeliveryPointDetails } from '@/types/query/delivery-point';
import type { Clock, DateRange, Hour, HourRange } from '@/types/search/dates';
import { clockToMinutes, minutesToClock } from '@/utils/date-format';
import emitter from '@/utils/emitter';

const deliveryTimeStore = useDeliveryTimeStore();
const searchBarStore = useSearchBarStore();
const appStore = useAppStore();

const hour = (clock: string): Hour => {
    return {
        label: clock,
        minutes: clockToMinutes(clock)
    };
};
const hourRange = (start?: string, end?: string): HourRange => {
    return {
        end: hour(end || '23:59'),
        start: hour(start || '00:00')
    };
};
const dayClocks = (day: Date, availableHours: HourRange, dateRange: DateRange | null, match: Date | string | null, step: number = 30): Clock[] => {
    let clocks: Clock[] = [];
    let dayMoment = moment(day).startOf('day');
    let minMoment = moment(dateRange?.start || moment(day).startOf('day'));
    let maxMoment = moment(dateRange?.end || moment(day).endOf('day'));
    for (let i = 0; i < 1440; i += step) {
        let isActive = i >= availableHours.start.minutes && i <= availableHours.end.minutes;
        let hour: Hour = {
            label: minutesToClock(i),
            minutes: i
        };
        let clockMoment = moment(dayMoment).add(i, 'minutes');
        if (clockMoment.isBefore(minMoment) || clockMoment.isAfter(maxMoment)) {
            isActive = false;
        }
        let isSelected = false;
        if (match != null && isActive) {
            if (typeof match == 'string' && match.length == 5) {
                isSelected = hour.label == match;
            } else {
                isSelected = clockMoment.isSame(moment(match));
            }
        }
        // disable 00:00
        if (i == 0) {
            isActive = false;
        }
        clocks.push({
            hour: hour,
            isActive: isActive,
            isSelected: isSelected,
            moment: clockMoment
        });
    }
    return clocks;
};
const getAvailableClock = (day: Date, availableHours: HourRange, dateRange: DateRange | null, match: Date | string | null, maxDay: number = 3): Clock => {
    let clocks: Clock[] = [];
    while (maxDay > 0) {
        clocks = dayClocks(day, availableHours, dateRange, match);
        let activeClocks: Clock[] = clocks.filter((c) => c.isActive);
        let matchClock = clocks.find((c) => c.isSelected);
        if (matchClock != null) {
            return matchClock;
        }
        if (activeClocks != null && activeClocks.length > 0) {
            return activeClocks[0];
        }
        day = moment(day).add(1, 'days').toDate();
        maxDay--;
    }
    return clocks[0];
};

const calendar = ref<typeof Calendar | null>(null);

const pickupAvailableHours = ref<HourRange>({ end: hour('23:59'), start: hour('00:00') });
const dropAvailableHours = ref<HourRange>({ end: hour('23:59'), start: hour('00:00') });
const earliestReservationHour = ref<number>(2);
const minRentalHour = ref<number>(4);

const calendarAttributes = ref<any>(undefined);
const calendarMinDate = ref<any>(undefined);
const calendarMaxDate = ref<any>(undefined);
const calendarIsRange = ref<boolean>(true);
const calendarActive = ref<boolean>(true);

// en erken alış vakti
const ruleMinPickupMoment = computed(() => {
    //  (api kuralı)
    let m = moment().add(earliestReservationHour.value, 'hours');
    // günün en erken saati
    let todayMinPickupMoment = moment().startOf('day').add(pickupAvailableHours.value.start.minutes, 'minutes');
    // eğer en erken alış vakti bugünün en erken saatinden önceyse, en erken alış vaktini bugünün en erken saatine eşitle.
    if (m.isBefore(todayMinPickupMoment)) {
        m = todayMinPickupMoment;
    }
    // günün en geç alış vakti
    let todayMaxPickupMoment = moment().startOf('day').add(pickupAvailableHours.value.end.minutes, 'minutes');
    // eğer en erken alış vakti bugünün en geç saatinden sonra ise, en erken alış vaktini yarınki en erken saatine eşitle.
    if (m.isAfter(todayMaxPickupMoment)) {
        m = todayMaxPickupMoment.add(1, 'days').startOf('day').add(pickupAvailableHours.value.start.minutes, 'minutes');
    }
    // console.log('MIN PICKUP', m.format('YYYY-MM-DD HH:mm'))
    return m;
});

// en erken teslim vakti
const ruleMinDropMoment = computed(() => {
    let pickupMoment = moment(ruleMinPickupMoment.value);
    if (deliveryTimeStore.pickupDateTime != null) {
        pickupMoment = moment(deliveryTimeStore.pickupDateTime);
    }
    //  (api kuralı)
    let m = moment(pickupMoment).add(minRentalHour.value, 'hours');
    // günün en erken saati
    let todayMinDropMoment = moment().startOf('day').add(dropAvailableHours.value.start.minutes, 'minutes');
    // eğer en erken teslim vakti bugünün en erken saatinden önceyse, en erken teslim vaktini bugünün en erken saatine eşitle.
    if (m.isBefore(todayMinDropMoment)) {
        m = todayMinDropMoment;
    }
    // günün en geç teslim vakti
    let todayMaxDropMoment = moment().startOf('day').add(dropAvailableHours.value.end.minutes, 'minutes');
    // eğer en erken teslim vakti bugünün en geç saatinden sonra ise, en erken teslim vaktini yarınki en erken saatine eşitle.
    if (m.isAfter(todayMaxDropMoment)) {
        let yesterdayStart = todayMaxDropMoment.add(1, 'days').startOf('day').add(dropAvailableHours.value.start.minutes, 'minutes');
        if (m.isBefore(yesterdayStart)) {
            m = yesterdayStart;
        }
    }
    // console.log('MIN DROP', m.format('YYYY-MM-DD HH:mm'))
    return m;
});

const setPickupDate = (date: Date) => {
    let availableClock = getAvailableClock(date, pickupAvailableHours.value, { end: null, start: ruleMinPickupMoment.value.toDate() }, date);
    deliveryTimeStore.pickupDateTime = availableClock.moment.toDate();
};
const setDropDate = (date: Date) => {
    let availableClock = getAvailableClock(date, dropAvailableHours.value, { end: null, start: ruleMinDropMoment.value.toDate() }, date);
    deliveryTimeStore.dropDateTime = availableClock.moment.toDate();
};

const sync = () => {
    if (deliveryTimeStore.getPickupDateTime == null) {
        setPickupDate(new Date());
    }
    if (deliveryTimeStore.getDropDateTime == null) {
        setDropDate(moment(deliveryTimeStore.getPickupDateTime).add(10, 'days').toDate());
    }

    // if (calendar.value == null) return;
    calendarMinDate.value = ruleMinPickupMoment.value.toDate();
};

const onPickupPointDetailsChanged = (e: unknown) => {
    let deliveryPointDetails = e as DeliveryPointDetails;
    if (deliveryPointDetails == null) return;

    pickupAvailableHours.value = hourRange(deliveryPointDetails.rules?.availableHours[0], deliveryPointDetails.rules?.availableHours[1]);

    sync();
};
const onDropPointDetailsChanged = (e: unknown) => {
    let deliveryPointDetails = e as DeliveryPointDetails;
    if (deliveryPointDetails == null) return;

    dropAvailableHours.value = hourRange(deliveryPointDetails.rules?.availableHours[0], deliveryPointDetails.rules?.availableHours[1]);
    sync();
};

// delivery-time:pickup-date-set
// const onDeliveryTimePickupDateSet = (e: unknown) => {
//     setPickupDate( toDate(e as string) );
// };
// delivery-time:drop-date-set
// const onDeliveryTimeDropDateSet = (e: unknown) => {
//     setDropDate( toDate(e as string) );
// };

onMounted(() => {
    emitter.on('pickup-delivery-point-details:changed', onPickupPointDetailsChanged);
    emitter.on('drop-delivery-point-details:changed', onDropPointDetailsChanged);
    // emitter.on('delivery-time:pickup-date-set',onDeliveryTimePickupDateSet)
    // emitter.on('delivery-time:drop-date-set',onDeliveryTimeDropDateSet)
    sync();
});

onUnmounted(() => {
    emitter.off('pickup-delivery-point-details:changed', onPickupPointDetailsChanged);
    emitter.off('drop-delivery-point-details:changed', onDropPointDetailsChanged);
    // emitter.off('delivery-time:pickup-date-set',onDeliveryTimePickupDateSet)
    // emitter.off('delivery-time:drop-date-set',onDeliveryTimeDropDateSet)
});

const getDatepickerRange = computed(() => {
    if (calendarIsRange.value == false) return null;
    return {
        end: deliveryTimeStore.getDropDateTime || new Date(),
        start: deliveryTimeStore.getPickupDateTime || new Date()
    };
});
const onDateRangeChanged = (value: any) => {
    if (calendarIsRange.value == false) return;
    if (value == null) return;
    let start = moment(value.start);
    let end = moment(value.end);
    // eğer tarhiler değişmedi ise çık
    if (start.startOf('day').isSame(moment(deliveryTimeStore.getPickupDateTime).startOf('day')) && end.startOf('day').isSame(moment(deliveryTimeStore.getDropDateTime).startOf('day'))) {
        return;
    }
    // start ve end in sadece gün,ay,yıl kısmını al ve pickupDateTime ın saatini ekleyerek yeni bir tarih oluştur.
    let pickupDateTime = moment(start.format('YYYY-MM-DD') + ' ' + deliveryTimeStore.getPickupClockText, 'YYYY-MM-DD HH:mm');
    let dropDateTime = moment(end.format('YYYY-MM-DD') + ' ' + deliveryTimeStore.getDropClockText, 'YYYY-MM-DD HH:mm');

    setPickupDate(pickupDateTime.toDate());
    setDropDate(dropDateTime.toDate());

    searchBarStore.closeAllDialogs();
};
const handleDayClick = (day: CalendarDay, e: MouseEvent) => {
    if (searchBarStore.getIsDeliveryDateDropDialogOpened && !day.isDisabled) {
        calendarIsRange.value = true;
        // deliveryTimeStore.setDropDateTime(day.date);
        setDropDate(day.date);
        searchBarStore.closeAllDialogs();
    }
};

const switchCalendarToPickup = () => {
    calendarAttributes.value = [];
    calendarIsRange.value = true;
    calendarMinDate.value = ruleMinPickupMoment.value.toDate();
    calendarActive.value = false;
    nextTick(() => {
        calendarActive.value = true;
    });
};
const switchCalendarToDrop = () => {
    if (deliveryTimeStore.getPickupDateTime == null) {
        searchBarStore.openDeliveryDatePickupDialog();
        return;
    }
    calendarAttributes.value = [
        {
            dates: {
                end: deliveryTimeStore.getDropDateTime,
                start: deliveryTimeStore.getPickupDateTime
            },
            highlight: {
                base: { fillMode: 'light' },
                end: { color: 'red', fillMode: 'outline' },
                start: { fillMode: 'solid' }
            }
        }
    ];
    calendarIsRange.value = false;
    calendarMinDate.value = ruleMinDropMoment.value.toDate();
    calendarActive.value = false;
    nextTick(() => {
        calendarActive.value = true;
        nextTick(() => {
            calendar.value?.move(deliveryTimeStore.getDropDateTime);
        });
    });
};

const handlePickupDateClick = () => {
    searchBarStore.openDeliveryDatePickupDialog();
    switchCalendarToPickup();
};
const handleDropDateClick = () => {
    if (deliveryTimeStore.getPickupDateTime == null) {
        searchBarStore.openDeliveryDatePickupDialog();
        return;
    }
    searchBarStore.openDeliveryDateDropDialog();
    switchCalendarToDrop();
};

const handlePickupClockClick = () => {
    searchBarStore.openDeliveryClockPickupDialog();
};
const handleDropClockClick = () => {
    searchBarStore.openDeliveryClockDropDialog();
};

// watch deliveryTimeStore.getMinPickupDate
// watch(
//     () => deliveryTimeStore.getMinPickupDate,
//     (newValue) => {
//         // let minMoment = moment(newValue);
//         // // eğer deliveryTimeStore.getPickupDateTime newValue den küçükse, pickupDateTime ı newValue yap.
//         // let pickupMoment = moment(deliveryTimeStore.getPickupDateTime);
//         // if (pickupMoment.isBefore(minMoment)) {
//         //     deliveryTimeStore.setPickupDateTime(minMoment.toDate());
//         // }
//         // // eğer deliveryTimeStore.getDropDateTime getPickupDateTime dan küçükse, dropDateTime ı getPickupDateTime + 1 gün yap.
//         // let dropMoment = moment(deliveryTimeStore.getDropDateTime);
//         // if (dropMoment.isBefore(minMoment)) {
//         //     deliveryTimeStore.setDropDateTime(minMoment.add(1, 'days').toDate());
//         // }
//     }
// );

const onClockSelected = (clockString: Clock) => {
    if (searchBarStore.getIsDeliveryClockDropDialogOpened) {
        let dropMoment = moment(deliveryTimeStore.getDropDateTime).startOf('day').add(clockString.hour.minutes, 'minutes');
        setDropDate(dropMoment.toDate());
        //     setDateRange(
        //         moment(deliveryTimeStore.pickupDateTime).toDate(),
        //         moment(dropMoment.format('YYYY-MM-DD') + ' ' + clockString, 'YYYY-MM-DD HH:mm').toDate()
        //     );
        searchBarStore.closeAllDialogs();
    }
    if (searchBarStore.getIsDeliveryClockPickupDialogOpened) {
        let pickupMoment = moment(deliveryTimeStore.getPickupDateTime).startOf('day').add(clockString.hour.minutes, 'minutes');
        setPickupDate(pickupMoment.toDate());
        //     setDateRange(
        //         moment(pickupMoment.format('YYYY-MM-DD') + ' ' + clockString, 'YYYY-MM-DD HH:mm').toDate(),
        //         moment(deliveryTimeStore.dropDateTime).toDate()
        //     );
        searchBarStore.closeAllDialogs();
        // searchBarStore.openDeliveryClockDropDialog();
    }
};

enum IDateDialogSection {
    CALENDAR = 'calendar',
    MONTHS = 'months'
}
const dateDialogSection = ref(IDateDialogSection.CALENDAR);
const setDateDialogSection = (section: IDateDialogSection) => {
    dateDialogSection.value = section;
};
const handleMonthClick = (monthDate: string) => {
    setDateDialogSection(IDateDialogSection.CALENDAR);
    calendar.value?.move(moment(monthDate, 'DD.MM.YYYY').toDate());
};

const getAvailableClocks = computed(() => {
    let clocks: Clock[] = [];
    if (searchBarStore.getIsDeliveryClockPickupDialogOpened) {
        clocks = dayClocks(deliveryTimeStore.getPickupDateTime || new Date(), pickupAvailableHours.value, { end: null, start: ruleMinPickupMoment.value.toDate() }, deliveryTimeStore.getPickupClockText);
    }
    if (searchBarStore.getIsDeliveryClockDropDialogOpened) {
        clocks = dayClocks(deliveryTimeStore.getDropDateTime || new Date(), dropAvailableHours.value, { end: null, start: ruleMinDropMoment.value.toDate() }, deliveryTimeStore.getDropClockText);
    }
    return clocks;
});

watch(
    () => searchBarStore.getIsDeliveryDateDialogOpened,
    (newValue) => {
        if (newValue) {
            if (searchBarStore.getIsDeliveryDatePickupDialogOpened) {
                switchCalendarToPickup();
            }
            if (searchBarStore.getIsDeliveryDateDropDialogOpened) {
                switchCalendarToDrop();
            }
        }
    }
);
watch(
    () => searchBarStore.getIsDeliveryClockDialogOpened,
    (newValue) => {
        if (newValue) {
            setTimeout(() => {
                let activeClock = document.querySelector('.clocks .active');
                if (activeClock != null && screen.value.isMobile) {
                    activeClock.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 1);
        }
    }
);

// just for month selection
const getAvailableMonths = computed(() => {
    let list = [];
    let startMoment = moment();
    for (let i = 0; i < 12; i++) {
        list.push({
            date: startMoment.format('DD.MM.YYYY'),
            month: startMoment.format('MMMM'),
            year: startMoment.format('YYYY')
        });
        startMoment.add(1, 'month');
    }
    return list;
});
</script>

<template>
    <div class="v-search-dates">
        <div class="inputs">
            <VDateSlot
                tag="pickup"
                :label="$t('searchBar.pickupDateTime')"
                :date="deliveryTimeStore.getPickupDateText"
                :clock="deliveryTimeStore.getPickupClockText"
                @onDateClick="handlePickupDateClick"
                @onClockClick="handlePickupClockClick"
            />
            <VDateSlot
                tag="drop"
                :label="$t('searchBar.dropDateTime')"
                :date="deliveryTimeStore.getDropDateText"
                :clock="deliveryTimeStore.getDropClockText"
                @onDateClick="handleDropDateClick"
                @onClockClick="handleDropClockClick"
            />
        </div>

        <CDialogProxy>
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VSearchDialog class="v-search-dialog-date" v-if="searchBarStore.getIsDeliveryClockDialogOpened">
                    <template #title>
                        <span v-if="searchBarStore.getIsDeliveryClockPickupDialogOpened">
                            {{ $t('searchBar.definePickupClock') }}
                        </span>
                        <span v-if="searchBarStore.getIsDeliveryClockDropDialogOpened">
                            {{ $t('searchBar.defineDropClock') }}
                        </span>
                    </template>
                    <template #body>
                        <div class="clocks">
                            <template :key="ci" v-for="(clock, ci) in getAvailableClocks">
                                <div
                                    :class="{
                                        active: clock.isSelected,
                                        disabled: !clock.isActive
                                    }"
                                    @click="clock.isActive && onClockSelected(clock)"
                                >
                                    {{ clock.hour.label }}
                                </div>
                            </template>
                        </div>
                    </template>
                </VSearchDialog>
            </transition>
            <!-- </CDialogProxy> -->

            <!-- <CDialogProxy> -->
            <transition :name="screen.isMobile ? 'to-up' : 'to-down'">
                <VSearchDialog class="v-search-dialog-date" v-if="searchBarStore.getIsDeliveryDateDialogOpened">
                    <template #title>
                        <span v-if="searchBarStore.getIsDeliveryDatePickupDialogOpened">
                            {{ $t('searchBar.pickupDropDate') }}
                        </span>
                        <span v-if="searchBarStore.getIsDeliveryDateDropDialogOpened">
                            {{ $t('searchBar.dropDate') }}
                        </span>
                    </template>
                    <template #interaction>
                        <div class="section-switchers">
                            <VButton
                                :label="$t('searchBar.dates')"
                                :color="dateDialogSection === IDateDialogSection.CALENDAR ? 'primary' : 'secondary'"
                                :variant="dateDialogSection === IDateDialogSection.CALENDAR ? 'text' : 'text'"
                                @click="setDateDialogSection(IDateDialogSection.CALENDAR)"
                            />
                            <VButton
                                :label="$t('searchBar.months')"
                                :color="dateDialogSection === IDateDialogSection.MONTHS ? 'primary' : 'secondary'"
                                :variant="dateDialogSection === IDateDialogSection.MONTHS ? 'text' : 'text'"
                                @click="setDateDialogSection(IDateDialogSection.MONTHS)"
                            />
                        </div>
                    </template>
                    <template #body>
                        <div class="calendar" v-show="dateDialogSection === IDateDialogSection.CALENDAR">
                            <DatePicker
                                mode="range"
                                :model-value="getDatepickerRange"
                                :is-range="calendarIsRange"
                                :attributes="calendarAttributes"
                                :locale="appStore.locale.code"
                                :min-date="calendarMinDate"
                                :max-date="calendarMaxDate"
                                borderless
                                transparent
                                expanded
                                :columns="screen.isMobile ? 1 : 2"
                                :rows="screen.isMobile ? 2 : 1"
                                view="monthly"
                                @dayclick="handleDayClick"
                                @update:modelValue="onDateRangeChanged"
                                ref="calendar"
                                v-if="calendarActive"
                            />
                        </div>

                        <div class="months" v-show="dateDialogSection === IDateDialogSection.MONTHS">
                            <div :key="mi" v-for="(month, mi) in getAvailableMonths" class="month" @click="handleMonthClick(month.date)">
                                <VIcon name="calendar-event-line" :size="24" />
                                <div class="month-name">
                                    {{ month.month }}
                                </div>
                                <div class="year">
                                    {{ month.year }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #footer v-if="screen.isMobile">
                        <VButton :label="$t('searchBar.ok')" variant="tonal" @click="searchBarStore.closeAllDialogs" />
                    </template>
                </VSearchDialog>
            </transition>
        </CDialogProxy>
    </div>
</template>
