<script setup lang="ts">
import { onMounted } from 'vue';

import type { ICurrency } from '@/stores/app/currency-store';
import useCurrencyStore from '@/stores/app/currency-store';
import emitter from '@/utils/emitter';

const currencyStore = useCurrencyStore();

onMounted(() => {
    currencyStore.initialize();
});

const handleCurrencyClick = (currency: ICurrency) => {
    currencyStore.setActiveCurrency(currency);
    emitter.emit('currency:change', currency);
};
</script>

<template>
    <div class="v-currency-switcher-horizontal">
        <div class="title">{{ $t('directives.currencySwitcher.selectCurrency') }}</div>
        <ul>
            <template v-for="currency in currencyStore.getCurrencyList" :key="currency.code">
                <li :class="{ active: currencyStore.getCurrency.code === currency.code }" @click="handleCurrencyClick(currency)">
                    {{ currency.symbol }}
                </li>
            </template>
            <!-- <li><a href="javascript:;">₺</a></li>
            <li class="active"><a href="#">€</a></li>
            <li><a href="javascript:;">$</a></li>
            <li><a href="javascript:;">CHF</a></li>
            <li><a href="javascript:;">£</a></li> -->
        </ul>
    </div>
</template>
