<script lang="ts" setup>
import CioLink from '@/components/CioLink.vue';
import VButton from '@/components/Forms/VButton.vue';
import VIcon from '@/components/UI/VIcon.vue';
import useMenu from '@/composables/useMenu';
import useAuthStore from '@/stores/auth/auth-store';

const authStore = useAuthStore();

const { getAccountMenuItems } = useMenu();
</script>

<template>
    <div class="v-auth-menus" v-if="authStore.isAuthed != null">
        <div class="customer">
            <div class="avatar">
                <VIcon name="user-line" :size="24" />
            </div>
            <div class="profile">
                <div class="fullname">
                    {{ authStore.getFullName }}
                </div>
                <div class="email">
                    {{ authStore.getEmail }}
                </div>
            </div>
        </div>

        <ul class="menu-items">
            <template :key="item.slug" v-for="item in getAccountMenuItems">
                <li>
                    <CioLink root="account" :name="item.template">
                        {{ item.label }}
                    </CioLink>
                </li>
            </template>
        </ul>
        <hr />
        <VButton variant="tonal" color="secondary" :label="$t('auth.logout.title')" @click="authStore.logout" full-width />
    </div>
</template>
