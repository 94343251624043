import { browserIsMobile } from '@/plugins/screen';

const scrollToElement = (element: Element, offset: number, onlyNeeded: boolean, cb: (() => void) | undefined = undefined): Promise<void> => {
    return new Promise((resolve) => {
        const appDom = window.document.getElementById('app');

        // get element top for scroll
        const rect = element.getBoundingClientRect();
        const offsetTop = rect.top - parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')) - document.getElementsByClassName('v-header')[0].clientHeight - offset;
        // console.log(offsetTop, rect.top, parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')), document.getElementsByClassName('v-header')[0].clientHeight, offset);

        // get window height
        // const scrollHeight = appDom?.scrollHeight || 0;
        const viewportHeight = appDom?.clientHeight || 0;

        // get scroll position
        const scrollPosition = appDom?.scrollTop || 0;
        let isElementVisible = !(offsetTop < 0 || offsetTop - viewportHeight >= 0);
        // console.log(scrollPosition, isElementVisible);

        if (isElementVisible) {
            const scrollViewport = scrollPosition + viewportHeight;

            if (scrollViewport < rect.bottom) {
                // console.log(offsetTop, scrollViewport, rect.bottom);
                isElementVisible = false;
            }
        }

        if (isElementVisible && onlyNeeded) {
            if (typeof cb === 'function') {
                cb();
            }

            resolve();
            return;
        }

        const targetScrollPosition = scrollPosition + offsetTop;

        const distance = Math.abs(scrollPosition - targetScrollPosition);

        // setTimeout(
        //     () => {
        //         // scroll to element
        //         appDom?.scrollTo({
        //             behavior: 'smooth',
        //             top: scrollPosition + offsetTop
        //         });
        //
        //         setTimeout(() => {
        //             if (typeof cb === 'function') {
        //                 cb();
        //             }
        //
        //             resolve();
        //         }, distance / 2);
        //     },
        //     browserIsMobile ? 2000 : 0
        // );

        // scroll to element
        appDom?.scrollTo({
            behavior: 'smooth',
            top: scrollPosition + offsetTop
        });

        setTimeout(() => {
            if (typeof cb === 'function') {
                cb();
            }

            resolve();
        }, distance / 2);
    });
};

export default scrollToElement;
