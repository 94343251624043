import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import useLoginStore from '@/stores/auth/login-store';
import useAuthModalStore from '@/stores/auth/modal-store';
import type * as IRequest from '@/types/auth/password-restore/request';
import type * as IResponse from '@/types/auth/password-restore/response';
import type { ApiAxiosResponse } from '@/types/general';

interface PStore {
    emailFieldIsDisabled: boolean;
    isLoading: boolean;
    payload: IRequest.Payload;
}
const usePasswordRestoreStore = defineStore('password-restore-store', {
    actions: {
        passwordRestore(): Promise<void> {
            return new Promise((resolve, reject) => {
                this.isLoading = true;
                api.post('/auth/restore-password', this.payload)
                    .then((response: ApiAxiosResponse<IResponse.Payload>) => {
                        if (response.data.status_code === 200) {
                            useAuthModalStore().closePasswordRestoreModal();

                            const loginStore = useLoginStore();
                            loginStore.setPayloadEmail(this.payload.email);
                            loginStore.setPayloadPassword(this.payload.password);
                            loginStore.login();

                            toast.success(i18n.global.t('auth.passwordRestore.success'));

                            resolve();
                        }
                    })
                    .catch(() => {
                        // this lines deprecated because error messages moved to axios plugin
                        // let message = error.response.data.message;
                        // if (error.response.status === 401) {
                        //     message = i18n.global.t('auth.passwordRestore.error');
                        // }
                        // toast(message, {
                        //     autoClose: 1000,
                        //     type: 'error'
                        // });
                        reject();
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });
        },
        setEmailFieldIsDisabled(value: boolean) {
            this.emailFieldIsDisabled = value;
        },
        setPayloadEmail(email: string) {
            this.payload.email = email;
        },
        setPayloadPassword(password: string) {
            this.payload.password = password;
        },
        setPayloadRePassword(rePassword: string) {
            this.payload.rePassword = rePassword;
        },
        setPayloadToken(token: string) {
            this.payload.token = token;
        }
    },

    getters: {
        getPayloadEmail(state) {
            return state.payload.email;
        },
        getPayloadPassword(state) {
            return state.payload.password;
        },
        getPayloadRePassword(state) {
            return state.payload.rePassword;
        },
        getPayloadToken(state) {
            return state.payload.token;
        }
    },

    state: () =>
        ({
            emailFieldIsDisabled: false,
            isLoading: false,
            payload: {
                email: '',
                password: '',
                rePassword: '',
                token: ''
            }
        }) as PStore
});

export default usePasswordRestoreStore;
