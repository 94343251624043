import { defineStore } from 'pinia';
import type { RouteLocationNamedRaw } from 'vue-router';
import { defaultCurrency, enabledCurrencies, type ILocale, locales } from '@/definitions';
import i18n from '@/locales/locale';
import { setApiLocale } from '@/plugins/axios';
import emitter from '@/utils/emitter';
import screen from '@/plugins/screen';

export interface IRoute {
    name: string;
    params?: {
        [key: string]: string | string[];
    };
}
export interface IRouterLocaleParams {
    [key: string]: { [key: string]: string };
}

export interface IRouterLocalizedLocation {
    code: string;
    route: RouteLocationNamedRaw;
}

export interface ICapacitor {
    isNative: boolean;
    platform: 'web' | 'android' | 'ios';
}

interface IBackRouteBlocker {
    key: string;
    fn: () => void;
}

// Store Interface
interface IAppStore {
    backRequestCall: boolean;
    backRouteBlockers: IBackRouteBlocker[];
    backRoutePath: null | string;
    baseUrl: string;
    blockMessage: null | string;
    capacitor: ICapacitor;
    cdnUrl: string;
    darkMode: 'auto' | 'on' | 'off';
    hideFooter: boolean;
    hideMobileAppWidget: boolean;
    locale: ILocale;
    mobileHeaderTitle: null | string;
    mobileMode: boolean;
    ready: boolean;
    route: IRoute;
    routerLocaleParams: IRouterLocaleParams;
    routerLocalizedLocations: IRouterLocalizedLocation[];
    serverRendered: boolean;
    ssrMode: boolean;
    countryCode: string;
}

export const useAppStore = defineStore('app', {
    actions: {
        addBackRouteBlocker(value: IBackRouteBlocker) {
            if (this.backRouteBlockers.findIndex((bb) => bb.key === value.key) === -1) {
                this.backRouteBlockers.unshift(value);
            }
        },
        removeBackRouteBlocker(key: string) {
            const index = this.backRouteBlockers.findIndex((i: IBackRouteBlocker) => i.key === key);

            if (index !== -1) {
                this.backRouteBlockers.splice(index, 1);
            }
        },
        setBackRequestCall(value: boolean) {
            this.backRequestCall = value;
        },
        setBackRoutePath(value: null | string) {
            this.backRoutePath = value;
        },
        setBaseUrl(value: string) {
            this.baseUrl = value.slice(-1) === '/' ? value.slice(0, -1) : value;
        },
        setBlockMessage(value: null | string) {
            this.blockMessage = value;
        },
        setCapacitorIsNative(value: ICapacitor['isNative']) {
            this.capacitor.isNative = value;
        },
        setCapacitorPlatform(value: ICapacitor['platform']) {
            this.capacitor.platform = value;
        },
        setCdnUrl(value: string) {
            this.cdnUrl = value;
        },
        setCountryCode(value: string) {
            this.countryCode = value;
        },
        setDarkMode(value: 'auto' | 'on' | 'off') {
            this.darkMode = value;
        },
        setHideFooter(value: boolean) {
            this.hideFooter = value;
        },
        setHideMobileAppWidget(value: boolean) {
            this.hideMobileAppWidget = value;
        },
        setLocale(value: ILocale) {
            const locale = value;

            if (typeof locale !== 'undefined') {
                const hasChange = locale.code !== this.locale.code;
                this.locale = locale;
                this.updateAlternateRouteLinks();
                setApiLocale(locale.path);
                i18n.global.locale.value = locale.code as keyof typeof i18n.global.messages.value;

                if (hasChange) {
                    emitter.emit('locale-code:changed');
                }
            }
        },
        setLocaleWithId(value: number) {
            const locale = locales.find((l) => l.id === value);

            if (typeof locale !== 'undefined') {
                this.setLocale(locale);
            }
        },
        setMobileHeaderTitle(value: null | string) {
            if (this.mobileHeaderTitle !== value) {
                this.mobileHeaderTitle = value;
            }
        },
        setMobileMode(value: boolean) {
            this.mobileMode = value;
        },
        setReady(value: boolean) {
            this.ready = value;
        },
        setRoute(value: IRoute) {
            this.route = value;
        },
        setRouterLocaleParams(params: IRouterLocaleParams) {
            this.routerLocaleParams = params;
            this.updateAlternateRouteLinks();
        },
        setRouterLocalizedLocations(value: IRouterLocalizedLocation[]) {
            this.routerLocalizedLocations = value;
        },
        setServerRendered(value: boolean) {
            this.serverRendered = value;
        },
        setSsrMode(value: boolean) {
            this.ssrMode = value;
        },
        updateAlternateRouteLinks() {
            const tempRouterLocalizedLocations = [];

            if (this.route.name !== '' && this.route.name.includes('.' + this.locale.path)) {
                for (const ll of locales.filter((l) => l.isActive)) {
                    if (
                        ll.path !== null &&
                        (typeof this.route.params !== 'object' ||
                            Object.keys(this.route.params).length === 0 ||
                            (typeof this.routerLocaleParams[ll.path] === 'object' && Object.keys(this.routerLocaleParams[ll.path]).length > 0)) &&
                        this.$router.hasRoute(this.route.name.replace('.' + this.locale.path, '.' + ll.path))
                    ) {
                        tempRouterLocalizedLocations.push({
                            code: ll.code,
                            route: {
                                name: this.route.name.replace('.' + this.locale.path, '.' + ll.path),
                                params: typeof this.routerLocaleParams[ll.path] === 'undefined' ? {} : this.routerLocaleParams[ll.path]
                            }
                        });
                    }
                }
            }

            this.setRouterLocalizedLocations(tempRouterLocalizedLocations);
        }
    },

    getters: {
        getCountryCode(state: IAppStore): string {
            return state.countryCode;
        },
        getCurrencyCode(): string {
            if (!import.meta.env.SSR) {
                const _currency = localStorage.getItem('currency');
                if (_currency && enabledCurrencies.includes(_currency)) {
                    return _currency;
                }
            }
            return defaultCurrency;
        },
        getFirstBackRouteBlocker: (state: IAppStore) => {
            let backRouteBlocker = null;

            if (state.backRouteBlockers.length > 0) {
                backRouteBlocker = state.backRouteBlockers[0];
            }

            return backRouteBlocker;
        },
        getMobileHeaderTitle(state: IAppStore): null | string {
            return screen.value.isMobile ? state.mobileHeaderTitle : null;
        }
    },

    state: () =>
        ({
            backRequestCall: false,
            backRouteBlockers: [],
            backRoutePath: null,
            baseUrl: '',
            blockMessage: null,
            capacitor: {
                isNative: false,
                platform: 'web'
            },
            cdnUrl: '',
            countryCode: '',
            darkMode: 'auto',
            hideFooter: false,
            hideMobileAppWidget: false,
            locale: locales.find((l) => l.isDefault),
            mobileHeaderTitle: null,
            mobileMode: false,
            ready: false,
            route: {
                name: '',
                params: {}
            },
            routerLocaleParams: {},
            routerLocalizedLocations: [],
            serverRendered: false,
            ssrMode: false
        }) as IAppStore
});
