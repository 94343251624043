import type { App } from 'vue';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import i18n from '@/locales/locale';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/browser';
import type { ApiAxiosResponse } from '@/types/general';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $api: AxiosInstance;
    }
}

const setApiAuth = (token: null | string): null | string => {
    const authorization = token === null ? null : `Bearer ${token}`;
    api.defaults.headers.common.Authorization = authorization;
    return authorization;
};

const setApiCurrency = (currency: null | string) => {
    api.defaults.headers.common['X-App-Currency'] = currency;
};

const setApiLocale = (locale: null | string) => {
    api.defaults.headers.common['X-App-Locale'] = locale;
};

const setApiSession = (session: null | string): null | string => {
    api.defaults.headers.common['X-App-Session'] = session;
    return session;
};

const setApiVisitor = (visitor: null | string): null | string => {
    api.defaults.headers.common['X-App-Visitor'] = visitor;
    return visitor;
};

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'X-App-Domain': import.meta.env.VITE_APP_DOMAIN
    }
});

if (import.meta.env.SSR) {
    api.defaults.headers.common['X-Front-End-Server'] = import.meta.env.VITE_APP_DOMAIN;
    // set useragent as Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36
    api.defaults.headers.common['User-Agent'] = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36';
    api.defaults.headers.common['X-App-Platform'] = import.meta.env.VITE_BUILD_TARGET;
    api.defaults.headers.common['X-Platform'] = 'web';
    api.defaults.headers.common['X-Uri'] = '/';
    api.defaults.headers.common['X-Uri-Params'] = '';
    api.defaults.headers.common['X-Referrer'] = '';
    // api.defaults.headers.common['X-Referrer-Params'] = '';
    api.defaults.headers.common['X-App-Session'] = '';
    api.defaults.headers.common['X-App-Visitor'] = '';
    api.defaults.headers.common['X-App-Locale'] = '';
    api.defaults.headers.common['X-App-Currency'] = 'EUR';
    api.defaults.headers.common['X-App-Visitor'] = '';
} else {
    setApiAuth(localStorage.getItem('accessToken'));
    setApiCurrency(localStorage.getItem('locale'));
    setApiLocale(localStorage.getItem('currency'));
    setApiSession(localStorage.getItem('session'));
    setApiVisitor(localStorage.getItem('visitor'));

    api.interceptors.request.use(async (config) => {
        while (!(config.url === '/liftoff' || sessionStorage.getItem('liftoff') !== null)) {
            await new Promise((resolve) => setTimeout(resolve, 50));
        }

        const referrer = window.document.referrer === '' ? null : new URL(window.document.referrer);

        config.headers.Authorization = setApiAuth(localStorage.getItem('accessToken'));
        // config.headers.Authorization = localStorage.getItem('authToken') === null ? null : `Bearer ${localStorage.getItem('authToken')}`;
        config.headers['X-App-Locale'] = api.defaults.headers.common['X-App-Locale'];
        config.headers['X-App-Currency'] = api.defaults.headers.common['X-App-Currency'];
        config.headers['X-App-Session'] = localStorage.getItem('session');
        config.headers['X-App-Visitor'] = localStorage.getItem('visitor');
        config.headers['X-Uri'] = window.location.pathname;
        config.headers['X-Uri-Params'] = window.location.search.replace('?', '');
        config.headers['X-Referrer'] = referrer === null ? '' : referrer.host + referrer.pathname;
        // config.headers['X-Referrer-Params'] = '';
        config.headers['X-App-Platform'] = Capacitor.isNativePlatform() ? 'mobile' : 'web';
        config.headers['X-Platform'] = Capacitor.getPlatform();

        return config;
    });

    api.interceptors.response.use(
        (response) => {
            localStorage.setItem('session', response.headers['x-app-session']);
            localStorage.setItem('visitor', response.headers['x-app-visitor']);

            return response;
        },
        (error) => {
            if (axios.isCancel(error) || error?.code === 'ECONNABORTED') {
                Sentry.withScope(function (scope) {
                    scope.setTag('action-name', 'axios-response-aborted');
                    scope.setLevel('log');
                    Sentry.captureException(error);
                });
            } else if (typeof error.response === 'undefined' || error.response === null) {
                toast.error(i18n.global.t('error.network'));
                Sentry.withScope(function (scope) {
                    scope.setTag('action-name', 'axios-request-network-issue');
                    scope.setLevel('error');
                    Sentry.captureException(error);
                });
            } else if (typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && (error.response.status === 0 || error.response.status >= 400)) {
                // if request 0 or 4** or 5** show toast
                ((errorX) => {
                    if (errorX.response.status === 0) {
                        toast.error(i18n.global.t('error.network'));
                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-network-issue');
                            scope.setLevel('error');
                            Sentry.captureException(errorX);
                        });
                        return;
                    }

                    if (errorX.response.status >= 500) {
                        toast.error(i18n.global.t('error.server'));
                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-server-issue');
                            scope.setLevel('error');
                            Sentry.captureException(errorX);
                        });
                        return;
                    }

                    const apiResponse = errorX.response as ApiAxiosResponse<any>;

                    // if auth error
                    if (apiResponse.data.status_code === 401) {
                        if (apiResponse.data.message !== null) {
                            toast.error(apiResponse.data.message);
                        }

                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-auth-fail');
                            scope.setLevel('log');
                            Sentry.captureException(errorX);
                        });

                        return;
                    }

                    // if data.data.messages exists 422
                    if (apiResponse.data.status_code === 422) {
                        const validationMessages = apiResponse.data.data.messages || {};
                        for (const key in validationMessages) {
                            const messages = validationMessages[key];
                            if (Array.isArray(messages) && messages.length > 0) {
                                const firstMessage = messages[0];
                                toast.error(firstMessage);
                            }
                        }

                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-server-validation');
                            scope.setLevel('log');
                            Sentry.captureException(errorX);
                        });

                        return;
                    }

                    // if data.data.message exists 400,421
                    if ((apiResponse.data.status_code === 400 || apiResponse.data.status_code === 421) && apiResponse.data.message !== null) {
                        toast.error(apiResponse.data.message);

                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-server-reject');
                            scope.setLevel('log');
                            Sentry.captureException(errorX);
                        });
                        return;
                    }

                    // if data.message exists
                    if (apiResponse.data.message !== null) {
                        toast.error(apiResponse.data.message);

                        Sentry.withScope(function (scope) {
                            scope.setTag('action-name', 'axios-response-unknown-with-message');
                            scope.setLevel('warning');
                            Sentry.captureException(errorX);
                        });

                        return;
                    }

                    // if unknown error
                    toast.error(i18n.global.t('error.unknown'));

                    Sentry.withScope(function (scope) {
                        scope.setTag('action-name', 'axios-response-unknown');
                        scope.setLevel('warning');
                        Sentry.captureException(errorX);
                    });
                })(error);
            }

            return Promise.reject(error);
        }
    );
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$api = api;
    }
};

export { api, setApiAuth, setApiCurrency, setApiLocale, setApiSession, setApiVisitor };
