<script lang="ts" setup>
import { computed, ref } from 'vue';

import { toast } from 'vue3-toastify';

import VAlert from '@/components/Forms/VAlert.vue';
import VButton from '@/components/Forms/VButton.vue';
import VTextInput from '@/components/Forms/VTextInput.vue';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';

const email = ref('');
const loading = ref(false);
const subscribed = ref(false);

const handleSubmit = () => {
    if (loading.value) return;
    if (!email.value.trim()) {
        toast.error(i18n.global.t('newsletter.subscribe.emptyEmailError'));
        return;
    }
    loading.value = true;
    api.post('/subscriptions/email', {
        email: email.value
    })
        .then((response: ApiAxiosResponse<any>) => {
            //TODO!
            toast.success(i18n.global.t('newsletter.subscribe.success'));
            subscribed.value = true;
        })
        .catch((error: any) => {
            // this lines deprecated because error messages moved to axios plugin
            // let message =
            //     error?.response?.data?.data?.messages?.email[0] ||
            //     error?.response?.data?.message ||
            //     error?.response?.data?.error ||
            //     error?.response?.data?.data?.message ||
            //     error?.message ||
            //     'An error occurred';
            // toast.error(message);
        })
        .finally(() => {
            loading.value = false;
        });
};

const classes = computed(() => ({
    'v-newsletter-subscribe': true
}));
</script>

<template>
    <div :class="classes">
        <template v-if="!subscribed">
            <VTextInput :value="email" @input="email = $event" :label="$t('newsletter.subscribe.label')" :placeholder="$t('newsletter.subscribe.placeholder')" prepend-icon="mail-open-line" />
            <VButton @click="handleSubmit" :loading="loading">
                {{ $t('newsletter.subscribe.subscribe') }}
            </VButton>
        </template>
        <template v-else>
            <VAlert type="info" :message="$t('newsletter.subscribe.subscribed')" />
        </template>
    </div>
</template>
