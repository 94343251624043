import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import { useNodeStore } from '@/stores/node-store';
import type { ApiAxiosResponse } from '@/types/general';
import type { ISearchFormPageTemplateContent } from '@/types/template/base';
import { setQueryFormParams } from '@/composables/useSetSearchFormContent';

export interface ISimpleIndexTemplateContent extends ISearchFormPageTemplateContent {
    pages: {
        image: string | null;
        listName: string | null;
        name: string | null;
        slug: string | null;
        summary: string | null;
        template: string | null;
    }[];
}

export interface ISimpleIndexStore extends ISimpleIndexTemplateContent {
    loaded: boolean;
    loading: boolean;
}

export const useSimpleIndexStore = defineStore('simple-index-store', {
    actions: {
        setData(value: ISimpleIndexTemplateContent) {
            this.$patch(value);
            setQueryFormParams(value);
        },
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<ISimpleIndexTemplateContent>) => {
                    this.setData(
                        Object.assign(
                            {
                                loaded: true,
                                loading: false
                            },
                            response.data.data
                        )
                    );
                    status = true;
                })
                .catch(() => {
                    status = false;
                    // console.log('content-data-load-fail: ' + nodeStore.slug);
                    // console.log(error);
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                });

            return status;
        }
    },

    getters: {
        getData(state) {
            return state;
        }
    },

    state: () =>
        ({
            loaded: false,
            loading: false,
            pages: [],
            queryFormParams: {
                dropAt: null,
                dropPointId: null,
                dropPointItem: null,
                pickupAt: null,
                pickupPointId: null,
                pickupPointItem: null,
                searchImmediately: false
            },
            vehicleFilters: []
        }) as ISimpleIndexStore
});
