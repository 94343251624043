<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = withDefaults(
    defineProps<{
        /**
         * Is the item active.
         * @type boolean
         * @required false
         */
        active?: boolean;

        /**
         * The text that identifies the component.
         * @type string
         * @required false
         */
        label: string | null;

        /**
         * The to that identifies the component.
         * @type string
         * @required false
         */
        to: string | null;
    }>(),
    {
        active: false,
        label: '',
        to: ''
    }
);

const classes = computed(() => ({
    active: props.active,
    'v-breadcrumb-item': true
}));
</script>

<template>
    <RouterLink :class="classes" :to="props.to" :title="props.label" v-if="props.label !== null && props.to !== null">{{ props.label }}</RouterLink>
</template>
