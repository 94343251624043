<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { vMaska } from 'maska/vue';

import VButton from '@/components/Forms/VButton.vue';
import VIcon from '@/components/UI/VIcon.vue';
import scrollToElement from '@/utils/scroll-to-element';

const props = withDefaults(
    defineProps<{
        /**
         * Append icon to the text input. Icon will be on the right side
         * @type string
         * @required false
         */
        appendIcon?: string;

        autocomplete?:
            | 'on'
            | 'off'
            | 'name'
            | 'email'
            | 'given-name'
            | 'additional-name'
            | 'family-name'
            | 'username'
            | 'new-password'
            | 'current-password'
            | 'organization'
            | 'street-address'
            | 'address-line1'
            | 'address-line2'
            | 'city'
            | 'state'
            | 'postal-code'
            | 'country'
            | 'tel'
            | 'fax'
            | 'cc-name'
            | 'cc-number'
            | 'cc-exp'
            | 'cc-cvc'
            | 'cc-csc'
            | 'month'
            | 'week'
            | 'day'
            | 'hour'
            | 'minute'
            | 'second'
            | 'date'
            | 'time'
            | 'datetime'
            | 'url'
            | 'search'
            | 'shipping'
            | 'billing'
            | 'contact';

        /**
         * Autofocus text input. Will be focused on page load
         * @type boolean
         * @required false
         * @default false
         */
        autofocus?: boolean;

        /**
         * Auto validate text input. Will show a check icon on the right side after appending icon if the text input is valid
         * @type boolean
         * @required false
         * @default false
         */
        autoValidate?: boolean;

        /**
         * Is clearable text input. Will show a clear icon on the right side after appending icon
         * @type boolean
         * @required false
         * @default false
         */
        clearable?: boolean;

        /**
         * Is disabled text input. Will not be able to type and will be grayed out
         * @type boolean
         * @required false
         * @default false
         */
        disabled?: boolean;

        /**
         * Enter key action of the text input. Can be done, go, next, previous, search, send. For mobile devices
         * @type 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'
         * @required false
         * @default done
         */
        enterKeyAction?: 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

        /**
         * Hint color. Will be set to the hint text and icon
         * @type 'default' | 'success' | 'warning' | 'error'
         * @required false
         * @default default
         */
        hintColor?: 'default' | 'success' | 'warning' | 'error';

        /**
         * Hint icon. Will be shown below the text input
         * @type string
         * @required false
         */
        hintIcon?: string;

        /**
         * hint text. Will be shown below the text input
         * @type string
         * @required false
         * @default undefined
         */
        hintText?: string;

        /**
         * Keyboard type of the text input. Can be text, password, email, number, search, tel, url. For mobile devices
         * @type "text" | "email" | "search" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined
         * @required false
         * @default text
         */
        keyboardType?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;

        /**
         * The label of the text input
         * @type string
         * @required false
         * @default undefined
         */
        label?: string;

        /**
         * Is the text input loading. Shows a loading spinner
         * @type boolean
         * @required false
         * @default false
         */
        loading?: boolean;

        /**
         * Mask of the text input. Can be any regex pattern. Example: ^[0-9]{1,10}$
         * @type number
         * @required false
         * @default -1
         */
        mask?: any;

        /**
         * Max character length of the text input
         * @type number
         * @required false
         * @default -1
         */
        maxLength?: number;

        /**
         * Min character length of the text input
         * @type number
         * @required false
         * @default -1
         */
        minLength?: number;

        /**
         * Name of the text input
         * @type string
         * @required false
         * @default undefined
         */
        name?: string;

        /**
         * Pattern of the text input for validation purposes. Can be any regex pattern. Example: ^[0-9]{1,10}$
         * @type string
         * @required false
         * @default undefined
         */
        pattern?: string;

        /**
         * Pill text input. Fully rounded edges
         * @type boolean
         * @required false
         */
        pill?: boolean;

        /**
         * Placeholder of the text input
         * @type string
         * @required false
         * @default undefined
         */
        placeholder?: string;

        /**
         * The size of the text input
         * Prepend icon to the text input. Icon will be on the left side
         * @type string
         * @required false
         * @default medium
         */
        prependIcon?: string;

        /**
         * Is readonly text input. Will not be able to type but will not be grayed out
         * @type boolean
         * @required false
         * @default false
         */
        readonly?: boolean;

        /**
         * Is text input required
         * @type boolean
         * @required false
         * @default false
         */
        required?: boolean;

        /**
         * Rounded text input. Soft rounded edges
         * @type boolean
         * @required false
         */
        rounded?: boolean;

        /**
         * The size of the input
         * @type 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
         * @required false
         * @default medium
         */
        size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

        /**
         * Type of the text input. Can be text, password, email, number, search, tel, url
         * @type 'text' | 'password' | 'email' | 'number' | 'search' | 'tel' | 'url'
         * @required false
         * @default text
         */
        type?: 'text' | 'password' | 'email' | 'number' | 'search' | 'tel' | 'url' | 'date';

        /**
         * Value of the text input
         * @type string
         * @required false
         * @default undefined
         */
        value?: string;

        fullWidth?: boolean;

        confirmed?: boolean;
        error?: string | null;
    }>(),
    {
        appendIcon: '',
        autoValidate: false,
        autocomplete: 'on',
        autofocus: false,
        clearable: false,
        confirmed: false,
        disabled: false,
        enterKeyAction: 'done',
        error: null,
        fullWidth: false,
        hintColor: 'default',
        hintIcon: undefined,
        hintText: undefined,
        keyboardType: 'text',
        label: undefined,
        loading: false,
        mask: '',
        maxLength: undefined,
        minLength: undefined,
        name: undefined,
        pattern: undefined,
        placeholder: undefined,
        prependIcon: '',
        readonly: false,
        required: false,
        size: 'medium',
        type: 'text',
        value: undefined
    }
);

// define is password visible state
const isPasswordVisible = ref(false);

// define is focused state
const isFocused = ref(false);

// define emits
const emit = defineEmits(['input', 'blur', 'focus', 'clear', 'keyupEnter', 'keydownEnter']);

// define elements
const inputElement = ref<HTMLInputElement>();

// define internal value for v-model
const internalValue = ref(props.value);

// watch for changes to props.value and update internalValue
watch(
    () => props.value,
    (value) => {
        internalValue.value = value;
    }
);

// handle input event
const handleInput = (event: Event) => {
    // internalValue.value = (event.target as HTMLInputElement).value;
    const target = event.target as HTMLInputElement;
    internalValue.value = target.value;
    emit('input', internalValue.value);
};

// handle focus event
const handleFocus = () => {
    isFocused.value = true;
    emit('focus');
};
const focus = () => {
    // inputElement.value?.scrollIntoView({ behavior: 'smooth' });
    if (inputElement.value) {
        scrollToElement(inputElement.value, 0, true).then(() => {
            inputElement.value?.focus();
        });
        // inputElement.value?.focus();
    }
};

// handle blur event
const handleBlur = () => {
    isFocused.value = false;
    emit('blur');
};
const blur = () => {
    inputElement.value?.blur();
};

// handle keyup enter event
const handleKeyupEnter = (event: KeyboardEvent) => {
    emit('keyupEnter', event);
};
const handleKeydownEnter = (event: KeyboardEvent) => {
    emit('keydownEnter', event);
};

// handle clear
const handleClear = () => {
    internalValue.value = '';
    emit('input', '');
    emit('clear');
};
const clear = () => {
    internalValue.value = '';
    emit('input', '');
    emit('clear');
};

// handle switch password visibility
const switchPasswordVisibility = () => {
    isPasswordVisible.value = !isPasswordVisible.value;
};

// compute type of input. Because of password visibility
const type = computed(() => {
    if (props.type === 'password' && isPasswordVisible.value) {
        return 'text';
    } else {
        return props.type;
    }
});

const getAppendIcon = computed(() => {
    if (props.appendIcon) {
        return props.appendIcon;
    } else if (props.confirmed) {
        return 'checkbox-circle-fill';
    } else if (props.error) {
        return 'error-warning-line';
    } else {
        return undefined;
    }
});

// compute hint icon name
// eslint-disable-next-line vue/return-in-computed-property
const hintIconName = computed((): undefined | string => {
    if (props.hintColor === 'default') {
        return undefined;
    } else if (props.hintColor === 'success') {
        return 'checkbox-circle-fill';
    } else if (props.hintColor === 'warning') {
        return 'information-fill';
    } else if (props.hintColor === 'error') {
        return 'close-circle-fill';
    }
});

// compute classes
const classes = computed(() => ({
    clearable: props.clearable,
    confirmed: props.confirmed,
    disabled: props.disabled,
    error: props.error !== null,
    focused: isFocused.value,
    'full-width': props.fullWidth,
    'has-append-icon': props.appendIcon,
    'has-hint': props.hintText,
    'has-hint-icon': props.hintIcon,
    'has-prepend-icon': props.prependIcon,
    'has-value': (props.value || '').length > 0,
    loading: props.loading,
    pill: props.pill,
    readonly: props.readonly,
    'v-text-input': true,
    [`size-${props.size}`]: true
}));

defineExpose({
    blur,
    clear,
    focus
});
</script>

<template>
    <div :class="classes">
        <template v-if="props.label">
            <label>{{ props.label }}</label>
        </template>
        <div class="input-content">
            <VIcon v-if="props.prependIcon" :name="props.prependIcon" />
            <input
                :placeholder="props.placeholder"
                :name="props.name"
                :value="props.value"
                :type="type"
                :inputmode="props.keyboardType"
                :enterkeyhint="props.enterKeyAction"
                :autofocus="props.autofocus"
                :readonly="props.readonly"
                :disabled="props.disabled"
                ref="inputElement"
                :required="props.required"
                v-maska
                :autocomplete="props.autocomplete"
                :minlength="props.minLength"
                :maxlength="props.maxLength"
                :pattern="props.pattern"
                :data-maska="props.mask"
                @input="handleInput"
                @blur="handleBlur"
                @focus="handleFocus"
                @keyup.enter="handleKeyupEnter"
                @keydown.enter="handleKeydownEnter"
            />
            <VIcon v-if="getAppendIcon" :name="getAppendIcon" class="append-icon" />
            <template v-if="props.type === 'password'">
                <!-- switch password visibility button icon -->
                <VButton :icon="isPasswordVisible ? 'eye-off-line' : 'eye-line'" pill size="small" variant="link" color="secondary" @click="switchPasswordVisibility" />
            </template>
            <!-- clear icon for clearable input text  -->
            <VButton v-if="props.clearable && (props.value || '').length > 0" icon="close-circle-fill" pill size="small" variant="link" color="secondary" @click="handleClear" />
            <VIcon v-if="props.loading" name="loader-4-fill load" />
        </div>
        <div v-if="props.hintText" class="hint" :class="props.hintColor">
            <VIcon v-if="hintIconName" :name="hintIconName" />
            <VIcon v-else :name="props.hintIcon" />
            <span>{{ props.hintText }}</span>
        </div>
        <div v-if="props.error" class="error">
            <VIcon name="information-fill" />
            <span v-html="props.error"></span>
        </div>
    </div>
</template>
