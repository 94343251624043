import moment from 'moment';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import useAuthStore from '@/stores/auth/auth-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useVehicleListStore from '@/stores/query/vehicle-list-store';
import type { ApiAxiosResponse } from '@/types/general';
import type * as IDiscountCode from '@/types/query/discount-code';
import dateFormat from '@/utils/date-format';
import useReservationCreateStore from '@/stores/reservation/create-store';

export interface PStore {
    isDialogOpened: boolean;
    checkMode: 'half' | 'full';
    code: string;
    email: string;
    isEmailFieldVisible: boolean;
    loading: boolean;
    loaded: boolean;
    isValid: boolean;
    payload: IDiscountCode.Data;
}

const useDiscountCodeStore = defineStore('discount-code-store', {
    actions: {
        clear() {
            localStorage.removeItem('discountStore');
            this.code = '';
            this.isValid = false;
            this.loaded = false;
            this.payload = {
                discountCode: null,
                invitation: null,
                mode: null,
                status: false,
                type: null
            };
            const vehicleListStore = useVehicleListStore();
            if (vehicleListStore.loaded) {
                vehicleListStore.fetchVehicles();
            }

            const reservationCreateStore = useReservationCreateStore();
            if (reservationCreateStore.created !== null) {
                reservationCreateStore.discountCheckoutRefresh();
            }
        },
        closeDialog() {
            this.isDialogOpened = false;
        },
        async getPlainPayload(): Promise<IDiscountCode.Plain | undefined> {
            if (!this.loading && !this.loaded) this.initialize();

            let max = 20;
            while (this.loading && max > 0) {
                max--;
                await new Promise((r) => setTimeout(r, 100));
            }

            if (this.isValid) {
                return {
                    code: this.code,
                    email: this.email
                };
            }

            return undefined;
        },
        initialize() {
            if (this.loading || this.loaded) return;
            // check if there is a stored discount code
            const localStorageData = localStorage.getItem('discountStore');

            if (localStorageData !== null) {
                const discountCodeStoreData = JSON.parse(localStorageData);

                if (typeof discountCodeStoreData.time === 'undefined') {
                    localStorage.removeItem('discountStore');
                } else {
                    const createMinuteDiff = moment(new Date()).diff(discountCodeStoreData.time, 'minutes');

                    if (createMinuteDiff < 5) {
                        this.setFromStorage({
                            code: discountCodeStoreData.code,
                            email: discountCodeStoreData.email,
                            payload: discountCodeStoreData.payload
                        });
                    } else if (createMinuteDiff < 15) {
                        this.setFromStorage({
                            code: discountCodeStoreData.code,
                            email: discountCodeStoreData.email
                        });
                    } else {
                        localStorage.removeItem('discountStore');
                    }
                }
            }
        },
        openDialog(fullMode: boolean = false): void {
            this.isEmailFieldVisible = !useAuthStore().isAuthed;
            // this.email = useAuthStore().getEmail || '';
            this.isDialogOpened = true;
            this.checkMode = fullMode ? 'full' : 'half';
        },
        setCode(value: string) {
            this.code = value;
        },
        setFromStorage(data: { code: string; email: string; payload?: IDiscountCode.Data }) {
            this.code = data.code;
            this.email = data.email;

            if (typeof data.payload === 'undefined') {
                return this.validate();
            }

            // this.loaded = true;
            this.payload = data.payload;
            this.isValid = true;
        },
        validate() {
            if (this.loading) return;

            const deliveryTimeStore = useDeliveryTimeStore();
            this.loading = true;

            if (this.code.trim() === '') {
                toast.error(i18n.global.t('discountCode.codeValidationError'));
                this.loading = false;
                return;
            }

            if (deliveryTimeStore.getDropDateTime === null || deliveryTimeStore.getPickupDateTime === null) {
                toast.error(i18n.global.t('discountCode.dateTimeValidationError'));
                this.loading = false;
                return;
            }

            if (this.isEmailFieldVisible) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.email)) {
                    toast.error(i18n.global.t('discountCode.emailValidationError'));
                    this.loading = false;
                    return;
                }
            }

            const checkPayload = {
                code: this.code,
                dropAt: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                email: this.email,
                mode: this.checkMode,
                pickupAt: dateFormat.toString(deliveryTimeStore.getPickupDateTime),
                pickupPointId: undefined as undefined | number,
                vehicleId: undefined as undefined | number
            };

            if (this.checkMode === 'full') {
                const reservationCreateStore = useReservationCreateStore();

                if (reservationCreateStore.created === null) {
                    localStorage.removeItem('discountStore');
                    toast.error(i18n.global.t('error.unknown'));
                    this.isValid = false;
                    return;
                }

                checkPayload.pickupPointId = reservationCreateStore.created.rental.pickup.pointId;
                checkPayload.vehicleId = reservationCreateStore.created.vehicle.id;
            }

            api.post('/query/discount-code-check', checkPayload)
                .then((response: ApiAxiosResponse<IDiscountCode.Data>) => {
                    if (response.data.data.status) {
                        this.isValid = true;
                        toast.success(i18n.global.t('discountCode.usedDiscountCode', { code: this.code }));
                        this.payload = response.data.data;
                        localStorage.setItem(
                            'discountStore',
                            JSON.stringify({
                                code: this.code,
                                email: this.email,
                                payload: this.payload,
                                time: new Date().getTime()
                            })
                        );
                        this.closeDialog();

                        const vehicleListStore = useVehicleListStore();
                        if (vehicleListStore.loaded) {
                            vehicleListStore.fetchVehicles();
                        }

                        if (this.checkMode === 'full') {
                            const reservationCreateStore = useReservationCreateStore();
                            if (reservationCreateStore.created !== null) {
                                reservationCreateStore.discountCheckoutRefresh();
                            }
                        }
                    }
                })
                .catch(() => {
                    localStorage.removeItem('discountStore');
                    // toast.error(i18n.global.t('discountCode.invalidDiscountCode'));
                    this.isValid = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },

    getters: {
        getDiscountCode(state) {
            return state.code;
        },
        getIsDialogOpened(state) {
            return state.isDialogOpened;
        },
        getIsValidationLoading(state) {
            return state.loading;
        }
    },

    state: () =>
        ({
            checkMode: 'half',
            code: '',
            email: '',
            isDialogOpened: false,
            isEmailFieldVisible: false,
            isValid: false,
            loaded: false,
            loading: false,
            payload: {
                discountCode: null,
                invitation: null,
                mode: null,
                status: false,
                type: null
            }
        }) as PStore
});

export default useDiscountCodeStore;
