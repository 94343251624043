<script lang="ts" setup>
import { computed } from 'vue';

import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useSearchBarStore from '@/stores/query/search-bar-store';

import VIcon from '../UI/VIcon.vue';

const deliveryPointStore = useDeliveryPointStore();
const searchBarStore = useSearchBarStore();

// define props without defaults
const props = defineProps<{
    tag: string;

    label?: string;
}>();

const disabled = computed(() => {
    if (props.tag == 'pickup') {
        return deliveryPointStore.getSelectedPickupDeliveryPoint == null;
    } else {
        return deliveryPointStore.getSelectedDropDeliveryPoint == null;
    }
});

const deliveryPoint = computed(() => {
    if (props.tag == 'pickup') {
        return deliveryPointStore.getSelectedPickupDeliveryPoint;
    } else {
        return deliveryPointStore.getSelectedDropDeliveryPoint;
    }
});

const deliveryPointIcon = computed(() => {
    if (deliveryPoint.value != null) {
        return deliveryPoint.value.icon;
    } else {
        return props.tag == 'pickup' ? 'map-pin-2-line' : 'map-pin-range-line';
    }
});

const classes = computed(() => ({
    'v-search-input-place': true,
    disabled: disabled.value,
    editing: searchBarStore.dialogGroup == 'DELIVERY_POINT' && searchBarStore.dialogTag == props.tag
}));
</script>

<template>
    <div :class="classes">
        <label>{{ label }}</label>
        <div class="input">
            <VIcon :size="20" :name="deliveryPointIcon" />
            <div class="value">{{ deliveryPoint?.name || $t('searchBar.pleaseSelect') }}</div>
        </div>
    </div>
</template>
