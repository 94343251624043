import { computed } from 'vue';

import settings from '@/definitions/settings.json';
import { useAppStore } from '@/stores/app-store';

const defaultLocale: string = settings.defaultLocale;

interface MenuItem {
    label: string;
    url: string;
    childs: MenuItem[];
}

interface ITranslation {
    label: string;
    url: string;
}

interface MenuItemJSON {
    routeGroup: string;
    translations: {
        [key: string]: ITranslation;
    };
    childs?: MenuItemJSON[];
}

interface AccountItem {
    template: string;
    orderRank: number;
    icon: string;
    label: string;
    slug: string;
}
interface AccountItemJSON {
    template: string | null;
    orderRank: number;
    icon: string | null;
    translations: {
        [key: string]: {
            label: string;
            slug: string;
        };
    };
}

interface StaticRouteItemJSON {
    key: string;
    routeName: string;
    routeRoot: string;
    translations: {
        [key: string]: ITranslation;
    };
}
interface StaticRouteItem {
    key: string;
    routeRoot: string;
    routeName: string;
    label: string;
    url: string;
}

const getMenuItems = (menu: MenuItemJSON[], locale: string): MenuItem[] => {
    return menu.map((item) => {
        const translation = item.translations[locale] || item.translations[defaultLocale];
        return {
            label: translation.label,
            url: translation.url,
            childs: item.childs ? getMenuItems(item.childs, locale) : []
        };
    });
};

const getAccountItems = (menu: AccountItemJSON[], locale: string): AccountItem[] => {
    return menu.map((item) => {
        const translation = item.translations[locale] || item.translations[defaultLocale];
        return {
            icon: item.icon || '',
            label: translation.label || '',
            orderRank: item.orderRank || 0,
            slug: translation.slug || '',
            template: item.template || ''
        };
    });
};

const useMenu = () => {
    const appStore = useAppStore();

    // const getHeaderMenuItems = computed(() => {
    //     return getMenuItems(settings.menus.header, appStore.locale.code);
    // });
    const getHeaderMenuHeadItems = computed(() => {
        return getMenuItems(settings.menus.header.head, appStore.locale.code);
    });
    const getHeaderMenuMainItems = computed(() => {
        return getMenuItems(settings.menus.header.main, appStore.locale.code);
    });

    const getFooterCorporateMenuItems = computed(() => {
        return getMenuItems(settings.menus.footer.corporate, appStore.locale.code);
    });

    const getFooterSeoMenuItems = computed(() => {
        return getMenuItems(settings.menus.footer.seo, appStore.locale.code);
    });

    const getFooterPoliciesMenuItems = computed(() => {
        return getMenuItems(settings.menus.footer.policies, appStore.locale.code);
    });

    const getAccountMenuItems = computed(() => {
        return getAccountItems(settings.menus.account as AccountItemJSON[], appStore.locale.code);
    });

    // for get homepage or contact etc. from breadcrumbs,faqs...
    const getStaticRoute = (key: string): StaticRouteItem | undefined => {
        const items: StaticRouteItemJSON[] = settings.staticRoutes;
        const item = items.find((item) => item.key === key);
        if (!item) return undefined;
        const translation = item.translations[appStore.locale.code] || item.translations[defaultLocale];
        return {
            key: item.key,
            label: translation.label,
            routeName: item.routeName,
            routeRoot: item.routeRoot,
            url: translation.url
        };
    };

    const getContactPageStaticRoute: StaticRouteItem | undefined = getStaticRoute('contact');

    return {
        getAccountMenuItems,
        getContactPageStaticRoute,
        getFooterCorporateMenuItems,
        getFooterPoliciesMenuItems,
        getFooterSeoMenuItems,
        getHeaderMenuHeadItems,
        getHeaderMenuMainItems,
        getStaticRoute
    };
};

export default useMenu;
