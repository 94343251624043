<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';

import { type Router, useRouter } from 'vue-router';

import VButton from '@/components/Forms/VButton.vue';
import { useCioRouter } from '@/composables/useCioRouter';

const cioRouter = useCioRouter();
const router: Router = useRouter();

const props = defineProps<{
    attributes?: { [key: string]: string | number | boolean };
    classList?: string;
    locale?: string;
    name?: string;
    params?: { [key: string]: string | number };
    tag?: 'a' | 'button' | 'link';
    targetType?: string;
    root: string;
    query?: { [key: string]: string | number };
    // href?: string | null;
    nodeGroup?: string;
    template?: string;
}>();

const hrefLink = ref('');

onBeforeMount(() => {
    if (hrefLink.value === '') {
        prepareHrefLink();
    }
});

const open = (e: MouseEvent) => {
    if (!e.metaKey && !e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();

        if (props.nodeGroup !== undefined && props.template !== undefined) {
            cioRouter.openStaticSlug(props.nodeGroup, props.template, props.query);
        }
        if (props.name !== undefined) {
            cioRouter.prepareOpen(props.root, props.name, props.params, props.locale, props.query);
        }
    }
};

const prepareHrefLink = () => {
    let href = null;
    if (props.nodeGroup !== undefined && props.template !== undefined) {
        href = cioRouter.prepareStaticSlug(props.nodeGroup, props.template, props.query);
    }
    if (props.name !== undefined) {
        href = cioRouter.prepareUrl(props.root, props.name, props.params, props.locale, false, props.query);
    }

    if (href !== null) {
        hrefLink.value = href;
    }
};

if (import.meta.env.SSR) {
    //   await prepareHrefLink();
    prepareHrefLink();
}

const classes = computed(() => ({
    active: hrefLink.value === router.currentRoute.value.fullPath,
    'v-link': true
}));
</script>

<template>
    <v-button v-if="tag === 'button'" :class="classes" @click="open($event)" v-bind="attributes"><slot /></v-button>
    <link v-else-if="tag === 'link'" :href="hrefLink" :hreflang="locale" rel="alternate" v-bind="attributes" />
    <a v-else :class="classes" @click="open($event)" :href="hrefLink" :target="targetType" v-bind="attributes"><slot /></a>
</template>
