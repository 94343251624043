import { api } from '@/plugins/axios';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { useAppStore } from '@/stores/app-store';
import { useGtm } from '@gtm-support/vue-gtm';
import type { DataLayerObject } from '@gtm-support/core';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import dateFormat from '@/utils/date-format';
import useVehicleListStore from '@/stores/query/vehicle-list-store';
import useReservationStoreStore from '@/stores/reservation/store-store';
import useReservationCreateStore from '@/stores/reservation/create-store';
import * as Sentry from '@sentry/browser';
import { Capacitor } from '@capacitor/core';
import useAuthStore from '@/stores/auth/auth-store';
import useCurrencyStore from '@/stores/app/currency-store';
import { useNodeStore } from '@/stores/node-store';
import { useRoute } from 'vue-router';

interface IParamsApollon {
    group: string;
    key: string;
    value: string;
}

interface IParamsAnalytics {
    [key: string]: string | number | null | IParamsAnalytics;
}

export function useCaptureThingsManager() {
    const appStore = useAppStore();
    const nodeStore = useNodeStore();
    const authStore = useAuthStore();
    const currencyStore = useCurrencyStore();
    const deliveryTimeStore = useDeliveryTimeStore();
    const deliveryPointStore = useDeliveryPointStore();
    const vehicleListStore = useVehicleListStore();
    const reservationCreateStore = useReservationCreateStore();
    const reservationStoreStore = useReservationStoreStore();
    const gtm = useGtm();
    const route = useRoute();

    const getFormData = () => {
        const pickupPoint = deliveryPointStore.selectedPickupDeliveryPointDetails;
        const dropPoint = deliveryPointStore.selectedDropDeliveryPointDetails || deliveryPointStore.selectedPickupDeliveryPointDetails;

        return {
            drop: {
                cityId: dropPoint?.cityId ?? null,
                cityName: dropPoint?.cityName ?? null,
                dateTime: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                pointId: dropPoint?.id ?? null,
                pointName: dropPoint?.name ?? null
            },
            pickup: {
                cityId: pickupPoint?.cityId ?? null,
                cityName: pickupPoint?.cityName ?? null,
                dateTime: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                pointId: pickupPoint?.id ?? null,
                pointName: pickupPoint?.name ?? null
            }
        };
    };

    const getVehicleData = () => {
        const vehicle = reservationCreateStore.created?.vehicle || null;

        return {
            brand: {
                id: vehicle?.brand.id || null,
                name: vehicle?.brand.name || null
            },
            fuelType: {
                id: vehicle?.fuelType.id || null,
                name: vehicle?.fuelType.name || null
            },
            group: {
                id: vehicle?.group.id || null,
                name: vehicle?.group.name || null
            },
            model: {
                id: vehicle?.id || null,
                name: vehicle?.name || null
            },
            transmissionType: {
                id: vehicle?.transmissionType.id || null,
                name: vehicle?.transmissionType.name || null
            }
        };
    };

    const captureReservation = async () => {
        const customer = reservationStoreStore.storing.customer;
        const rental = reservationStoreStore.stored;

        const params = {
            customer: {
                email: customer.email || null,
                fullName: customer.firstName + ' ' + customer.lastName,
                gender: customer.gender || null,
                id: rental.customerId || null,
                phoneNumber: customer.phoneNumber.number || null
            },
            form: getFormData(),
            queryId: vehicleListStore.queryId,
            rental: {
                currencyCode: rental.currencyCode || null,
                customerId: rental.customerId || null,
                customerIsNew: rental.customerIsNew || null,
                id: rental.id || null,
                pnr: rental.pnr || null,
                totalAmount: rental.totalAmount || null
            },
            vehicle: getVehicleData()
        };

        await Promise.all([sendToFirebaseAnalytics('conversation_reservation', params), sendToGoogleDatalayer('conversation_reservation', 'params', params)]);
    };

    const captureReservationOffer = async () => {};

    const captureVehicleSearch = async () => {
        const params = {
            form: getFormData(),
            queryId: vehicleListStore.queryId
        };

        await Promise.all([sendToFirebaseAnalytics('vehicle_search_results', params), sendToGoogleDatalayer('vehicle_search_results', 'params', params)]);
    };

    const captureVehicleSelect = async () => {
        const params = {
            form: getFormData(),
            queryId: vehicleListStore.queryId,
            vehicle: getVehicleData()
        };

        await Promise.all([sendToFirebaseAnalytics('vehicle_select', params), sendToGoogleDatalayer('vehicle_select', 'params', params)]);
    };

    const captureVehicleView = async () => {
        const pickupPoint = deliveryPointStore.selectedPickupDeliveryPointDetails;
        const dropPoint = deliveryPointStore.selectedDropDeliveryPointDetails || deliveryPointStore.selectedPickupDeliveryPointDetails;
        // @todo need vehicle data via store
        // const vehicle = undefined;

        const params = {
            form: {
                drop: {
                    cityId: dropPoint?.cityId ?? null,
                    cityName: dropPoint?.cityName ?? null,
                    dateTime: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                    pointId: dropPoint?.id ?? null,
                    pointName: dropPoint?.name ?? null
                },
                pickup: {
                    cityId: pickupPoint?.cityId ?? null,
                    cityName: pickupPoint?.cityName ?? null,
                    dateTime: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                    pointId: pickupPoint?.id ?? null,
                    pointName: pickupPoint?.name ?? null
                }
            },
            queryId: vehicleListStore.queryId
            // vehicle: {
            //     brand: {
            //         id: vehicle?.brand.id || null,
            //         name: vehicle?.brand.name || null
            //     },
            //     fuelType: {
            //         id: vehicle?.fuelType.id || null,
            //         name: vehicle?.fuelType.name || null
            //     },
            //     group: {
            //         id: vehicle?.group.id || null,
            //         name: vehicle?.group.name || null
            //     },
            //     model: {
            //         id: vehicle?.id || null,
            //         name: vehicle?.name || null
            //     },
            //     transmissionType: {
            //         id: vehicle?.transmissionType.id || null,
            //         name: vehicle?.transmissionType.name || null
            //     }
            // }
        };

        await Promise.all([sendToFirebaseAnalytics('vehicle_view', params), sendToGoogleDatalayer('vehicle_view', 'params', params)]);
    };

    const setVisitor = async () => {
        const visitorId = localStorage.getItem('visitor');

        if (visitorId === null || visitorId === '') return;

        const params = {
            countryCode: appStore.countryCode,
            currencyCode: currencyStore.currency.code,
            id: visitorId,
            localeCode: appStore.locale.code,
            sessionId: localStorage.getItem('session')
        };

        if (import.meta.env.PROD && !import.meta.env.SSR) {
            Sentry.setUser(params);
        }

        if (Capacitor.isNativePlatform()) {
            await FirebaseAnalytics.setUserId({
                userId: params.id
            });
            await FirebaseAnalytics.setUserProperty({
                key: 'sessionId',
                value: params.sessionId
            });
            await FirebaseAnalytics.setUserProperty({
                key: 'countryCode',
                value: params.countryCode
            });
            await FirebaseAnalytics.setUserProperty({
                key: 'localeCode',
                value: params.localeCode
            });
            await FirebaseAnalytics.setUserProperty({
                key: 'currencyCode',
                value: params.currencyCode
            });
        } else {
            await sendToGoogleDatalayer('visitor', 'params', params);
        }
    };

    const setVisitorCurrencyCode = async () => {
        const params = {
            currencyCode: currencyStore.currency.code
        };

        if (import.meta.env.PROD && !import.meta.env.SSR) {
            Sentry.setUser(params);
        }

        if (Capacitor.isNativePlatform()) {
            await FirebaseAnalytics.setUserProperty({
                key: 'currencyCode',
                value: params.currencyCode
            });
        } else {
            await sendToGoogleDatalayer('visitor_currency_code', 'params', params);
        }

        await sendToApollon({
            group: 'change',
            key: 'currency',
            value: params.currencyCode
        });
    };

    const setVisitorLocaleCode = async () => {
        const params = {
            localeCode: appStore.locale.code
        };

        if (import.meta.env.PROD && !import.meta.env.SSR) {
            Sentry.setUser(params);
        }

        if (Capacitor.isNativePlatform()) {
            await FirebaseAnalytics.setUserProperty({
                key: 'localeCode',
                value: params.localeCode
            });
        } else {
            await sendToGoogleDatalayer('visitor_locale_code', 'params', params);
        }

        await sendToApollon({
            group: 'change',
            key: 'locale',
            value: params.localeCode
        });
    };

    const setVisitorSession = async () => {
        const sessionId = localStorage.getItem('session');

        if (sessionId === null || sessionId === '') return;

        const params = {
            sessionId: sessionId
        };

        if (import.meta.env.PROD && !import.meta.env.SSR) {
            Sentry.setUser(params);
        }

        if (Capacitor.isNativePlatform()) {
            await FirebaseAnalytics.setUserProperty({
                key: 'sessionId',
                value: params.sessionId
            });
        } else {
            await sendToGoogleDatalayer('visitor_session', 'params', params);
        }
    };

    const setCustomer = async () => {
        if (authStore.customer === null) return;

        const params = {
            customerId: authStore.customer.id.toString(),
            customerName: authStore.getFullName
        };

        if (import.meta.env.PROD && !import.meta.env.SSR) {
            Sentry.setUser(params);
        }

        if (Capacitor.isNativePlatform()) {
            await FirebaseAnalytics.setUserProperty({
                key: 'customerId',
                value: params.customerId
            });
            await FirebaseAnalytics.setUserProperty({
                key: 'customerName',
                value: params.customerName
            });
        } else {
            await sendToGoogleDatalayer('customer', 'params', {
                id: params.customerId,
                name: params.customerName
            });
        }
    };

    const capturePhoneNumberClick = async (type: 'call' | 'whatsapp', position: string, number: string) => {
        await Promise.all([
            sendToApollon({
                group: `click_${type}_number`,
                key: position,
                value: number
            })
        ]);
    };

    const captureSocialLinkClick = async (name: string, position: string) => {
        await Promise.all([
            sendToApollon({
                group: 'click_social_link',
                key: position,
                value: name
            })
        ]);
    };

    const sendToApollon = async (params: IParamsApollon) => {
        await api.post('/capture/action', params);
    };

    const sendToFirebaseAnalytics = async (name: string, params: IParamsAnalytics) => {
        if (!appStore.capacitor.isNative) return;

        await FirebaseAnalytics.logEvent({
            name: name,
            params: params
        });
    };

    const sendToGoogleDatalayer = async (name: string, key: string, params: IParamsAnalytics, uniqueEventId: number | undefined = undefined) => {
        if (appStore.capacitor.isNative || typeof gtm === 'undefined') return;

        const data: DataLayerObject = {
            event: name
        };

        data[key] = params;

        if (typeof uniqueEventId !== 'undefined') {
            data.gtm = {
                uniqueEventId: uniqueEventId
            };
        }

        gtm.push(data);
    };

    const captureNodePageView = async () => {
        const pageName = nodeStore.head.title || nodeStore.name;

        if (appStore.capacitor.isNative) {
            await sendToFirebaseAnalytics('page_view', {
                name: pageName,
                path: route.fullPath
            });
        } else {
            await sendToGoogleDatalayer('virtualPageView', 'params', {
                name: pageName,
                path: route.fullPath
            });
        }
    };

    return {
        captureNodePageView,
        capturePhoneNumberClick,
        captureReservation,
        captureReservationOffer,
        captureSocialLinkClick,
        captureVehicleSearch,
        captureVehicleSelect,
        captureVehicleView,
        sendToApollon,
        sendToFirebaseAnalytics,
        sendToGoogleDatalayer,
        setCustomer,
        setVisitor,
        setVisitorCurrencyCode,
        setVisitorLocaleCode,
        setVisitorSession
    };
}
