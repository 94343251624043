<script lang="ts" setup>
import { computed } from 'vue';

import useSearchBarStore from '@/stores/query/search-bar-store';

const searchBarStore = useSearchBarStore();

const props = withDefaults(
    defineProps<{
        tag: string;
        /**
         * The label of the input.
         *
         * @default 'Start Date'
         */
        label?: string;

        /**
         * The date.
         *
         * @default ''
         */
        date: string;

        /**
         * The time.
         *
         * @default ''
         */
        clock: string;
    }>(),
    {
        label: 'Start Date',
        date: '',
        clock: '12:00'
    }
);

const emit = defineEmits(['onDateClick', 'onClockClick', 'onTimeChange']);

const handleDateClicked = (e: Event) => {
    e.stopPropagation();
    emit('onDateClick');
    // if(props.tag == 'pickup'){
    //     searchBarStore.openDeliveryDatePickupDialog()
    // }
    // else{
    //     searchBarStore.openDeliveryDateDropDialog()
    // }
};

const handleClockClicked = (e: Event) => {
    e.stopPropagation();
    emit('onClockClick');
    // if(props.tag == 'pickup'){
    //     searchBarStore.openDeliveryClockPickupDialog()
    // }
    // else{
    //     searchBarStore.openDeliveryClockDropDialog()
    // }
};

const classes = computed(() => ({
    'v-search-date-slot': true,
    active: (searchBarStore.dialogGroup == 'DELIVERY_DATE' && searchBarStore.dialogTag == props.tag) || (searchBarStore.dialogGroup == 'DELIVERY_CLOCK' && searchBarStore.dialogTag == props.tag)
}));

const clockClasses = computed(() => ({
    'time-input': true,
    active: searchBarStore.dialogGroup == 'DELIVERY_CLOCK' && searchBarStore.dialogTag == props.tag
}));
</script>

<template>
    <div :class="classes" @click="handleDateClicked">
        <label>{{ props.label }} </label>
        <div class="input">
            <div class="date-input">{{ props.date }}</div>
            <div :class="clockClasses" @click="handleClockClicked">{{ props.clock || '00:00' }}</div>
        </div>
    </div>
</template>
