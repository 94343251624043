import moment from 'moment';
import { defineStore } from 'pinia';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import df from '@/utils/date-format';

const MINUTE_ROUND = 30;

export interface DeliveryTimeStoreState {
    // initialized: boolean;

    pickupDateTime: Date | null;
    dropDateTime: Date | null;

    requestedPickupDateTime: Date | null;
    requestedDropDateTime: Date | null;
}

const useDeliveryTimeStore = defineStore('delivery-time-store', {
    actions: {
        setDropDateTime(date: Date): void {
            const m = moment(date);
            this.dropDateTime = m.minutes(Math.round(m.minutes() / MINUTE_ROUND) * MINUTE_ROUND).toDate();
            // if (this.initialized) useVehicleListStore().loadVehicles();
        },
        setPickupDateTime(date: Date): void {
            const m = moment(date);
            this.pickupDateTime = m.minutes(Math.round(m.minutes() / MINUTE_ROUND) * MINUTE_ROUND).toDate();
            // if (this.initialized) useVehicleListStore().loadVehicles();
        },
        // usualy called from setQueryFormParams
        setQueryParams(pickupAt: string | null, dropAt: string | null) {
            if (pickupAt !== null) {
                this.pickupDateTime = df.toDate(pickupAt);
            }
            if (dropAt !== null) {
                this.dropDateTime = df.toDate(dropAt);
            }
        }
    },
    getters: {
        // DROP
        getDropClockText: (state: DeliveryTimeStoreState) => {
            return moment(state.dropDateTime).format('HH:mm');
        },
        getDropDateText: (state: DeliveryTimeStoreState) => {
            return moment(state.dropDateTime).format('DD.MM.YYYY');
        },
        getDropDateTime: (state: DeliveryTimeStoreState) => {
            return state.dropDateTime;
        },
        getDropDateTimeFullText: (state: DeliveryTimeStoreState) => {
            return moment(state.dropDateTime).format('DD.MM.YYYY HH:mm');
        },
        getIsInitialized: (state: DeliveryTimeStoreState) => {
            return state.pickupDateTime !== null && state.dropDateTime !== null;
        },
        getMinDropDate: (state: DeliveryTimeStoreState): Date => {
            const deliveryTimeStore = useDeliveryPointStore();
            let minRentalHour = 1;
            const deliveryPoint = deliveryTimeStore.getSelectedDropDeliveryPointDetails || deliveryTimeStore.getSelectedPickupDeliveryPointDetails;
            if (deliveryPoint !== null) {
                const rules = deliveryPoint.rules;
                if (rules !== null) {
                    minRentalHour = rules.minRentalHour;
                }
            }
            return moment(state.pickupDateTime).add(minRentalHour, 'hour').toDate();
        },
        getMinPickupDate: (): Date => {
            const deliveryTimeStore = useDeliveryPointStore();
            if (deliveryTimeStore.getSelectedPickupDeliveryPointDetails !== null) {
                const deliveryPoint = deliveryTimeStore.getSelectedPickupDeliveryPointDetails;
                const rules = deliveryPoint.rules;
                if (rules !== null) {
                    return moment().add(rules.earliestReservationHour, 'hour').toDate();
                }
            }
            return moment().toDate();
        },
        // PICKUP
        getPickupClockText: (state: DeliveryTimeStoreState) => {
            return moment(state.pickupDateTime).format('HH:mm');
        },
        getPickupDateText: (state: DeliveryTimeStoreState) => {
            return moment(state.pickupDateTime).format('DD.MM.YYYY');
        },
        getPickupDateTime: (state: DeliveryTimeStoreState) => {
            return state.pickupDateTime;
        },
        getPickupDateTimeFullText: (state: DeliveryTimeStoreState) => {
            return moment(state.pickupDateTime).format('DD.MM.YYYY HH:mm');
        }
    },
    state: (): DeliveryTimeStoreState => ({
        // initialized: false,
        dropDateTime: null,
        pickupDateTime: null,
        requestedDropDateTime: null,
        requestedPickupDateTime: null
    })
});

export default useDeliveryTimeStore;
