import { computed } from 'vue';
import { type RouteLocationNamedRaw, type Router, type RouteRecordName, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import { staticRouteTranslations } from '@/definitions';
import i18n from '@/locales/locale';
import { useAppStore } from '@/stores/app-store';

export interface ICioRoute {
    name: string;
    localePath: string | null;
    params: {
        [key: string]: string | number;
    };
    root: string;
    query?: {
        [key: string]: string | number;
    };
}

export function useCioRouter(initialTo?: null | string | ICioRoute, router?: Router, newTab?: boolean) {
    if (typeof router === 'undefined') {
        router = useRouter();
    }

    const appStore = useAppStore();
    const locale = computed(() => appStore.locale);
    const mode = computed((): 'push' | 'replace' => 'push');

    const prepareRouteName = (root: string, name: string = '', localePath: string | null = null) => {
        return root + '.' + (localePath || locale.value.path) + (name === '' ? '' : '.' + name);
    };

    const prepareLocation = (
        root: string,
        name: string = '',
        params: {
            [key: string]: string | number;
        } = {},
        localePath: string | null = null,
        query: {
            [key: string]: string | number;
        } = {}
    ): null | RouteLocationNamedRaw => {
        if (typeof router === 'undefined') {
            return null;
        }

        const routeName = prepareRouteName(root, name, localePath);

        if (!router.hasRoute(routeName)) {
            return null;
        }

        return {
            name: routeName,
            params: params,
            query: query
        };
    };

    const getHref = (to: RouteLocationNamedRaw, absolute: boolean = false): null | string => {
        if (typeof router === 'undefined') {
            return null;
        }

        if (!router.hasRoute(<NonNullable<RouteRecordName>>to.name)) {
            return null;
        }

        const routeResolve = router.resolve(to);

        return (absolute ? appStore.baseUrl : '') + routeResolve.href;
    };

    const prepareUrl = (
        root: string,
        name: string = '',
        params: {
            [key: string]: string | number;
        } = {},
        localePath: string | null = null,
        absolute: boolean = false,
        query: {
            [key: string]: string | number;
        } = {}
    ): null | string => {
        const to = prepareLocation(root, name, params, localePath, query);

        if (to === null) {
            return null;
        }

        return getHref(to, absolute);
    };

    const prepareOpen = (
        root: string,
        name: string = '',
        params: {
            [key: string]: string | number;
        } = {},
        localePath: string | null = null,
        query: {
            [key: string]: string | number;
        } = {}
    ): void => {
        const to = prepareLocation(root, name, params, localePath, query);

        if (to === null) {
            return;
        }

        open(to);
    };

    const open = (to: RouteLocationNamedRaw) => {
        if (typeof router === 'undefined') {
            return;
        }

        if (typeof newTab !== 'undefined' && newTab) {
            const path = getHref(to, true);

            if (path !== null) {
                window.open(path);
            }

            return;
        }

        router[mode.value](to);
    };

    const openPath = (path: string) => {
        if (typeof router === 'undefined') {
            return;
        }

        if (typeof newTab !== 'undefined' && newTab) {
            window.open(path);
            return;
        }

        let query = {};
        const parts = path.split('?');

        if (parts.length === 2) {
            query = JSON.parse('{"' + decodeURI(parts[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        }

        router[mode.value]({
            path: parts[0],
            query: query
        });
    };

    const prepareStaticSlug = (nodeGroup: string, template: string, query: {} = {}) => {
        // console.log(staticRouteTranslations)
        // find static slug for the given nodeGroup and template
        if (staticRouteTranslations[locale.value.path] === undefined) {
            return '';
        }
        if (staticRouteTranslations[locale.value.path][nodeGroup] === undefined) {
            return '';
        }
        if (staticRouteTranslations[locale.value.path][nodeGroup][template] === undefined) {
            return '';
        }
        const staticSlug = staticRouteTranslations[locale.value.path][nodeGroup][template];

        const slug = (locale.value.isDefault ? '' : '/' + locale.value.path) + '/' + staticSlug;
        return slug + (Object.keys(query).length > 0 ? '?' + new URLSearchParams(query).toString() : '');
    };

    const openStaticSlug = (nodeGroup: string, template: string, query: {} = {}) => {
        const slug = prepareStaticSlug(nodeGroup, template, query);
        if (slug !== '') {
            openPath(slug);
        }
    };

    /**
     * Redirect to home page with alert message if provided
     * @param alertMessage string | boolean | undefined - alert message to show (if boolean, show default message)
     * @returns
     */
    const redirectToHome = (alertMessage?: string | boolean | undefined) => {
        const path = prepareUrl('app', 'home');
        if (path === null) {
            console.error('Path is null');
            return;
        }

        openPath(path);

        setTimeout(() => {
            if (typeof alertMessage === 'string') {
                toast.error(alertMessage);
            } else if (typeof alertMessage === 'boolean' && alertMessage) {
                toast.error(i18n.global.t('410.message'));
            }
        }, 300);
    };

    if (typeof initialTo !== 'undefined' && initialTo !== null) {
        if (typeof initialTo === 'object') {
            prepareOpen(
                initialTo.root,
                initialTo.name,
                typeof initialTo.params === 'undefined' ? {} : initialTo.params,
                typeof initialTo.localePath === 'undefined' ? null : initialTo.localePath,
                typeof initialTo.query === 'undefined' ? {} : initialTo.query
            );
        } else if (initialTo !== '') {
            openPath(initialTo);
        }
    }

    return {
        getHref,
        open,
        openPath,
        openStaticSlug,
        prepareLocation,
        prepareOpen,
        prepareRouteName,
        prepareStaticSlug,
        prepareUrl,
        redirectToHome
    };
}
