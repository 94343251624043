<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import VTextInput from '@/components/Forms/VTextInput.vue';
import VSearchDialog from '@/components/Search/Dialog.vue';
import VIcon from '@/components/UI/VIcon.vue';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useSearchBarStore from '@/stores/query/search-bar-store';
import type { DeliveryPoint } from '@/types/query/delivery-point';
import emitter from '@/utils/emitter';
import PageLoader from '../PageLoader/PageLoader.vue';

const props = defineProps<{
    tag: string;
    label: string;
}>();

const deliveryPointStore = useDeliveryPointStore();
const searchBarStore = useSearchBarStore();

const searchValue = ref('');
const searchComponent = ref<InstanceType<typeof VTextInput> | null>(null);

const handleSearch = (value: string) => {
    searchValue.value = value;
    if (props.tag === 'pickup') {
        deliveryPointStore.setPickupDeliveryPointGroupsFilter(value);
    } else {
        deliveryPointStore.setDropDeliveryPointGroupsFilter(value);
    }
};

const handleSelect = (deliveryPoint: DeliveryPoint) => {
    searchValue.value = '';
    if (props.tag === 'pickup') {
        deliveryPointStore.setPickupDeliveryPointGroupsFilter('');
        deliveryPointStore.selectPickupDeliveryPoint(deliveryPoint);

        if (deliveryPointStore.useDifferentDropLocation) {
            //deliveryPointStore.loadDropDeliveryPoints(deliveryPoint.id);
            searchBarStore.openDeliveryPointDropDialog();
        } else {
            searchBarStore.openDeliveryDatePickupDialog();
        }
    } else {
        deliveryPointStore.setDropDeliveryPointGroupsFilter('');
        deliveryPointStore.selectDropDeliveryPoint(deliveryPoint);
        searchBarStore.openDeliveryDatePickupDialog();
    }

    emitter.emit('delivery-point:changed', (props.tag, deliveryPoint));
};

onMounted(() => {
    if (props.tag === 'pickup') {
        deliveryPointStore.setPickupDeliveryPointGroupsFilter('');
    } else {
        deliveryPointStore.setDropDeliveryPointGroupsFilter('');
    }
});

watch(
    () => searchComponent.value,
    (value) => {
        if (value) {
            value?.focus();
        }
    }
);

const deliveryPointGroups = computed(() => {
    return props.tag === 'pickup' ? deliveryPointStore.getFilteredPickupDeliveryPointGroups : deliveryPointStore.getFilteredDropDeliveryPointGroups;
});
</script>

<template>
    <VSearchDialog class="v-search-dialog-place">
        <template #title>
            {{ props.label }}
        </template>
        <template #interaction>
            <VTextInput ref="searchComponent" :placeholder="$t('searchBar.searchPlace')" :value="searchValue" @input="handleSearch($event)" clearable full-width />
        </template>
        <template #body>
            <template v-if="deliveryPointStore.pickupDeliveryPointsState.loading || deliveryPointStore.dropDeliveryPointsState.loading">
                <div class="loading">
                    <PageLoader embedded />
                </div>
            </template>
            <template v-else>
                <div class="groups" v-if="deliveryPointGroups.length > 0">
                    <div class="group" v-for="pointGroup in deliveryPointGroups" :key="pointGroup.name">
                        <div class="group-label">{{ pointGroup.name }}</div>
                        <div class="places">
                            <div class="place" v-for="deliveryPoint in pointGroup.deliveryPoints" :key="deliveryPoint.id" @click="handleSelect(deliveryPoint)">
                                <VIcon :name="deliveryPoint.icon || 'map-pin'" :size="24" />
                                {{ deliveryPoint.name }}
                                <small v-if="deliveryPoint.code"> ({{ deliveryPoint.code }}) </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-result" v-if="deliveryPointGroups.length === 0"><VIcon name="links-fill" :size="24" /> {{ $t('searchBar.noResult') }}</div>
            </template>
        </template>
    </VSearchDialog>
</template>
