import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import useAuthModalStore from '@/stores/auth/modal-store';
import type * as IRequest from '@/types/auth/password-reset/request';
import type * as IResponse from '@/types/auth/password-reset/response';
import type { ApiAxiosResponse } from '@/types/general';

interface PStore {
    isLoading: boolean;
    payload: IRequest.Payload;
}
const usePasswordResetStore = defineStore('password-reset-store', {
    actions: {
        passwordReset(): Promise<void> {
            return new Promise((resolve, reject) => {
                this.isLoading = true;
                api.post('/auth/reset-password', this.payload)
                    .then((response: ApiAxiosResponse<IResponse.Payload>) => {
                        if (response.data.status_code === 200) {
                            useAuthModalStore().closePasswordResetModal();

                            toast.success(i18n.global.t('auth.passwordReset.success'));

                            resolve();
                        }
                    })
                    .catch((error: any) => {
                        // this lines deprecated because error messages moved to axios plugin
                        // let message = error.response.data.message;
                        // if (error.response.status === 401) {
                        //     message = i18n.global.t('auth.passwordReset.error');
                        // }
                        // toast(message, {
                        //     autoClose: 1000,
                        //     type: 'error'
                        // });
                        reject(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });
        },
        setPayloadEmail(email: string) {
            this.payload.email = email;
        }
    },

    getters: {
        getPayloadEmail(state) {
            return state.payload.email;
        }
    },

    state: () =>
        ({
            payload: {
                email: ''
            }
        }) as PStore
});

export default usePasswordResetStore;
