<script setup lang="ts">
import { computed, ref, type Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { type IRouterLocalizedLocation, useAppStore } from '@/stores/app-store';
const route = useRoute();
const appStore = useAppStore();
const alternateLocaleLinks: Ref<IRouterLocalizedLocation[]> = ref(appStore.routerLocalizedLocations);

if (import.meta.env.SSR) {
    const check = async () => {
        let c = 0;

        while (appStore.routerLocalizedLocations.length === 0 && c < 250) {
            await new Promise((resolve) => setTimeout(resolve, 10));
            c++;
        }

        return appStore.routerLocalizedLocations;
    };

    alternateLocaleLinks.value = await check();
}

const getActiveLocaleCode = computed(() => appStore?.locale?.code || 'tr');

const getAlternateLocaleLinks = computed(() => {
    let links: IRouterLocalizedLocation[] = [];
    alternateLocaleLinks.value.forEach((link) => {
        links.push({
            ...link,
            route: {
                ...link.route,
                query: route.query
            }
        });
    });
    return links;
});

const classes = computed(() => ({
    'v-locale-switcher-horizontal': true
}));

watch(
    () => appStore.routerLocalizedLocations,
    (value) => {
        alternateLocaleLinks.value = value;
    }
);
</script>

<template>
    <div :class="classes">
        <template v-for="link in getAlternateLocaleLinks" :key="link.code">
            <router-link :to="link.route" :class="{ locale: true, active: getActiveLocaleCode === link.code }">
                <img :src="`${appStore.cdnUrl}/images/flags/${link.code}.svg`" loading="lazy" />
                <div class="locale-label">{{ link.code }}</div>
            </router-link>
        </template>
    </div>
</template>
