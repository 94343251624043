<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';

import { useRouter } from 'vue-router';

import VButton from '@/components/Forms/VButton.vue';

const props = withDefaults(
    defineProps<{
        active?: boolean;
        locked?: boolean;
    }>(),
    {
        active: false,
        locked: false
    }
);

const emit = defineEmits(['close']);

onMounted(() => {
    if (!document.body.classList.contains('v-modal-open') && props.active) {
        document.body.classList.add('v-modal-open');
    }
});

onBeforeUnmount(() => {
    let modalCount = document.querySelectorAll('.v-modal').length;
    if (modalCount <= 1) {
        document.body.classList.remove('v-modal-open');
    }
});

watch(
    () => props.active,
    (value) => {
        if (value) {
            let modalCount = document.querySelectorAll('.v-modal').length;
            if (modalCount <= 1) {
                document.body.classList.add('v-modal-open');
            }
        } else {
            let modalCount = document.querySelectorAll('.v-modal').length;
            if (modalCount <= 1) {
                document.body.classList.remove('v-modal-open');
            }
        }
    }
);

const router = useRouter();

watch(
    () => router.currentRoute.value,
    (value) => {
        if (!props.locked) {
            handleClose();
        }
    }
);

const handleClose = () => {
    emit('close');
    // setTimeout(() => {
    //     let modalCount = document.querySelectorAll('.v-modal').length;
    //     if (modalCount <= 1) {
    //         document.body.classList.remove('v-modal-open');
    //     }
    // }, 300);
};

const classes = computed(() => ({
    'v-modal': true
}));
</script>

<template>
    <Transition name="slide-left-to-right">
        <div :class="classes" v-if="props.active">
            <div class="modal-overlay" @click="!props.locked && handleClose()"></div>
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header"></slot>
                    <VButton class="modal-close-button" color="secondary" variant="tonal" size="x-small" icon="close-line" pill @click="handleClose" />
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>
