import './assets/styles/main.scss';
import { markRaw } from 'vue';
import { createPinia } from 'pinia';
import { setupCalendar } from 'v-calendar';
import { ViteSSG, type ViteSSGContext } from 'vite-ssg';
import type { Router, RouteRecordRaw, RouterScrollBehavior } from 'vue-router';
import { useCioRouter } from '@/composables/useCioRouter';
import { usePopstate } from '@/composables/usePopstate';
import settings from '@/definitions/settings.json';
import i18n from '@/locales/locale';
import axios from '@/plugins/axios';
import { routes } from '@/router/routes';
import { useAppStore } from '@/stores/app-store';
import { createGtm } from '@gtm-support/vue-gtm';
// import * as Sentry from '@sentry/capacitor';
// import * as SentryVue from '@sentry/vue';
import { SchemaOrgUnheadPlugin } from '@vueuse/schema-org';
import App from './App.vue';
import { Capacitor } from '@capacitor/core';
import type { ComposerTranslation } from 'vue-i18n';

// const app = createApp(App);
//
// app.use(createPinia());
// app.use(router);
//
// app.mount('#app');

declare module 'pinia' {
    export interface PiniaCustomProperties {
        $router: Router;
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: ComposerTranslation;
    }
}

if (!import.meta.env.SSR && !Capacitor.isNativePlatform()) {
    window.addEventListener('popstate', (event) => {
        event.preventDefault();
        event.stopPropagation();
        usePopstate.has = true;
    });
}

if (!import.meta.env.SSR) {
    document.documentElement.style.setProperty('--cdn-url', import.meta.env.VITE_CDN_URL);
}

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
    const scrollableContainer = document.getElementById('app') as HTMLElement;

    if (to.hash) {
        return {
            behavior: 'smooth',
            // el: decodeURIComponent(to.hash),
            el: scrollableContainer,
            top: 120
        };
        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         scrollableContainer.scrollTo(0, 100);
        //         resolve(savedPosition);
        //     }, 300);
        // });
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                scrollableContainer.scrollTo(0, 0);
                resolve(false);
            }, 300);
        });
    }
};

export const createApp: (client?: boolean, routePath?: string) => Promise<ViteSSGContext<true>> = ViteSSG(
    // the root component
    App,
    // vue-router options
    {
        // history: createWebHistory(import.meta.env.BASE_URL),
        routes,
        scrollBehavior
    },
    // function to have custom setups
    async ({ app, router, initialState, head }) => {
        app.use(axios);
        // app.use(screen);

        const pinia = createPinia();

        pinia.use(({ store }) => {
            store.$router = markRaw(router);
        });

        app.use(pinia);

        if (import.meta.env.SSR) {
            initialState.pinia = pinia.state.value;
        } else {
            pinia.state.value = initialState.pinia || {};
        }

        app.use(i18n);
        app.use(setupCalendar, {});

        router.beforeEach(() => {
            const appStore = useAppStore(pinia);

            if (!import.meta.env.SSR && usePopstate.has) {
                usePopstate.has = false;

                if (appStore.getFirstBackRouteBlocker === null) {
                    appStore.setBackRequestCall(true);
                } else {
                    appStore.getFirstBackRouteBlocker.fn();
                    return false;
                }
            } else {
                appStore.setBackRoutePath(null);
                appStore.setHideFooter(Capacitor.isNativePlatform());
                appStore.setHideMobileAppWidget(Capacitor.isNativePlatform());
                appStore.setRouterLocaleParams({});
            }
        });

        router.afterEach(() => {
            const appStore = useAppStore(pinia);
            appStore.setBackRequestCall(false);
        });

        if (import.meta.env.VITE_BUILD_TARGET === 'web' && !import.meta.env.SSR && typeof settings?.head?.googleTagManagerKey !== 'undefined') {
            //  && typeof settings?.googleApiKey !== 'undefined'
            app.use(
                createGtm({
                    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
                    debug: import.meta.env.DEV, // Whether or not display console logs debugs (optional)
                    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                    enabled: import.meta.env.PROD, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                    id: 'GTM-' + settings.head.googleTagManagerKey, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
                    ignoredViews: [], // Don't trigger events for specified router names (optional)
                    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                    nonce: '2726c7f26c', // Will add `nonce` to the script tag
                    // queryParams: {
                    //     // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
                    //     gtm_auth: 'lUvY91639EwTzj2_1JaYAw',
                    //     // gtm_auth: settings.googleApiKey,
                    //     // gtm_cookies_win: 'x',
                    //     gtm_debug: 'x',
                    //     gtm_preview: 'env-2'
                    // },
                    source: 'https://www.googletagmanager.com/gtag/js', // Add your own serverside GTM script
                    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
                    // vueRouter: router // Pass the router instance to automatically sync with router (optional) @todo versiyon uyumsuzluğu
                })
            );
        }

        // router.beforeEach((to, from, next) => {
        //     const store = useRootStore(pinia)
        //     if (!store.ready)
        //         // perform the (user-implemented) store action to fill the store's state
        //         store.initialize()
        //     next()
        // })

        if (typeof head !== 'undefined') {
            head.use(
                SchemaOrgUnheadPlugin({ host: import.meta.env.VITE_APP_URL, tagPosition: 'head', trailingSlash: false }, () => {
                    const route = router.currentRoute.value;
                    return { path: route.path, ...route.meta };
                    // return {};
                })
            );
        }

        // Directives
        app.directive('cto', {
            beforeMount(el, binding) {
                if (typeof binding.value === 'object' || binding.value !== '') {
                    el.setAttribute('data-cto', 'init');
                    el.addEventListener('click', (e: TouchEvent) => {
                        useCioRouter(binding.value, router, e.ctrlKey || e.metaKey);
                        e.preventDefault();
                        return false;
                    });
                }
            },
            beforeUnmount(el, binding) {
                if (el.hasAttribute('data-cto')) {
                    el.removeAttribute('data-cto');
                    el.removeEventListener('click', (e: TouchEvent) => {
                        useCioRouter(binding.value, router, e.ctrlKey || e.metaKey);
                        e.preventDefault();
                        return false;
                    });
                }
            }
        });

        app.directive('content-cto', {
            beforeUnmount(el) {
                for (const link of el.getElementsByTagName('a')) {
                    try {
                        if (link.hasAttribute('data-cto')) {
                            link.removeAttribute('data-cto');
                            const url = new URL(link.href);

                            link.removeEventListener('click', (e: TouchEvent) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                    useCioRouter(url.pathname, router);
                                    e.preventDefault();
                                    return false;
                                }
                            });
                        }
                    } catch (error) {
                        // Sentry.withScope(function (scope) {
                        //     scope.setTag('action-name', 'content-cto-before-unmount');
                        //     scope.setLevel('error');
                        //     Sentry.captureException(error);
                        // });
                    }
                }
            },
            beforeUpdate(el) {
                for (const link of el.getElementsByTagName('a')) {
                    try {
                        const url = new URL(link.href);

                        if (url.pathname === link.attributes.href.value || url.host.search(import.meta.env.VITE_APP_DOMAIN_WITHOUT_EXT) !== -1) {
                            link.setAttribute('data-cto', 'init');
                            link.addEventListener('click', (e: TouchEvent) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                    useCioRouter(url.pathname, router);
                                    e.preventDefault();
                                    return false;
                                }
                            });
                        }
                    } catch (error) {
                        // Sentry.withScope(function (scope) {
                        //     scope.setTag('action-name', 'content-cto-before-update');
                        //     scope.setLevel('error');
                        //     Sentry.captureException(error);
                        // });
                    }
                }
            },
            mounted(el) {
                for (const link of el.getElementsByTagName('a')) {
                    try {
                        const url = new URL(link.href);

                        if (url.pathname === link.attributes.href.value || url.host.search(import.meta.env.VITE_APP_DOMAIN_WITHOUT_EXT) !== -1) {
                            link.setAttribute('data-cto', 'init');
                            link.addEventListener('click', (e: TouchEvent) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                    useCioRouter(url.pathname, router);
                                    e.preventDefault();
                                    return false;
                                }
                            });
                        }
                    } catch (error) {
                        // Sentry.withScope(function (scope) {
                        //     scope.setTag('action-name', 'content-cto-mounted');
                        //     scope.setLevel('error');
                        //     Sentry.captureException(error);
                        // });
                    }
                }
            }
        });

        // app.directive('clipboard', clipboardDirective);

        // app.mixin(screenMixin);

        // Sentry
        if (!import.meta.env.SSR && import.meta.env.VITE_SENTRY_ENABLED === 'true') {
            const [Sentry, SentryVue] = await Promise.all([import('@sentry/capacitor'), import('@sentry/vue')]);

            Sentry.init(
                {
                    app,
                    beforeSend(event) {
                        if (event.tags && typeof event.tags['action-name'] !== 'undefined' && event.tags['action-name'] === 'push-notification-register--web') {
                            if (
                                event.tags['action-name'] === 'push-notification-register--web' ||
                                event.tags['action-name'] === 'axios-response-server-validation' ||
                                event.tags['action-name'] === 'axios-response-server-reject'
                            ) {
                                // Web browser push notification register issue report disabled
                                // Api validation error response issue report disabled
                                // Api 421 reject error response issue report disabled
                                return null;
                            }
                        }

                        return event;
                    },
                    dsn: 'https://66154e185beba0aa951a28654db5ca85@o4504729325207552.ingest.us.sentry.io/4507058049908736',
                    integrations: [
                        SentryVue.browserTracingIntegration({ router }),
                        SentryVue.replayIntegration({
                            blockAllMedia: false,
                            maskAllInputs: false,
                            maskAllText: false,
                            unblock: ['.sentry-unblock, [data-sentry-unblock]'],
                            unmask: ['.sentry-unmask, [data-sentry-unmask]']
                        })
                    ],
                    // Session Replay
                    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                    // Performance Monitoring
                    tracesSampleRate: 1.0 //  Capture 100% of the transactions
                },
                // Forward the init method from @sentry/vue
                SentryVue.init
            );
        }
    }
);

export async function includedRoutes(paths: string[], routes: Readonly<RouteRecordRaw[]>) {
    const module = await import('@/router/ssg-routes');
    const preparedPaths = await module.includedSSGRoutes(paths, routes);
    // await generateSitemaps(preparedPaths.sitemapPaths);

    return preparedPaths.list;
    // return ['/', '/blog', '/blog/gezi', '/blog/gezi/2', '/ankara-arac-kiralama'];
}
