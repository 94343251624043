import { computed, ref } from 'vue';

const screenWidth = ref(1280);
if (typeof window !== 'undefined') {
    screenWidth.value = window.innerWidth;

    const handleResize = () => {
        screenWidth.value = window.innerWidth;
    };

    window.addEventListener('resize', handleResize);
}

const screen = computed(() => ({
    isDesktop: screenWidth.value >= 1050,
    isMobile: screenWidth.value < 1050,

    isPhone: screenWidth.value < 768,
    isTablet: screenWidth.value < 1050 && screenWidth.value >= 768
}));

export const browserIsMobileCheck = (): boolean => {
    const userAgent: string = navigator.userAgent || (window as any).opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return true;
    }

    // Android detection
    if (/android/i.test(userAgent)) {
        return true;
    }

    // Generic mobile detection for modern browsers
    if (/Mobile|mobile|CriOS|FxiOS|Opera Mini|Opera Mobi/i.test(userAgent)) {
        return true;
    }

    return false;
};

export const browserIsMobile = import.meta.env.SSR ? false : browserIsMobileCheck();

export default screen;
