import { nextTick } from 'vue';
import { defineStore } from 'pinia';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useFilterListStore from '@/stores/query/filter-list-store';
import emitter from '@/utils/emitter';

export interface SearchBarStore {
    dialogGroup: string;
    dialogTag: string;
}

const useSearchBarStore = defineStore('search-bar-store', {
    actions: {
        closeAllDialogs() {
            this.dialogGroup = '';
            this.dialogTag = '';
        },
        openDeliveryClockDropDialog() {
            this.dialogGroup = 'DELIVERY_CLOCK';
            this.dialogTag = 'drop';
        },
        openDeliveryClockPickupDialog() {
            this.dialogGroup = 'DELIVERY_CLOCK';
            this.dialogTag = 'pickup';
        },
        openDeliveryDateDropDialog() {
            this.dialogGroup = 'DELIVERY_DATE';
            this.dialogTag = 'drop';
        },
        openDeliveryDatePickupDialog() {
            this.dialogGroup = 'DELIVERY_DATE';
            this.dialogTag = 'pickup';
        },
        openDeliveryPointDropDialog() {
            this.dialogGroup = 'DELIVERY_POINT';
            this.dialogTag = 'drop';
            nextTick(() => {
                const inputElement = document.querySelector('.v-place-dialog input') as HTMLInputElement;
                inputElement?.focus();
            });
            emitter.emit('delivery-point-drop-dialog:opened');
        },
        openDeliveryPointPickupDialog() {
            const deliveryPointStore = useDeliveryPointStore();
            const filterListStore = useFilterListStore();
            this.dialogGroup = 'DELIVERY_POINT';
            this.dialogTag = 'pickup';
            nextTick(() => {
                const inputElement = document.querySelector('.v-place-dialog input') as HTMLInputElement;
                inputElement?.focus();
            });
            emitter.emit('delivery-point-pickup-dialog:opened');
            if (deliveryPointStore.pickupDeliveryPoints.length === 0) {
                deliveryPointStore.loadPickupDeliveryPoints();
                filterListStore.loadFilters();
            }
        }
    },

    getters: {
        getIsAnyDialogOpened(state) {
            return state.dialogGroup !== '' && state.dialogTag !== '';
        },
        getIsDeliveryClockDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_CLOCK';
        },
        getIsDeliveryClockDropDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_CLOCK' && state.dialogTag === 'drop';
        },
        getIsDeliveryClockPickupDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_CLOCK' && state.dialogTag === 'pickup';
        },
        getIsDeliveryDateDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_DATE';
        },
        getIsDeliveryDateDropDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_DATE' && state.dialogTag === 'drop';
        },
        getIsDeliveryDatePickupDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_DATE' && state.dialogTag === 'pickup';
        },
        getIsDeliveryPointDropDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_POINT' && state.dialogTag === 'drop';
        },
        getIsDeliveryPointPickupDialogOpened(state) {
            return state.dialogGroup === 'DELIVERY_POINT' && state.dialogTag === 'pickup';
        }
    },

    state: () =>
        ({
            dialogGroup: '',
            dialogTag: ''
        }) as SearchBarStore
});

export default useSearchBarStore;
