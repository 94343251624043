import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import useAuthStore from '@/stores/auth/auth-store';
import useAuthModalStore from '@/stores/auth/modal-store';
import type * as IRequest from '@/types/auth/register/request';
import type * as IResponse from '@/types/auth/register/response';

interface PStore {
    isLoading: boolean;
    payload: IRequest.Payload;
    isModalOpened: boolean;
}
const useRegisterStore = defineStore('register-store', {
    actions: {
        closeModal() {
            this.isModalOpened = false;
        },
        openModal() {
            this.isModalOpened = true;
        },
        register() {
            this.isLoading = true;
            api.post('/auth/register', this.payload)
                .then((response: IResponse.Payload) => {
                    if (response.data.status_code === 200) {
                        useAuthStore().setLogin({
                            accessToken: response.data.data.accessToken,
                            authProvider: response.data.data.authProvider,
                            tokenType: response.data.data.tokenType
                        });
                        useAuthModalStore().closeRegisterModal();

                        toast.success(i18n.global.t('auth.register.success'));
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        setPayloadEmail(email: string) {
            this.payload.email = email;
        },
        setPayloadFirstName(firstName: string) {
            this.payload.firstName = firstName;
        },
        setPayloadLastName(lastName: string) {
            this.payload.lastName = lastName;
        },
        setPayloadPassword(password: string) {
            this.payload.password = password;
        },
        setPayloadPhoneNumber(phoneNumber: string) {
            this.payload.phoneNumber = phoneNumber;
        },
        setPayloadRePassword(rePassword: string) {
            this.payload.rePassword = rePassword;
        },
        validate() {
            if (this.payload.password !== this.payload.rePassword) {
                toast(i18n.global.t('auth.register.passwordsNotMatch'), {
                    autoClose: 1000,
                    type: 'error'
                });
                return false;
            }

            if (this.payload.password.length < 8) {
                toast(i18n.global.t('auth.register.passwordLength'), {
                    autoClose: 1000,
                    type: 'error'
                });
                return false;
            }

            return true;
        }
    },

    getters: {
        getPayloadEmail(state) {
            return state.payload.email;
        },
        getPayloadFirstName(state) {
            return state.payload.firstName;
        },
        getPayloadLastName(state) {
            return state.payload.lastName;
        },
        getPayloadPassword(state) {
            return state.payload.password;
        },
        getPayloadPhoneNumber(state) {
            return state.payload.phoneNumber;
        },
        getPayloadRePassword(state) {
            return state.payload.rePassword;
        }
    },

    state: () =>
        ({
            isLoading: false,
            isModalOpened: false,
            payload: {
                // firstName: faker.person.firstName(),
                // lastName: faker.person.lastName(),
                // email: faker.internet.email(),
                // password: '123456789..!',
                // rePassword: '123456789..!',
                // phoneNumber: '5414822091',
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                phoneNumber: '',
                rePassword: ''
            }
        }) as PStore
});

export default useRegisterStore;
