import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import { type IRouterLocaleParams, useAppStore } from '@/stores/app-store';
import type { ApiAxiosResponse } from '@/types/general';
import { usePageLoaderStore } from './page-loader-store';
import { usePageSitemapStore } from '@/stores/page-sitemap-store';
import { usePageHomeStore } from '@/stores/page-home-store';
import { usePageAboutIndexStore } from '@/stores/page-about-index-store';
import { usePageContactStore } from '@/stores/page-contact-store';
import { type ISimpleIndexTemplateContent, useSimpleIndexStore } from '@/stores/template/simple-index-store';
import { type IBlogHomeTemplateContentResponse, useBlogHomeStore } from '@/stores/blog-home-store';
import { type IBlogCategoryTemplateDataResponse, useBlogCategoryStore } from '@/stores/blog-category-store';
import { type ISimpleDefaultStore, useSimpleDefaultStore } from '@/stores/template/simple-default-store';
import { usePageOfficeDefaultStore } from '@/stores/page-office-default-store';
import { usePageDocumentDefaultStore } from '@/stores/page-document-default-store';
import emitter from '@/utils/emitter';

export interface INodeHead {
    link: { rel: string; href: string }[];
    meta: { name: string; content: string }[];
    title: string | null;
    og: {
        description?: string;
        image?: string;
        imageHeight?: number;
        imageWidth?: number;
        locale?: string;
        title?: string;
        type?: 'article' | 'website';
        url?: string;
    };
}

export interface IFaqNode {
    clearContent: string;
    content: string;
    group: string;
    locale: string;
    name: string;
    orderRank: number;
    publishedAt: string;
    summary: null | string;
    template: string;
    updatedAt: string;
}

export interface INodeBreadcrumb {
    name: string;
    slug: string;
}

export interface INodeBase {
    breadcrumb: INodeBreadcrumb[];
    content: null | string;
    faqNodes: IFaqNode[];
    group: string;
    head: INodeHead;
    idKey: number;
    key: string;
    loaded: boolean;
    name: null | string;
    publishedAt: string;
    routeLocaleParams: IRouterLocaleParams;
    summary: null | string;
    template: string;
    updatedAt: string;
}

export interface INodeResponse extends INodeBase {
    templateContent: false | null | any[] | object;
}

export interface INodeStore extends INodeBase {
    transactionId: number;
    loading: boolean;
    slug: string;
}

export const useNodeStore = defineStore('node', {
    actions: {
        setData(value: INodeStore) {
            this.$patch(value);
        },
        async setFormApi(slug: string, key: string, isStatic: boolean, loadParams: object = {}): Promise<boolean> {
            // if (this.loading) return false; //!
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loaded = false;
            this.loading = true;

            await api
                .post(
                    `/nodes/slug/${slug}`,
                    Object.assign(
                        {
                            group: isStatic ? 'static' : null,
                            key: key,
                            parentSlug: null
                        },
                        loadParams
                    )
                )
                .then((response: ApiAxiosResponse<INodeResponse>) => {
                    // console.log(JSON.stringify(response));
                    // console.log(response.data.data);
                    const tempHead = response.data.data.head;

                    if (tempHead.title === null) {
                        tempHead.title = response.data.data.name;
                    }

                    if ((tempHead.meta.length === 0 || tempHead.meta.findIndex((i) => i.name === 'description') === -1) && response.data.data.summary !== null) {
                        tempHead.meta.push({
                            content: response.data.data.summary,
                            name: 'description'
                        });
                    }

                    const withoutTemplateData = Object.fromEntries(Object.entries(response.data.data).filter(([key]) => key !== 'templateData')) as INodeBase;

                    if (response.data.data.templateContent !== null && response.data.data.templateContent !== false) {
                        // console.log(response.data.data.templateContent);
                        const appStore = useAppStore();

                        if (response.data.data.key === 'page') {
                            if (response.data.data.group === 'static') {
                                if (response.data.data.template === 'home') {
                                    const pageHomeStore = usePageHomeStore();
                                    pageHomeStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loadedForKey: response.data.data.idKey + appStore.locale.path,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                } else if (response.data.data.template === 'sitemap') {
                                    const pageSitemapStore = usePageSitemapStore();
                                    pageSitemapStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                } else if (
                                    response.data.data.template === 'office-index' ||
                                    response.data.data.template === 'airport-index' ||
                                    response.data.data.template === 'rental-index' ||
                                    response.data.data.template === 'vehicle-group-index' ||
                                    response.data.data.template === 'vehicle-brand-index' ||
                                    response.data.data.template === 'vehicle-model-index' ||
                                    response.data.data.template === 'city-index'
                                ) {
                                    const simpleIndexIndexStore = useSimpleIndexStore();
                                    simpleIndexIndexStore.setData(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loading: false
                                            },
                                            response.data.data.templateContent as ISimpleIndexTemplateContent
                                        )
                                    );
                                }
                            } else if (response.data.data.group === 'corporate') {
                                if (response.data.data.template === 'about-us') {
                                    const pageAboutIndexStore = usePageAboutIndexStore();
                                    pageAboutIndexStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loadedForKey: response.data.data.idKey + appStore.locale.path,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                } else if (response.data.data.template === 'contact') {
                                    const pageContactStore = usePageContactStore();
                                    pageContactStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                }
                            } else if (response.data.data.group === 'office') {
                                if (response.data.data.template === 'office-default') {
                                    const pageOfficeDefaultStore = usePageOfficeDefaultStore();
                                    pageOfficeDefaultStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                }
                            } else if (response.data.data.group === 'document') {
                                if (response.data.data.template === 'document-default') {
                                    const pageDocumentDefaultStore = usePageDocumentDefaultStore();
                                    pageDocumentDefaultStore.$patch(
                                        Object.assign(
                                            {
                                                loaded: true,
                                                loading: false
                                            },
                                            response.data.data.templateContent
                                        )
                                    );
                                }
                            } else if (
                                response.data.data.template === 'city-default' ||
                                response.data.data.template === 'rental-default' ||
                                response.data.data.template === 'vehicle-group-default' ||
                                response.data.data.template === 'vehicle-brand-default' ||
                                response.data.data.template === 'vehicle-model-default' ||
                                response.data.data.template === 'delivery-point-default' ||
                                response.data.data.template === 'airport-default'
                            ) {
                                const simpleDefaultStore = useSimpleDefaultStore();
                                const simpleDefaultContentDataResponse = response.data.data.templateContent as ISimpleDefaultStore;
                                simpleDefaultStore.setData(
                                    Object.assign(
                                        {
                                            loaded: true,
                                            loading: false
                                        },
                                        simpleDefaultContentDataResponse
                                    )
                                );
                            }
                        } else if (response.data.data.key === 'blog') {
                            if (response.data.data.group === 'static') {
                                if (response.data.data.template === 'blog-home') {
                                    const blogHomeTemplateContent = response.data.data.templateContent as IBlogHomeTemplateContentResponse;
                                    const blogHomeStore = useBlogHomeStore();
                                    blogHomeStore.$patch(
                                        Object.assign(
                                            {
                                                currentPage: blogHomeTemplateContent.pagination.viewedPage,
                                                loaded: true,
                                                loading: false
                                            },
                                            blogHomeTemplateContent
                                        )
                                    );
                                }
                            } else if (response.data.data.group === 'category') {
                                if (response.data.data.template === 'blog-category-default') {
                                    const blogCategoryTemplateContent = response.data.data.templateContent as IBlogCategoryTemplateDataResponse;
                                    const blogCategoryStore = useBlogCategoryStore();
                                    blogCategoryStore.$patch(
                                        Object.assign(
                                            {
                                                currentPage: blogCategoryTemplateContent.pagination.viewedPage,
                                                loaded: true,
                                                loading: false
                                            },
                                            blogCategoryTemplateContent
                                        )
                                    );
                                }
                            }
                        }
                    }

                    this.setData(
                        Object.assign(
                            {
                                head: tempHead,
                                loaded: true,
                                loading: false,
                                slug: slug,
                                transactionId: this.transactionId + 1
                            },
                            withoutTemplateData
                        )
                    );

                    emitter.emit('node:loaded');

                    status = true;
                })
                .catch(() => {
                    this.$reset();
                    this.loaded = true;
                    status = false;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });

            // await api
            //     .get(`/${path}/${slug}`)
            //     .then((response: ApiAxiosResponse<INodeStore>) => {
            //         // console.log(JSON.stringify(response));
            //         // console.log(response.data.data);
            //         const tempHead = response.data.data.head;
            //
            //         if (tempHead.title === null) {
            //             tempHead.title = response.data.data.name;
            //         }
            //
            //         if ((tempHead.meta.length === 0 || tempHead.meta.findIndex((i) => i.name === 'description') === -1) && response.data.data.summary !== null) {
            //             tempHead.meta.push({
            //                 content: response.data.data.summary,
            //                 name: 'description'
            //             });
            //         }
            //
            //         this.setData(
            //             Object.assign(
            //                 {
            //                     head: tempHead,
            //                     loaded: true,
            //                     loading: false,
            //                     slug: slug
            //                 },
            //                 response.data.data
            //             )
            //         );
            //         this.$patch({ transactionId: this.transactionId + 1 });
            //         status = true;
            //
            //         emitter.emit('node:loaded');
            //     })
            //     .catch((e) => {
            //         status = false;
            //         this.loaded = true;
            //         this.loading = false;
            //     })
            //     .finally(() => {
            //         pageLoaderStore.unsubscribe(this);
            //     });

            // if(status){
            //     // create body json for get request

            //     await api
            //         .post('/nodes/template-content', {
            //             id: this.idKey,
            //             template: this.template,
            //             key: this.key,
            //             group: this.group
            //         })
            //         .then((response: ApiAxiosResponse<string>) => {
            //             console.log(response.data.data);
            //         })
            //         .catch(() => {
            //             status = false;
            //         });
            // }

            return status;
        }
    },

    getters: {
        getData(state) {
            return {
                breadcrumb: state.breadcrumb,
                content: state.content,
                faqNodes: state.faqNodes,
                group: state.group,
                head: state.head,
                idKey: state.idKey,
                key: state.key,
                loaded: state.loaded,
                loading: state.loading,
                name: state.name,
                publishedAt: state.publishedAt,
                routeLocaleParams: state.routeLocaleParams,
                slug: state.slug,
                summary: state.summary,
                template: state.template,
                transactionId: state.transactionId,
                updatedAt: state.updatedAt
            };
        },
        getTemplateContentPayload(state) {
            return {
                group: state.group,
                id: state.idKey,
                key: state.key,
                template: state.template
            };
        }
    },

    state: () =>
        ({
            breadcrumb: [],
            content: null,
            faqNodes: [],
            group: '',
            head: {
                link: [],
                meta: [],
                og: {},
                title: null
            },
            idKey: 0,
            key: '',
            loaded: false,
            loading: false,
            name: null,
            publishedAt: '',
            routeLocaleParams: {},
            slug: '',
            summary: null,
            template: '',
            transactionId: 0,
            updatedAt: ''
        }) as INodeStore
});
