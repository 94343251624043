<script setup lang="ts">
import { RouterView } from 'vue-router';
import CioLink from '@/components/CioLink.vue';
import VButton from '@/components/Forms/VButton.vue';
import VPageLoader from '@/components/PageLoader/PageLoader.vue';
import VBreadcrumbs from '@/components/UI/Breadcrumbs/VBreadcrumbs.vue';
import screen from '@/plugins/screen';
import useAuthStore from '@/stores/auth/auth-store';
import useAuthModalStore from '@/stores/auth/modal-store';
import useMenu from '@/composables/useMenu';
import { onBeforeUnmount } from 'vue';
import { useAppStore } from '@/stores/app-store';

const appStore = useAppStore();
const authStore = useAuthStore();
const authModalStore = useAuthModalStore();
const { getAccountMenuItems } = useMenu();

onBeforeUnmount(() => {
    appStore.setMobileHeaderTitle(null);
});
</script>

<template>
    <section class="page" id="page-account">
        <template v-if="authStore.isAuthed">
            <div class="container">
                <div class="panel-area">
                    <div class="p-left" v-if="screen.isDesktop">
                        <div class="sticky sidebar-area">
                            <ul>
                                <template :key="item.slug" v-for="item in getAccountMenuItems">
                                    <li>
                                        <CioLink root="account" :name="item.template">
                                            {{ item.label }}
                                        </CioLink>
                                    </li>
                                </template>
                                <!--                                <li>-->
                                <!--                                    <CioLink name="account-personal-information" root="account">-->
                                <!--                                        <VIcon name="user-line" />-->
                                <!--                                        Kişisel Bilgiler-->
                                <!--                                    </CioLink>-->
                                <!--                                </li>-->
                                <!--                                <li>-->
                                <!--                                    <CioLink name="account-reservations" root="account">-->
                                <!--                                        <VIcon name="reserved-line" />-->
                                <!--                                        Rezervasyonlar-->
                                <!--                                    </CioLink>-->
                                <!--                                </li>-->
                                <!--                                <li>-->
                                <!--                                    <CioLink name="account-offers" root="account">-->
                                <!--                                        <VIcon name="file-upload-line" />-->
                                <!--                                        Teklifler-->
                                <!--                                    </CioLink>-->
                                <!--                                </li>-->
                                <!--                                <li>-->
                                <!--                                    <CioLink name="account-payments" root="account">-->
                                <!--                                        <VIcon name="copper-coin-line" />-->
                                <!--                                        Ödemeler-->
                                <!--                                    </CioLink>-->
                                <!--                                </li>-->
                                <!-- <li>
                                    <CioLink name="invitations" root="account">
                                        <VIcon name="user-line" />
                                        Davetler
                                    </CioLink>
                                </li> -->
                                <!-- <li>
                                    <CioLink name="account-earnings" root="account">
                                        <VIcon name="copper-diamond-line" />
                                        Para / Puan
                                    </CioLink>
                                </li> -->
                                <!-- <li>
                                    <CioLink name="account-messages" root="account">
                                        <VIcon name="user-line" />
                                        Mesajlar
                                    </CioLink>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="p-right">
                        <VBreadcrumbs standalone route-root="account" v-if="screen.isDesktop" />
                        <!--                        <VBreadcrumbs-->
                        <!--                            :breadcrumb="[]"-->
                        <!--                            :items="[-->
                        <!--                                { label: 'Hesabım', path: '/account' },-->
                        <!--                                { label: 'Kişisel Bilgiler', path: '/account/personal-info' }-->
                        <!--                            ]"-->
                        <!--                        />-->
                        <div class="content-area">
                            <router-view v-slot="{ Component }">
                                <transition name="route-change">
                                    <component :is="Component" />
                                </transition>
                            </router-view>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="authStore.isLoaded">
            <div class="no-auth">
                <p>{{ $t('account.auth.noAuth.description') }}</p>
                <VButton :label="$t('auth.login.login')" @click="authModalStore.openLoginModal" />
            </div>
        </template>

        <template v-else-if="authStore.isLoading">
            <VPageLoader embedded />
        </template>
    </section>
</template>
