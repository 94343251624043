import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export type IDocumentMenuNode = {
    label: string;
    orderRank: number;
    slug: string;
    active: boolean;
    nodeKey: string;
    nodeGroup: string;
};

export type IPageDocumentContentDataResponse = {
    menuNodes: IDocumentMenuNode[];
};

export interface IPageDocumentStore {
    loaded: boolean;
    loading: boolean;
    menuNodes: IDocumentMenuNode[];
}

export const usePageDocumentDefaultStore = defineStore('page-document-default', {
    actions: {
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;
            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<IPageDocumentContentDataResponse>) => {
                    this.$patch(
                        Object.assign({
                            loaded: true,
                            loading: false,
                            menuNodes: response.data.data.menuNodes
                        })
                    );
                    status = true;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });
            return status;
        }
    },

    getters: {
        getData(state: IPageDocumentStore): IPageDocumentStore {
            return state;
        },
        getMenuNodes(): IDocumentMenuNode[] {
            return this.menuNodes;
        }
    },

    state: () =>
        ({
            loaded: false,
            loading: false,
            menuNodes: []
        }) as IPageDocumentStore
});
