import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import i18n from '@/locales/locale';
import { api } from '@/plugins/axios';
import useAuthStore from '@/stores/auth/auth-store';
import { usePageLoaderStore } from '@/stores/page-loader-store';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import useReservationCreateStore from '@/stores/reservation/create-store';
import type { ApiAxiosResponse } from '@/types/general';
import type * as ICreateResponse from '@/types/reservation/create/response';
import * as IRequest from '@/types/reservation/store/request';
import * as IResponse from '@/types/reservation/store/response';
import * as ISummary from '@/types/reservation/summary';
import { humanReadableDate } from '@/utils/date-format';
import emitter from '@/utils/emitter';
import { humanReadableMoney } from '@/utils/money-format';
import { Validator } from '@/utils/validator';

// interface SummaryLine {
//     label: string;
//     content?: string;
//     value?: number;
//     valueString?: string;
//     isFeatured?: boolean;
//     isDescription?: boolean;
//     isPassive?: boolean;
// }
// interface SummaryGroup {
//     key: string;
//     label: string;
//     lines: SummaryLine[];
// }

interface PStore {
    storing: IRequest.Payload;
    stored: IResponse.Data;
}

const validators: Record<string, Validator> = {};

const useReservationStoreStore = defineStore('reservation-store-store', {
    actions: {
        complete() {
            // tüm validatorleri doğrula, doğrulama sırasında doğrulanmamış olan varsa çık

            for (const key in validators) {
                if (!validators[key].validate()) {
                    return;
                }
            }

            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);

            const payload = this.storing;
            payload.extras.paid = payload.extras.paid.filter((extra: IRequest.Paid) => extra.id > 0 && extra.totalPrice > 0);
            payload.extras.paid = payload.extras.paid.map((extra: IRequest.Paid) => ({
                id: extra.id,
                piece: extra.piece,
                totalPrice: extra.totalPrice * extra.piece
            }));
            payload.vehicle.totalPrice = this.getVehiclePrice.total;

            // Check Discount Code
            const discountCodeStore = useDiscountCodeStore();
            if (discountCodeStore.code && discountCodeStore.isValid) {
                payload.discount = {
                    code: discountCodeStore.code,
                    email: discountCodeStore.email
                };
                // if email is not set, set it from auth store
                if (!discountCodeStore.email) {
                    const authStore = useAuthStore();
                    if (authStore.isAuthed && authStore.getEmail) {
                        payload.customer.email = authStore.getEmail;
                    }
                }
            }

            return new Promise((resolve, reject) => {
                api.post('/reservations/store', payload)
                    .then(async (response: ApiAxiosResponse<IResponse.Data>) => {
                        const data = response.data.data;

                        // if (data.emailVelidationRequired) {

                        // }

                        if (data.id) {
                            this.$patch({ stored: data });
                            toast.success(i18n.global.t('reservation.store.success'));

                            emitter.emit('reservation:stored-before');

                            localStorage.removeItem('RESERVATION_CREATE_REQUEST_PAYLOAD');
                            localStorage.removeItem('RESERVATION_CREATE_RESPONSE_AT');
                            localStorage.removeItem('RESERVATION_CREATE_RESPONSE_PAYLOAD');
                            localStorage.removeItem('RESERVATION_STORE_REQUEST_PAYLOAD');

                            setTimeout(() => {
                                resolve(data);
                                emitter.emit('reservation:stored');
                                discountCodeStore.clear();
                                useReservationCreateStore().$reset();
                                pageLoaderStore.unsubscribe(this);
                            }, 500);
                        }
                    })
                    .catch(() => {
                        // this lines deprecated because error messages moved to axios plugin
                        // console.log(error);
                        // toast.error(message);
                        // const message = error.response.data.message || i18n.global.t('reservation.store.error');
                        reject();
                        pageLoaderStore.unsubscribe(this);
                    });
                // .finally(() => {
                //
                // });
            });
        },
        loadFromLocalStore() {
            if (!import.meta.env.SSR) {
                const storing: IRequest.Payload = JSON.parse(localStorage.getItem('RESERVATION_STORE_REQUEST_PAYLOAD') || 'null') as IRequest.Payload;
                if (storing) {
                    this.$patch({ storing });
                }
            }
        },
        runValidator(key: string) {
            return validators[key].validate();
        },
        saveToLocalStore() {
            if (!import.meta.env.SSR) {
                localStorage.setItem('RESERVATION_STORE_REQUEST_PAYLOAD', JSON.stringify(this.$state.storing));
            }
        },
        setValidator(name: string, validator: Validator) {
            validators[name] = validator;
        }
    },

    getters: {
        getSelectedPaymentType(): ICreateResponse.PaymentType | null {
            const reservationCreateStore = useReservationCreateStore();
            return reservationCreateStore.created?.paymentTypes?.find((paymentType: ICreateResponse.PaymentType) => paymentType.key === this.storing.payment.type) || null;
        },
        getSummaryGroups(): ISummary.Group[] {
            const reservationCreateStore = useReservationCreateStore();
            const groups: ISummary.Group[] = [];
            if (!reservationCreateStore.created) {
                return groups;
            }

            //# Pickup and Drop Informations
            const deliveryGroup: ISummary.Group = {
                items: [
                    // Pickup
                    {
                        bodyEntities: [
                            {
                                content: i18n.global.t('reservation.summary.pickupInformations'),
                                isBold: true
                            },
                            {
                                content: reservationCreateStore.created?.rental.pickup.pointName + '<br>' + humanReadableDate(reservationCreateStore.created?.rental.pickup.dateTime)
                            }
                        ]
                    },
                    // Drop
                    {
                        bodyEntities: [
                            {
                                content: i18n.global.t('reservation.summary.dropInformations'),
                                isBold: true
                            },
                            {
                                content: reservationCreateStore.created?.rental.drop.pointName + '<br>' + humanReadableDate(reservationCreateStore.created?.rental.drop.dateTime)
                            }
                        ]
                    }
                ],
                title: i18n.global.t('reservation.summary.pickupDropInformations')
            };
            groups.push(deliveryGroup);

            //# Vehicle Informations
            const vehicle = reservationCreateStore.created?.vehicle;
            if (!vehicle) return groups;
            const vehicleGroup: ISummary.Group = {
                items: [],
                title: i18n.global.t('reservation.summary.vehicleInformations')
            };
            const vehicleGroupItem = {
                bodyEntities: [],
                leftEntities: [],
                rightEntities: []
            } as ISummary.Item;
            vehicleGroup.items.push(vehicleGroupItem);
            // Vehicle Name
            vehicleGroupItem.leftEntities?.push({
                content: vehicle.brand.name + ' - ' + vehicle.name
            });
            vehicleGroupItem.bodyEntities?.push({
                content: vehicle.group.name + ' - ' + vehicle.fuelType.name + ' ' + vehicle.transmissionType.name,
                isItalic: true
            });
            // Vehicle Price
            vehicleGroupItem.rightEntities?.push({
                content: humanReadableMoney(vehicle.prices.display.total),
                isBold: true,
                priceValue: vehicle.prices.display.total
            });
            // Vehicle Price Discounted
            if (vehicle.prices.discountStatus) {
                // vehicleGroupItem.rightEntities?.push({
                //     content: humanReadableMoney(vehicle.prices.normal.total),
                //     isFade: true,
                //     isStrikethrough: true
                // });
                // vehicleGroupItem.bodyEntities?.push({
                //     content: i18n.global.t('reservation.summary.discountApplied', { amount: humanReadableMoney(vehicle.prices.display.total - vehicle.prices.normal.total) }),
                //     isBold: true,
                //     isColorful: true
                // });

                vehicleGroup.items.push({
                    leftEntities: [
                        {
                            content: i18n.global.t('reservation.summary.discountApplied', { amount: humanReadableMoney(vehicle.prices.display.total - vehicle.prices.normal.total) }),
                            isBold: true,
                            isColorful: true
                        }
                    ],
                    rightEntities: [
                        {
                            content: humanReadableMoney(vehicle.prices.normal.total),
                            isFade: true,
                            isStrikethrough: true
                        }
                    ]
                });
            }

            // # Vehicle Deposit
            if (reservationCreateStore.created.vehicle.prices.deposit > 0) {
                vehicleGroup.items.push({
                    leftEntities: [
                        {
                            content: i18n.global.t('vehicle.card.deposit'),
                            isFade: true
                        }
                    ],
                    rightEntities: [
                        {
                            content: humanReadableMoney(reservationCreateStore.created.vehicle.prices.deposit, undefined, 0),
                            isFade: true
                        }
                    ]
                });
            }

            groups.push(vehicleGroup);

            //# Payment Type
            const paymentType = this.getSelectedPaymentType;
            if (paymentType) {
                const paymentTypeGroup: ISummary.Group = {
                    items: [],
                    title: i18n.global.t('reservation.summary.paymentType')
                };
                const paymentTypeGroupItem = {
                    leftEntities: [],
                    rightEntities: []
                } as ISummary.Item;
                paymentTypeGroup.items.push(paymentTypeGroupItem);
                // Payment Type Label
                paymentTypeGroupItem.leftEntities?.push({
                    content: paymentType.label
                });
                // Payment Type Amount
                if (paymentType.amount) {
                    paymentTypeGroupItem.rightEntities?.push({
                        content: humanReadableMoney(paymentType.amount),
                        isBold: true,
                        priceValue: paymentType.amount
                    });
                }
                groups.push(paymentTypeGroup);
            }

            //# Extras
            reservationCreateStore.created.extras.forEach((extra: ICreateResponse.Extra) => {
                const groupExtra: ISummary.Group = {
                    items: [],
                    title: extra.title
                };
                extra.items.forEach((item) => {
                    const groupExtraItem = {
                        bodyEntities: [],
                        leftEntities: [],
                        rightEntities: []
                    } as ISummary.Item;

                    const selectedItem = this.storing.extras.paid.find((i: IRequest.Paid) => i.id === item.id);
                    if (selectedItem && selectedItem.piece > 0) {
                        // Extra Item Name
                        groupExtraItem.leftEntities?.push({
                            content: item.name,
                            isBold: true
                        });
                        // Extra Item Price
                        const price = selectedItem.piece * (item.prices as ICreateResponse.ItemPrices)?.total || 0;
                        if (price > 0) {
                            groupExtraItem.rightEntities?.push({
                                content: humanReadableMoney(price),
                                isBold: true,
                                priceValue: price
                            });
                        }
                        // Extra Item Content of Description
                        if (item.content) {
                            groupExtraItem.bodyEntities?.push({
                                content: item.content
                            });
                        }

                        // groupExtra.lines.push({
                        //     content: item.content || undefined,
                        //     label: item.name,
                        //     value: selectedItem.piece * (item.prices as ICreateResponse.ItemPrices)?.total || 0,
                        //     valueString: humanReadableMoney(selectedItem.piece * (item.prices as ICreateResponse.ItemPrices)?.total || 0)
                        // });
                    }
                    if ((groupExtraItem.leftEntities?.length || 0) + (groupExtraItem.rightEntities?.length || 0) + (groupExtraItem.bodyEntities?.length || 0) > 0) groupExtra.items.push(groupExtraItem);
                });
                if (groupExtra.items.length > 0) {
                    groups.push(groupExtra);
                }
            });
            return groups;
        },
        getTotalPrice(): number {
            let total = 0;
            this.getSummaryGroups.forEach((group: ISummary.Group) => {
                group.items.forEach((item) => {
                    total +=
                        (item.leftEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0) +
                        (item.rightEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0) +
                        (item.bodyEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0);
                });
            });

            return total;

            // return this.getSummaryGroups.reduce((acc: number, group: ISummary.Group) => {
            //     return (
            //         acc +
            //         group.items.reduce((acc, item) => {
            //             // left,right,body entitilerinin priceValue'ları toplamını döndür eğer yoksa 0 döndür
            //             return (
            //                 acc +
            //                 (item.leftEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0) +
            //                 (item.rightEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0) +
            //                 (item.bodyEntities?.reduce((acc, entity) => acc + (entity.priceValue || 0), 0) || 0)
            //             );
            //         }, 0)
            //     );
            // }, 0);
        },
        getVehicle(): ICreateResponse.Vehicle | null {
            const reservationCreateStore = useReservationCreateStore();
            if (!reservationCreateStore.created) return null;
            if (!reservationCreateStore.created.vehicle) return null;
            return reservationCreateStore.created.vehicle;
        },
        getVehiclePrice(): ICreateResponse.VehiclePrice {
            const vehicle = this.getVehicle;
            const paymentType = this.getSelectedPaymentType;

            if (!vehicle || !paymentType) return { daily: 0, total: 0 } as ICreateResponse.VehiclePrice;

            if (vehicle.prices.discountStatus) {
                return vehicle.prices.discount;
            }

            if (paymentType.key == IRequest.PaymentTypeKey.Now && vehicle.prices.earlyStatus) {
                return vehicle.prices.early;
            }
            return vehicle.prices.normal;
        }
    },

    state: () =>
        ({
            stored: {
                currencyCode: 'EUR',
                customerId: 0,
                customerIsNew: '0',
                id: 0,
                paymentRedirect: false,
                pnr: '',
                totalAmount: 0
            },
            storing: {
                bonusIds: [],
                customer: {
                    address: '',
                    birthDate: '',
                    cityId: null,
                    drivingLicenseAge: 0,
                    email: '',
                    firstName: '',
                    gender: null,
                    lastName: '',
                    passportNo: null,
                    phoneNumber: {
                        countryCode: '90',
                        id: null,
                        number: ''
                    },
                    tcNo: '',
                    turkeyAddress: null
                },
                discountCode: null,
                drop: {
                    dateTime: '',
                    description: null,
                    detail: null,
                    pointId: 0
                },
                extras: {
                    paid: []
                },
                note: null,
                offerId: null,
                payment: {
                    type: IRequest.PaymentTypeKey.Now
                },
                pickup: {
                    dateTime: '',
                    description: null,
                    detail: null,
                    pointId: 0
                },
                queryId: null,
                vehicle: {
                    id: 0,
                    totalPrice: 0
                }
            },
            validators: {}
        }) as PStore
});

export default useReservationStoreStore;
