import { useAppStore } from '@/stores/app-store';
import useCurrencyStore from '@/stores/app/currency-store';

export const humanReadableMoney = (value: number | string | null | undefined, currencyCode?: string, digits?: number): string => {
    const foatValue = parseFloat(value as string);
    if (isNaN(foatValue)) {
        return '';
    }
    const currency = currencyCode || useCurrencyStore().currency.code;
    return foatValue.toLocaleString(useAppStore().locale.code, {
        currency: currency,
        currencyDisplay: 'symbol',
        maximumFractionDigits: digits === undefined ? 2 : digits,
        minimumFractionDigits: digits === undefined ? 2 : digits,
        style: 'currency',
        useGrouping: true
    });
};

export default {
    humanReadableMoney
};
