import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import moment from 'moment';

export const clearLocalStorage = (): void => {
    localStorage.removeItem('SEARCH_FORM');
};

export const setToLocalStorage = (): void => {
    const deliveryPointStore = useDeliveryPointStore();
    const deliveryTimeStore = useDeliveryTimeStore();

    const params = {
        drop: {
            dateTime: deliveryTimeStore.getDropDateTime === null ? undefined : deliveryTimeStore.getDropDateTime.getTime(),
            pointId: deliveryPointStore.selectedDropDeliveryPoint?.id
        },
        pickup: {
            dateTime: deliveryTimeStore.getPickupDateTime === null ? undefined : deliveryTimeStore.getPickupDateTime.getTime(),
            pointId: deliveryPointStore.selectedPickupDeliveryPoint?.id
        },
        time: new Date().getTime()
    };

    localStorage.setItem('SEARCH_FORM', JSON.stringify(params));
};

export const getFromLocalStorage = async (): Promise<void> => {
    const localStorageParams = localStorage.getItem('SEARCH_FORM');

    if (localStorageParams !== null) {
        const params = JSON.parse(localStorageParams);

        if (typeof params.time === 'undefined') {
            clearLocalStorage();
        } else {
            if (typeof params.pickup.dateTime !== 'undefined') {
                const deliveryTimeStore = useDeliveryTimeStore();
                const pickupMinuteDiff = moment(params.pickup.dateTime).diff(new Date(), 'minutes');

                if (pickupMinuteDiff >= 120) {
                    deliveryTimeStore.setPickupDateTime(new Date(params.pickup.dateTime));

                    if (typeof params.drop.dateTime !== 'undefined') {
                        const dropMinuteDiff = moment(params.drop.dateTime).diff(new Date(), 'minutes');

                        if (dropMinuteDiff >= 120) {
                            deliveryTimeStore.setDropDateTime(new Date(params.drop.dateTime));
                        }
                    }
                }
            }

            if (typeof params.pickup.pointId !== 'undefined') {
                const deliveryPointStore = useDeliveryPointStore();

                if (deliveryPointStore.selectedPickupDeliveryPointId === null) {
                    await deliveryPointStore.loadPickupDeliveryPoints();
                    const pickupDeliveryPoint = deliveryPointStore.pickupDeliveryPoints.find((i) => i.id === parseInt(params.pickup.pointId));

                    if (typeof pickupDeliveryPoint !== 'undefined') {
                        deliveryPointStore.selectPickupDeliveryPoint(pickupDeliveryPoint);
                        deliveryPointStore.setHasManualSelected(false);
                    }

                    if (typeof params.drop.pointId !== 'undefined' && params.pickup.pointId !== params.drop.pointId) {
                        await deliveryPointStore.loadDropDeliveryPoints(parseInt(params.pickup.pointId));
                        const dropDeliveryPoint = deliveryPointStore.dropDeliveryPoints.find((i) => i.id === parseInt(params.drop.pointId));

                        if (typeof dropDeliveryPoint !== 'undefined') {
                            deliveryPointStore.selectDropDeliveryPoint(dropDeliveryPoint);
                            deliveryPointStore.setUseDifferentDropLocation(true);
                            deliveryPointStore.setHasManualSelected(false);
                        }
                    }
                }
            }

            // const createMinuteDiff = moment(new Date()).diff(params.time, 'minutes');
        }
    }
};

export default {
    clearLocalStorage,
    getFromLocalStorage,
    setToLocalStorage
};
