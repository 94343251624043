<script lang="ts" setup>
import {
    onBeforeUnmount,
    onMounted,
    ref,
} from 'vue';

import CioLink from '@/components/CioLink.vue';
import VIcon from '@/components/UI/VIcon.vue';
import useMenu from '@/composables/useMenu';
import useAuthStore from '@/stores/auth/auth-store';

import VButton from '../Forms/VButton.vue';

const authStore = useAuthStore();

const { 
    getAccountMenuItems 
} = useMenu();

const isOpen = ref(false);

const handleOpen = () => {
    isOpen.value = true;
};

const handleClose = () => {
    isOpen.value = false;
};

const handleClickedOutside = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        if (!event.target.closest('.v-auth-signedin-desktop')) {
            isOpen.value = false;
        }
    }
};

// mount ta document cli
onMounted(() => {
    document.addEventListener('click', handleClickedOutside);
});

// unmount ta document cli
onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickedOutside);
});
 
   
</script>

<template>
    <div class="v-auth-signedin-desktop">
        <div class="signedin-button" @click="handleOpen">
            <VIcon name="user-line" :size="24" />
            <div class="fullname" v-if="authStore.isAuthed">
                {{ authStore.getFullName }}
            </div>
            <VIcon name="arrow-down-s-fill" :size="24" />
        </div>
        <div class="signedin-menu" v-if="isOpen"> 
           <ul>
                <template v-for="item in getAccountMenuItems">
                    <li> 
                        <CioLink root="account" :name="item.template" @click="handleClose">
                            {{ item.label }}
                        </CioLink>
                    </li>
                </template>
                <!-- <li><CioLink locale="tr" name="home" root="app" tag="a">Anasayfa</CioLink></li>
                <li><CioLink locale="tr" name="page" :params="{ slug: 'hakkimizda' }" root="app" tag="a">Hakkımızda</CioLink></li>
                <li><CioLink locale="tr" name="page" :params="{ slug: 'iletisim' }" root="app" tag="a">İletişim</CioLink></li>
                <li><CioLink locale="tr" name="page" :params="{ slug: 'kiralik-arac-teslim-noktalari' }" root="app" tag="a">Sayfa</CioLink></li>
                <li><CioLink locale="tr" name="sitemap" root="app" tag="a">Site haritası</CioLink></li>
                <li><CioLink locale="tr" name="home" root="blog" tag="a">Blog</CioLink></li>
                <li><CioLink locale="tr" name="" root="account" tag="a">Hesap</CioLink></li> -->
            </ul>
            <hr />
            <VButton
                variant="tonal"
                color="secondary"
                :label="$t('auth.logout.title')"
                @click="authStore.logout"
                full-width
            />
        </div>
    </div>
</template> 