import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import { useCioRouter } from '@/composables/useCioRouter';
import useMenu from '@/composables/useMenu';
import { api } from '@/plugins/axios';
import { usePageLoaderStore } from '@/stores/page-loader-store';
import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useDiscountCodeStore from '@/stores/query/discount-code-store';
import useVehicleListStore from '@/stores/query/vehicle-list-store';
import useReservationStoreStore from '@/stores/reservation/store-store';
import type { ApiAxiosResponse } from '@/types/general';
import type { IInvioceListItem } from '@/types/invoice';
import type { DeliveryPoint } from '@/types/query/delivery-point';
import type { Vehicle } from '@/types/query/vehicle-list';
import type * as IRequest from '@/types/reservation/create/request';
import type * as IResponse from '@/types/reservation/create/response';
import type * as IStoreRequest from '@/types/reservation/store/request';
import dateFormat from '@/utils/date-format';
import emitter from '@/utils/emitter';
import moneyFormat from '@/utils/money-format';
import { useAppStore } from '@/stores/app-store';
import i18n from '@/locales/locale';

export interface PStore {
    activeStep: string;
    isActive: boolean;
    steps: { isCompleted: boolean; key: string; name: string; validator: null | string }[];
    creating: IRequest.Payload | null;
    created: IResponse.Data | null;
    createdBefore: boolean;
    pickupDeliveryPoint: DeliveryPoint | null;
    dropDeliveryPoint: DeliveryPoint | null;
    pickupDateTime: Date;
    dropDateTime: Date;
    // selectedExtraItems: ICreate.SelectedExtraItem[];
}

const useReservationCreateStore = defineStore('reservation-create-store', {
    actions: {
        async checkout(vehicle: Vehicle, payAt: string) {
            // const pageLoaderStore = usePageLoaderStore();
            const deliveryPointStore = useDeliveryPointStore();
            const deliveryTimeStore = useDeliveryTimeStore();
            const vehicleListStore = useVehicleListStore();
            // pageLoaderStore.subscribe(this);

            if (deliveryPointStore.getSelectedPickupDeliveryPoint === null) {
                // toast.error('Lütfen teslim alma noktasını seçiniz.');
                return;
            }

            const payload: IRequest.Payload = {
                discountPriceStatus: vehicle.prices.discountStatus,
                dropAt: dateFormat.toString(deliveryTimeStore.getDropDateTime),
                dropPointId: deliveryPointStore.getSelectedDropDeliveryPoint?.id || null,
                earlyPriceStatus: vehicle.prices.earlyStatus,
                offerId: null,
                payAt,
                pickupAt: dateFormat.toString(deliveryTimeStore.getPickupDateTime),
                pickupPointId: deliveryPointStore.getSelectedPickupDeliveryPoint.id,
                queryId: vehicleListStore.queryId,
                vehicleId: vehicle.id
            };

            // if (discountCodeStore.isValid) {
            //     payload.discount = {
            //         code: discountCodeStore.code,
            //         email: discountCodeStore.email
            //     };
            // }

            if (!import.meta.env.SSR) {
                localStorage.setItem('RESERVATION_CREATE_REQUEST_PAYLOAD', JSON.stringify(payload));
            }

            await this.create();
            // pageLoaderStore.unsubscribe(this);
        },

        async create(): Promise<void> {
            const reservationStoreStore = useReservationStoreStore();
            const pageLoaderStore = usePageLoaderStore();

            if (!this.createdBefore) {
                this.setStep('info');
            }

            let payload = null;

            if (!import.meta.env.SSR) {
                payload = JSON.parse(localStorage.getItem('RESERVATION_CREATE_REQUEST_PAYLOAD') || 'null') as IRequest.Payload;
            }

            if (payload === null) {
                toast.error(i18n.global.t('reservation.create.errorMessage'));
                return;
            }

            const discountCodeStore = useDiscountCodeStore();

            if (payload.discountPriceStatus) {
                payload.discount = await discountCodeStore.getPlainPayload();
            }

            // payload.discount = undefined;
            // if (discountCodeStore.isValid) {
            //     payload.discount = {
            //         code: discountCodeStore.code,
            //         email: discountCodeStore.email
            //     };
            // }
            const subscribeThis = this;
            pageLoaderStore.subscribe(subscribeThis);

            api.post('/reservations/create', payload)
                .then(async (response: ApiAxiosResponse<IResponse.Data>) => {
                    // created i response.data.data ile patchle
                    this.$patch({ created: response.data.data });

                    if (this.created === null || this.created === undefined) {
                        toast.error(i18n.global.t('reservation.create.errorMessage'));
                        return;
                    }

                    emitter.emit('reservation-create:loaded');

                    reservationStoreStore.$reset();

                    reservationStoreStore.storing.extras.paid = this.extrasToPaidList(this.created.extras);
                    reservationStoreStore.storing.vehicle.id = payload.vehicleId;
                    reservationStoreStore.storing.queryId = this.created.queryId;
                    reservationStoreStore.storing.pickup.pointId = payload.pickupPointId;
                    reservationStoreStore.storing.pickup.dateTime = payload.pickupAt;
                    reservationStoreStore.storing.drop.pointId = payload.dropPointId || payload.pickupPointId;
                    reservationStoreStore.storing.drop.dateTime = payload.dropAt;

                    reservationStoreStore.storing.payment.type = this.created.paymentTypes.find((pt) => pt.default)?.key as IStoreRequest.PaymentTypeKey;

                    // prepare visitor
                    reservationStoreStore.storing.customer.firstName = this.created.customer?.firstName || this.created.visitor?.firstName || '';
                    reservationStoreStore.storing.customer.lastName = this.created.customer?.lastName || this.created.visitor?.lastName || '';
                    reservationStoreStore.storing.customer.email = this.created.customer?.email || this.created.visitor?.email || '';
                    reservationStoreStore.storing.customer.birthDate = this.created.customer?.birthDate || this.created.visitor?.birthDate || '';
                    reservationStoreStore.storing.customer.gender = this.created.customer?.gender || null;
                    reservationStoreStore.storing.customer.drivingLicenseAge = this.created.customer?.drivingLicenseAge || 0;
                    reservationStoreStore.storing.customer.tcNo = this.created.customer?.tcNo || '';
                    reservationStoreStore.storing.customer.address = this.created.customer?.address || '';
                    this.created.customer.phoneNumbers.forEach((pn: IResponse.PhoneNumber) => {
                        reservationStoreStore.storing.customer.phoneNumber = {
                            countryCode: pn.countryCode,
                            id: pn.id,
                            number: pn.phoneNumber
                        };
                    });

                    this.saveToLocalStore();
                    reservationStoreStore.saveToLocalStore();

                    useCioRouter(
                        {
                            localePath: null,
                            name: 'create',
                            params: {},
                            root: 'reservation'
                        },
                        this.$router
                    );

                    if (payload.discountPriceStatus && !this.created.discountCodeIsValid && discountCodeStore.isValid) {
                        // @todo toast bilgi gerekli
                        discountCodeStore.clear();
                    }

                    this.$patch({ createdBefore: true });
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(subscribeThis);
                });
        },

        async discountCheckoutRefresh() {
            let payload = null;

            if (!import.meta.env.SSR) {
                payload = JSON.parse(localStorage.getItem('RESERVATION_CREATE_REQUEST_PAYLOAD') || 'null') as IRequest.Payload;
            }

            if (payload === null) {
                return;
            }

            const discountCodeStore = useDiscountCodeStore();

            if (discountCodeStore.isValid) {
                payload.discount = await discountCodeStore.getPlainPayload();
                payload.discountPriceStatus = true;
            } else {
                payload.discountPriceStatus = false;
                payload.discount = undefined;
            }

            if (!import.meta.env.SSR) {
                localStorage.setItem('RESERVATION_CREATE_REQUEST_PAYLOAD', JSON.stringify(payload));
            }

            await this.create();
        },

        extrasToPaidList(extras: IResponse.Extra[]): IStoreRequest.Paid[] {
            const paids = [] as IStoreRequest.Paid[];

            if (!this.created) return paids;

            extras.forEach((extra: IResponse.Extra) => {
                extra.items.forEach((item: IResponse.ExtraItem) => {
                    const paid = {
                        id: item.id || -1,
                        piece: 0,
                        totalPrice: item.prices.total || 0
                    };
                    if (extra.requiredAnyItems.length > 0) {
                        if (paid.id === extra.requiredAnyItems[0]) {
                            paid.piece = 1;
                        }
                    } else if (item.freeStatus === true) {
                        paid.piece = 1;
                    }

                    paids.push(paid);
                });
            });

            return paids;
        },
        async initialize() {
            if (!import.meta.env.SSR) {
                if (!this.createdBefore) {
                    await this.create();
                    return;
                }
            }
            // const pageLoaderStore = usePageLoaderStore();
            // pageLoaderStore.subscribe(this);
            this.loadFromLocalStore();
            // pageLoaderStore.unsubscribe(this);
        },
        loadFromLocalStore() {
            if (!import.meta.env.SSR) {
                const searchPageLink = useMenu().getStaticRoute('vehicleSearch');
                const cioRouter = useCioRouter();

                const createDateString = localStorage.getItem('RESERVATION_CREATE_RESPONSE_AT');

                if (createDateString !== null) {
                    const createDate = createDateString ? new Date(createDateString) : null;
                    // eğer 20 dakikadan fazla süre geçmişse localStorage temizlenir.
                    if (createDate && new Date().getTime() - createDate.getTime() > 1000 * 60 * 20) {
                        localStorage.removeItem('RESERVATION_CREATE_RESPONSE_PAYLOAD');
                        localStorage.removeItem('RESERVATION_STORE_REQUEST_PAYLOAD');
                        localStorage.removeItem('RESERVATION_CREATE_RESPONSE_AT');
                        toast.error(i18n.global.t('reservation.create.timeoutMessage'));

                        // useCioRouter().openStaticSlug('page', 'vehicle-price-list');

                        if (typeof searchPageLink === 'undefined') {
                            cioRouter.redirectToHome();
                        } else {
                            cioRouter.prepareOpen(searchPageLink.routeRoot, searchPageLink.routeName, { slug: searchPageLink.url });
                        }
                    } else {
                        const data: PStore = JSON.parse(localStorage.getItem('RESERVATION_CREATE_RESPONSE_PAYLOAD') || 'null') as PStore;
                        if (data) {
                            this.$patch(Object.assign(data, { activeStep: this.activeStep, createdBefore: true }));
                            const reservationStoreStore = useReservationStoreStore();
                            if (reservationStoreStore.storing.vehicle.id === 0) {
                                reservationStoreStore.loadFromLocalStore();
                            }
                            localStorage.setItem('RESERVATION_CREATE_RESPONSE_AT', new Date().toISOString());
                        }
                    }
                } else {
                    // useCioRouter().openStaticSlug('page', 'vehicle-price-list');
                    if (typeof searchPageLink === 'undefined') {
                        cioRouter.redirectToHome();
                    } else {
                        cioRouter.prepareOpen('app', 'page', { slug: searchPageLink.url });
                    }
                }
            }
        },
        saveToLocalStore() {
            if (!import.meta.env.SSR) {
                localStorage.setItem('RESERVATION_CREATE_RESPONSE_PAYLOAD', JSON.stringify(this.$state));
                localStorage.setItem('RESERVATION_CREATE_RESPONSE_AT', new Date().toISOString());
            }
        },
        setIsActive(value: boolean) {
            this.isActive = value;
        },
        setStep(value: string) {
            if (!this.isActive) {
                return;
            }

            this.activeStep = value;
            const activeStepIndex = this.steps.findIndex((s) => s.key === this.activeStep);
            const scrollArea = document.getElementById('app');

            if (scrollArea !== null) {
                scrollArea.style.overflowY = 'hidden';
                scrollArea?.scrollTo(0, 0);
            }

            const appStore = useAppStore();
            appStore.setMobileHeaderTitle(this.steps[activeStepIndex].name);

            if (activeStepIndex === 0) {
                appStore.removeBackRouteBlocker('reservation-create-step-prev');
            } else {
                appStore.addBackRouteBlocker({
                    fn: () => {
                        this.stepPrev();
                    },
                    key: 'reservation-create-step-prev'
                });
            }

            setTimeout(() => {
                if (scrollArea !== null) {
                    scrollArea.style.removeProperty('overflow-y');
                    scrollArea?.scrollTo(0, 0);
                }
            }, 100);
        },
        stepIsValid() {
            const reservationStoreStore = useReservationStoreStore();
            const activeIndex = this.steps.findIndex((s) => s.key === this.activeStep);

            if (this.steps[activeIndex].validator !== null) {
                return reservationStoreStore.runValidator(this.steps[activeIndex].validator as string);
            }

            return true;
        },
        stepNext() {
            if (!this.stepIsValid()) {
                return;
            }

            const nextIndex = this.steps.findIndex((s) => s.key === this.activeStep) + 1;

            if (typeof this.steps[nextIndex] !== 'undefined') {
                this.setStep(this.steps[nextIndex].key);
            }
        },
        stepPrev() {
            const prevIndex = this.steps.findIndex((s) => s.key === this.activeStep) - 1;

            if (typeof this.steps[prevIndex] !== 'undefined') {
                this.setStep(this.steps[prevIndex].key);
            }
        }
    },

    getters: {
        getInvoiceItems(): IInvioceListItem[] {
            const items = [] as IInvioceListItem[];
            return items;
        },

        getStepIsFirst(state): boolean {
            return state.steps.findIndex((s) => s.key === state.activeStep) === 0;
        },

        getStepIsLast(state): boolean {
            return state.steps.findIndex((s) => s.key === state.activeStep) === state.steps.length - 1;
        },

        getTotalPrice(): number {
            const total = 0;
            return total;
        },
        getTotalPriceFormatted(): string {
            return moneyFormat.humanReadableMoney(this.getTotalPrice);
        },

        getYoungDriverPackages(state): IResponse.ExtraItem[] {
            const youngDriverPackages = [] as IResponse.ExtraItem[];
            state.created?.youngDriverPackageCodes.forEach((youngDriverPackageCode: IResponse.YoungDriverPackageCode) => {
                state.created?.extras.forEach((extra: IResponse.Extra) => {
                    if (extra.key === youngDriverPackageCode.group) {
                        extra.items.forEach((item: IResponse.ExtraItem) => {
                            if (item.groupCode === youngDriverPackageCode.groupCode) {
                                youngDriverPackages.push(item);
                            }
                        });
                    }
                });
            });
            return youngDriverPackages;
        }
    },

    state: () =>
        ({
            activeStep: 'info',
            created: null,
            createdBefore: false,
            creating: {
                discountPriceStatus: false,
                dropAt: '',
                dropPointId: null,
                earlyPriceStatus: false,
                offerId: null,
                payAt: '',
                pickupAt: '',
                pickupPointId: 0,
                queryId: null,
                vehicleId: 0
            },
            dropDateTime: new Date(),
            dropDeliveryPoint: null,
            isActive: false,
            pickupDateTime: new Date(),
            pickupDeliveryPoint: null,
            steps: [
                {
                    isCompleted: true,
                    key: 'info',
                    name: i18n.global.t('reservation.create.stepTitle.info'),
                    validator: null
                },
                // {
                //     isCompleted: true,
                //     key: 'rentalPackages',
                //     name: i18n.global.t('reservation.create.stepTitle.rentalPackages'),
                //     validator: null
                // },
                {
                    isCompleted: true,
                    key: 'extras',
                    name: i18n.global.t('reservation.create.stepTitle.extras'),
                    validator: null
                },
                {
                    isCompleted: true,
                    key: 'payment',
                    name: i18n.global.t('reservation.create.stepTitle.payment'),
                    validator: null
                },
                {
                    isCompleted: true,
                    key: 'customerData',
                    name: i18n.global.t('reservation.create.stepTitle.customerData'),
                    validator: 'customer'
                },
                {
                    isCompleted: true,
                    key: 'noteAndPrivacy',
                    name: i18n.global.t('reservation.create.stepTitle.noteAndPrivacy'),
                    validator: 'privacy'
                }
            ]
        }) as PStore
});

export default useReservationCreateStore;
