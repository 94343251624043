<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import VAuthButtons from '@/components/Auth/Buttons.vue';
import VAuthMenus from '@/components/Auth/Menus.vue';
import VAuthResolver from '@/components/Auth/Resolver.vue';
import CurrencySwitcher from '@/components/CurrencySwitcher/Horizontal.vue';
import VLocaleSwitcherHorizontal from '@/components/LocaleSwitcher/Horizontal.vue';
import VSearchBar from '@/components/Search/Bar.vue';
import VIcon from '@/components/UI/VIcon.vue';
import { useCaptureThingsManager } from '@/composables/useCaptureThingsManager';
import useMenu from '@/composables/useMenu';
import appSettings from '@/definitions/settings.json';
import i18n from '@/locales/locale';
import emitter from '@/utils/emitter';
import { useAppStore } from '@/stores/app-store';

const appStore = useAppStore();
const { getHeaderMenuHeadItems, getFooterPoliciesMenuItems } = useMenu();
const ctm = useCaptureThingsManager();
const t = i18n.global.t;

enum IDialogTag {
    None = '',
    Home = 'home',
    Contact = 'contact',
    Action = 'action',
    Account = 'account',
    Menu = 'menu'
}
const currentDialogTag = ref<IDialogTag>(IDialogTag.None);
const currentDialogActive = ref<boolean>(false);
const handleMenuClick = (tag: IDialogTag) => {
    if (currentDialogTag.value === tag) {
        currentDialogTag.value = IDialogTag.None;
        setTimeout(() => {
            currentDialogTag.value = IDialogTag.None;
        }, 100);
    } else {
        currentDialogActive.value = false;
        currentDialogTag.value = tag;
        setTimeout(() => {
            currentDialogActive.value = true;
        }, 100);
    }
};

watch(currentDialogTag, (value) => {
    if (value === IDialogTag.None) {
        appStore.removeBackRouteBlocker('bottom-navigation-menu');
        document.body.classList.remove('bottom-navigation-menu-dialog-open');
    } else {
        appStore.addBackRouteBlocker({
            fn: () => {
                currentDialogTag.value = IDialogTag.None;
            },
            key: 'bottom-navigation-menu'
        });
        document.body.classList.add('bottom-navigation-menu-dialog-open');
    }
});

const handleOverlayClick = (e: MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains('bottom-navigation-menu-dialog-container')) {
        handleMenuClick(IDialogTag.None);
        // currentDialogTag.value = IDialogTag.None;
    }
};

// inferred as Emitter<Events>
onMounted(() => {
    emitter.on('open-bottom-navigaiton-menu-search-dialog', openHomeDialog);
});
onBeforeUnmount(() => {
    emitter.off('open-bottom-navigaiton-menu-search-dialog', openHomeDialog);
});

const openHomeDialog = () => {
    handleMenuClick(IDialogTag.Home);
};

const route = useRoute();
watch(
    () => route.fullPath,
    () => {
        handleMenuClick(IDialogTag.None);
    }
);

const getCompanyNumber = computed(() => {
    return appSettings.company.phoneNumber;
});
const getCompanyNumberLink = computed(() => {
    return 'tel:' + getCompanyNumber.value.replace(/[^0-9+]/g, '');
});
const handleCompanyNumberClick = () => {
    ctm.capturePhoneNumberClick('call', 'bottom-navigation', getCompanyNumber.value);
};

const getCompanyWhatsappNumber = computed(() => {
    return appSettings.company.whatsappNumber;
});
const getCompanyWhatsappNumberLink = computed(() => {
    const message = t('bottomNavigationMenu.contactDialog.whatsappMessage', {
        visitor: localStorage.getItem('visitor') || '0'
    });
    return 'https://wa.me/' + getCompanyWhatsappNumber.value.replace(/[^0-9+]/g, '') + '?text=' + encodeURIComponent(message);
});
const handleCompanyWhatsappNumberClick = () => {
    ctm.capturePhoneNumberClick('whatsapp', 'bottom-navigation', getCompanyWhatsappNumber.value);
};
const handleSocialLinkClick = (key: string, url: string) => {
    ctm.captureSocialLinkClick(key, 'bottom-navigation');
};
</script>

<template>
    <Suspense>
        <div class="v-mobile-bottom-menu">
            <ul class="nav">
                <li>
                    <div @click="handleMenuClick(IDialogTag.Home)">
                        <VIcon name="search-2-line" :size="30" />
                    </div>
                </li>
                <li>
                    <div @click="handleMenuClick(IDialogTag.Contact)">
                        <VIcon name="whatsapp-line" :size="30" />
                    </div>
                </li>
                <li class="action" :class="{ active: currentDialogTag !== IDialogTag.None }">
                    <div @click="handleMenuClick(IDialogTag.None)">
                        <VIcon name="close-fill" :size="30" />
                    </div>
                </li>
                <li>
                    <div @click="handleMenuClick(IDialogTag.Account)">
                        <VIcon name="user-fill" :size="30" />
                    </div>
                </li>
                <li>
                    <div @click="handleMenuClick(IDialogTag.Menu)">
                        <VIcon name="menu-fill" :size="30" />
                    </div>
                </li>
            </ul>

            <div class="bottom-navigation-menu-dialog-container" :class="{ active: currentDialogTag !== IDialogTag.None }" @click="handleOverlayClick">
                <!-- Search -->
                <div class="bottom-navigation-menu-dialog" :class="{ active: currentDialogActive }" v-if="currentDialogTag === IDialogTag.Home">
                    <VSearchBar jump-to-list-on-search @onSearch="handleMenuClick(IDialogTag.None)" :summaryVisible="false" />
                </div>

                <!-- Contact -->
                <div class="bottom-navigation-menu-dialog" :class="{ active: currentDialogActive }" v-if="currentDialogTag === IDialogTag.Contact">
                    <div class="contact-area">
                        <div class="item">
                            <div class="title">
                                {{ $t('bottomNavigationMenu.contactDialog.callCenter') }}
                            </div>
                            <a @click="handleCompanyNumberClick" class="contact-button contact-button-call-center" :href="getCompanyNumberLink" target="_blank" rel="nofollow">
                                {{ getCompanyNumber }}
                            </a>
                        </div>
                        <div class="item">
                            <div class="title">
                                {{ $t('bottomNavigationMenu.contactDialog.whatsapp') }}
                            </div>
                            <a @click="handleCompanyWhatsappNumberClick" class="contact-button contact-button-whatsapp" :href="getCompanyWhatsappNumberLink" target="_blank" rel="nofollow">
                                {{ getCompanyWhatsappNumber }}
                            </a>
                        </div>
                        <div class="item">
                            <div class="title">
                                {{ $t('bottomNavigationMenu.contactDialog.socialMedia') }}
                            </div>
                            <div class="social">
                                <template :key="socialLink.key" v-for="socialLink in appSettings.socialLinks">
                                    <a @click="handleSocialLinkClick(socialLink.key, socialLink.url)" :href="socialLink.url" target="_blank" rel="nofollow">
                                        <img :src="`${appStore.cdnUrl}/images/icons/icon-${socialLink.key}.svg`" :alt="socialLink.key" loading="lazy" />
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bottom-navigation-menu-dialog" :class="{ active: currentDialogActive }" v-if="currentDialogTag === IDialogTag.Account">
                    <VAuthResolver>
                        <template #auth>
                            <VAuthMenus />
                        </template>
                        <template #unauth>
                            <VAuthButtons vertical />
                        </template>
                    </VAuthResolver>
                </div>

                <div class="bottom-navigation-menu-dialog" :class="{ active: currentDialogActive }" v-if="currentDialogTag === IDialogTag.Menu">
                    <ul class="menu">
                        <li :key="i" v-for="(item, i) in getHeaderMenuHeadItems">
                            <router-link :to="item.url" @click="handleMenuClick(IDialogTag.None)">{{ item.label }}</router-link>
                        </li>
                    </ul>
                    <VLocaleSwitcherHorizontal />
                    <CurrencySwitcher />
                    <ul class="menu menu-inline">
                        <li :key="i" v-for="(item, i) in getFooterPoliciesMenuItems">
                            <router-link :to="item.url" @click="handleMenuClick(IDialogTag.None)">{{ item.label }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </Suspense>
</template>
