import { defineStore } from 'pinia';

import { useNodeStore } from '@/stores/node-store';

export interface ISubscriber {
    id: number;
    date: Date;
    object: any;
}

export interface IPageLoaderStore {
    loading: boolean;
    subscribedObjects: ISubscriber[];
    triggerDebounce: any;
}

export const usePageLoaderStore = defineStore('page-loader-store', {
    actions: {
        setLoading(loading: boolean) {
            this.loading = loading || useNodeStore().loading;
        },
        subscribe(object: any) {
            if (import.meta.env.SSR) return;
            const id = Math.random();
            this.subscribedObjects.push({
                date: new Date(),
                id,
                object
            });

            // setTimeout(() => {
            //     // remove this id element
            //     const index = this.subscribedObjects.findIndex((item) => item.id === id);
            //     if (index !== -1) {
            //         this.subscribedObjects.splice(index, 1);
            //     }
            // }, 2000);
        },
        unsubscribe(object: any) {
            if (import.meta.env.SSR) return;

            setTimeout(() => {
                const index = this.subscribedObjects.findIndex((item) => item.object === object);
                if (index !== -1) {
                    this.subscribedObjects.splice(index, 1);
                }
            }, 50);

            // if(this.triggerDebounce === null) {
            //     this.triggerDebounce = debounce(this.trigger, 100);
            // }
        }
    },

    getters: {
        isLoading(state) {
            return state.loading || state.subscribedObjects.length > 0;
        }
    },

    state: () =>
        ({
            loading: false,
            subscribedObjects: [],
            triggerDebounce: null
        }) as IPageLoaderStore
});
