import { defineStore } from 'pinia';

import { api } from '@/plugins/axios';
import type { IBlogNode } from '@/stores/blog-home-store';
import type { ApiAxiosResponse } from '@/types/general';

import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export type IAdvantages = {
    content: null | string;
    isExists: boolean;
    items: AdvantagesItem[];
    name: null | string;
    summary: null | string;
};

export type AdvantagesItem = {
    color: string;
    content: string;
    icon: null | string;
    title: string;
};

export type PurpleLink = {
    group: string;
    isExists: boolean;
    slug: null | string;
};

export type IBlogs = {
    content: null | string;
    isExists: boolean;
    items: IBlogNode[];
    name: null | string;
    summary: null | string;
};

export type Campaigns = {
    content: null | string;
    isExists: boolean;
    items: CampaignsItem[];
    name: null | string;
    summary: null | string;
};

export type CampaignsItem = {
    content: string;
    image: null | string;
    link: FluffyLink;
    name: string;
    orderRank: number;
    summary: null | string;
    [property: string]: any;
};

export type HeroSlide = {
    caption: null | string;
    image: null | string;
    link: {
        group: string;
        isExists: boolean;
        slug: null | string;
    };
    mode: 'image' | 'text' | 'mixed';
    orderRank: number;
    title: null | string;
};

export type FluffyLink = {
    group: string;
    isExists: boolean;
    slug: null | string;
};

export type ICities = {
    content: null | string;
    isExists: boolean;
    items: ICitiesItem[];
    name: null | string;
    summary: null | string;
};

export type ICitiesItem = {
    content: string;
    image: null | string;
    link: TentacledLink;
    name: string;
    orderRank: number;
};

export type TentacledLink = {
    group: string;
    isExists: boolean;
    slug: null | string;
};

export type Faqs = {
    content: null | string;
    isExists: boolean;
    name: null | string;
    summary: null | string;
    items: FaqItem[];
};

export type FaqItem = {
    title: string;
    content: string;
};

// [ReviewList]
export type IReviews = {
    content: null | string;
    isExists: boolean;
    items: IReviewItem[];
    name: null | string;
    summary: null | string;
};

export type IReviewItem = {
    content: string;
    name: string;
    orderRank: number;
    user: IReviewUser;
};

export type IReviewUser = {
    content: string;
    image: null | string;
    name: string;
};

// [Vehicles Showcases]
export type IVehicles = {
    content: null | string;
    isExists: boolean;
    items: IVehiclesItem[];
    name: null | string;
    summary: null | string;
};

export type IVehiclesItem = {
    content: string;
    image: null | string;
    link: StickyLink;
    name: string;
    orderRank: number;
};

// [Vehicles Showcases]
export type IAirports = {
    content: null | string;
    isExists: boolean;
    items: IAirportsItem[];
    name: null | string;
    summary: null | string;
};

export type IAirportsItem = {
    content: string;
    image: null | string;
    link: StickyLink;
    name: string;
    orderRank: number;
    deliveryPoint: IAirportsDeliveryPoint;
};
export type IAirportsDeliveryPoint = {
    isExists: boolean;
    latCode: null | string;
    longCode: null | string;
};

export type StickyLink = {
    group: string;
    isExists: boolean;
    slug: null | string;
};

export type SloganTexts = {
    caption: string | null;
    title: string | null;
};

export interface IPageHomeStore {
    advantages?: IAdvantages;
    airports?: IAirports;
    blogs: IBlogs;
    campaigns: Campaigns;
    cities: ICities;
    faqs: Faqs;
    parallaxImages: string[];
    reviews: IReviews;
    vehicles: IVehicles;
    sloganTexts: SloganTexts;
    heroSlides: HeroSlide[];
    loadedForKey: null | string;
    loaded: boolean;
    loading: boolean;
    loadedFullData: any;
    hasError: boolean;
}

export const usePageHomeStore = defineStore('page-home', {
    actions: {
        setData(value: IPageHomeStore) {
            this.$patch(value);
        },
        async setFormApi(loadedForKey: string): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;

            await api
                // .post('/blog/home-content', data)
                .post(
                    '/nodes/template-content',
                    Object.assign(
                        nodeStore.getTemplateContentPayload
                        // this.selectedPage > 0 ? { page: this.selectedPage } : {}
                    )
                )
                .then((response: ApiAxiosResponse<IPageHomeStore>) => {
                    this.$reset();

                    this.setData(
                        Object.assign(
                            {
                                loaded: true,
                                loadedForKey: loadedForKey,
                                loading: false
                            },
                            response.data.data
                        )
                    );
                    status = true;
                })
                .catch(() => {
                    this.$reset();

                    this.loaded = true;
                    this.loading = false;
                    status = false;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });

            return status;
        },
        async setFromApiIfNeed(loadedForKey: string): Promise<null | boolean> {
            if (loadedForKey === this.loadedForKey) {
                return null;
            }

            return await this.setFormApi(loadedForKey);
        }
    },

    getters: {
        getData(state) {
            return {
                advantages: state.advantages,
                airports: state.airports,
                blogs: state.blogs,
                campaigns: state.campaigns,
                cities: state.cities,
                faqs: state.faqs,
                heroSlides: state.heroSlides,
                loaded: state.loaded,
                loadedForKey: state.loadedForKey,
                loading: state.loading,
                parallaxImages: state.parallaxImages,
                reviews: state.reviews,
                vehicles: state.vehicles
            };
        }
    },

    state: () =>
        ({
            advantages: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            airports: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            blogs: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            campaigns: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            cities: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            faqs: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            hasError: false,
            heroSlides: [],
            loaded: false,
            loadedForKey: null,
            loadedFullData: null,
            loading: false,
            parallaxImages: [],
            reviews: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            },
            sloganTexts: {
                caption: null,
                title: null
            },
            vehicles: {
                content: null,
                isExists: false,
                items: [],
                name: null,
                summary: null
            }
        }) as IPageHomeStore
});
