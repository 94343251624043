<script lang="ts" setup>
import { computed } from 'vue';

import VAuthButtons from '@/components/Auth/Buttons.vue';
import VAuthResolver from '@/components/Auth/Resolver.vue';
import VSignedInDesktop from '@/components/Auth/SignedInDesktop.vue';
import VButton from '@/components/Forms/VButton.vue';
import VLocaleSwitcherDropdown from '@/components/LocaleSwitcher/Dropdown.vue';
import VIcon from '@/components/UI/VIcon.vue';
import { useCioRouter } from '@/composables/useCioRouter';
import useMenu from '@/composables/useMenu';
import screen from '@/plugins/screen';
import { useAppStore } from '@/stores/app-store';

const appStore = useAppStore();
const { getHeaderMenuHeadItems, getHeaderMenuMainItems } = useMenu();
const cioRouter = useCioRouter();

const mobileHeaderTitle = computed(() => appStore.getMobileHeaderTitle);

const goBackRoute = () => {
    if (appStore.getFirstBackRouteBlocker !== null) {
        appStore.getFirstBackRouteBlocker.fn();
        return;
    }

    if (appStore.backRoutePath === null) {
        return;
    }

    appStore.setBackRequestCall(true);
    cioRouter.openPath(appStore.backRoutePath);
};
</script>

<template>
    <Suspense>
        <header class="v-header" v-if="true">
            <div class="container">
                <div class="area">
                    <div class="left-area">
                        <div class="back">
                            <VButton @click="goBackRoute()" color="secondary" size="medium" variant="text" v-if="screen.isMobile && appStore.backRoutePath !== null">
                                <template v-slot:default>
                                    <VIcon name="arrow-left-s-line" :size="40" />
                                </template>
                            </VButton>
                            <!--<router-link @click="appStore.setBackRequestCall(true)" :to="{ path: appStore.backRoutePath }" v-if="screen.isMobile && appStore.backRoutePath !== null">-->
                            <!--<VIcon name="arrow-left-s-line" :size="40" />-->
                            <!--</router-link>-->
                        </div>
                        <div class="logo">
                            <!--<transition name="list"></transition>-->
                            <img v-cto="{ name: 'home', root: 'app' }" alt="Elitcar" src="https://elitcarrental.fullstaticcdn.com/images/logo-black.svg" loading="lazy" v-if="mobileHeaderTitle === null" />
                            <div class="mobile-title" v-else v-html="mobileHeaderTitle" />
                        </div>
                        <div class="logo-icon" v-if="mobileHeaderTitle !== null">
                            <img v-cto="{ name: 'home', root: 'app' }" alt="E" src="https://elitcarrental.fullstaticcdn.com/icons/icon-36.png" loading="lazy" />
                        </div>
                    </div>
                    <div class="right-area" v-if="screen.isDesktop">
                        <div class="top-area">
                            <ul class="top-menu">
                                <li :key="mIndex" v-for="(item, mIndex) in getHeaderMenuHeadItems">
                                    <router-link :to="item.url">{{ item.label }}</router-link>
                                </li>
                            </ul>
                            <VLocaleSwitcherDropdown />
                            <VAuthResolver>
                                <template #auth>
                                    <VSignedInDesktop />
                                </template>
                                <template #unauth>
                                    <VAuthButtons />
                                </template>
                            </VAuthResolver>
                        </div>
                        <div class="bottom-area">
                            <ul class="p-menu">
                                <li v-for="(item, i) in getHeaderMenuMainItems" :key="i">
                                    <span class="p-item-text" v-if="item.url === ''" v-text="item.label" />
                                    <router-link class="p-item-text" :to="item.url" v-else v-text="item.label" />
                                    <ul v-if="item.childs.length > 0">
                                        <li v-for="(child, childIndex) in item.childs" :key="childIndex">
                                            <span class="p-sub-item-text" v-if="child.url === ''">
                                                {{ child.label }}
                                                <VIcon name="arrow-right-s-line" :size="24" v-if="child.childs.length > 0" />
                                            </span>
                                            <router-link class="p-sub-item-text" :to="child.url" v-else>
                                                {{ child.label }}
                                                <VIcon name="arrow-right-s-line" :size="24" v-if="child.childs.length > 0" />
                                            </router-link>
                                            <ol v-if="child.childs.length > 0">
                                                <li v-for="(grandchild, grandchildIndex) in child.childs" :key="grandchildIndex">
                                                    <router-link :to="grandchild.url">
                                                        {{ grandchild.label }}
                                                    </router-link>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </Suspense>

    <header class="v-header" v-if="false">
        <div class="container">
            <div class="area">
                <div class="back">
                    <VButton @click="goBackRoute()" color="secondary" size="medium" variant="text" v-if="screen.isMobile && appStore.backRoutePath !== null">
                        <template v-slot:default>
                            <VIcon name="arrow-left-s-line" :size="40" />
                        </template>
                    </VButton>
                    <!--                        <router-link @click="appStore.setBackRequestCall(true)" :to="{ path: appStore.backRoutePath }" v-if="screen.isMobile && appStore.backRoutePath !== null">-->
                    <!--                            <VIcon name="arrow-left-s-line" :size="40" />-->
                    <!--                        </router-link>-->
                </div>
                <div class="logo">
                    <!--                        <transition name="list"></transition>-->
                    <img v-cto="{ name: 'home', root: 'app' }" alt="Elitcar" src="https://elitcarrental.fullstaticcdn.com/images/logo-black.svg" loading="lazy" v-if="mobileHeaderTitle === null" />
                    <div class="mobile-title" v-else v-html="mobileHeaderTitle" />
                </div>
                <div class="logo-icon" v-if="mobileHeaderTitle !== null">
                    <img v-cto="{ name: 'home', root: 'app' }" alt="E" src="https://elitcarrental.fullstaticcdn.com/icons/icon-36.png" loading="lazy" />
                </div>
                <template v-if="screen.isDesktop">
                    <div class="menu">
                        <ul>
                            <template :key="i" v-for="(item, i) in getHeaderMenuMainItems">
                                <li>
                                    <router-link :to="item.url">{{ item.label }}</router-link>
                                </li>
                            </template>
                        </ul>
                    </div>
                </template>
                <div class="right" v-if="screen.isDesktop">
                    <VLocaleSwitcherDropdown />
                    <VAuthResolver>
                        <template #auth>
                            <VSignedInDesktop />
                        </template>
                        <template #unauth>
                            <VAuthButtons />
                        </template>
                    </VAuthResolver>
                </div>
            </div>
        </div>
    </header>
</template>
