import useDeliveryPointStore from '@/stores/query/delivery-point-store';
import useDeliveryTimeStore from '@/stores/query/delivery-time-store';
import useFilterListStore from '@/stores/query/filter-list-store';
import useVehicleListStore from '@/stores/query/vehicle-list-store';
import type { ISearchFormPageTemplateContent } from '@/types/template/base';

export const setQueryFormParams = (data: ISearchFormPageTemplateContent) => {
    useDeliveryPointStore().setQueryParams(data.queryFormParams.pickupPointItem, data.queryFormParams.dropPointItem);
    useDeliveryTimeStore().setQueryParams(data.queryFormParams.pickupAt, data.queryFormParams.dropAt);

    if (data.queryFormParams.searchImmediately) {
        useVehicleListStore().fetchVehicles();
    }
    // useVehicleListStore().searchImmediately = data.queryFormParams.searchImmediately;

    useFilterListStore().requestedFilters = data.vehicleFilters;
};
