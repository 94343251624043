<script lang="ts" setup>
import {
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    type Ref,
    watch,
} from 'vue';

import parsePhoneNumber from 'libphonenumber-js';

import VTextInput from '@/components/Forms/VTextInput.vue';
import VIcon from '@/components/UI/VIcon.vue';
import countries from '@/definitions/countries.json';
import { useAppStore } from '@/stores/app-store';
import { getScrollContainerDirect } from '@/utils/scroll-helper';
import scrollToElement from '@/utils/scroll-to-element';

import VButton from './VButton.vue';

type ICountry = {
    code: string;
    phoneCode: string;
    translatedName: string;
    nativeName: string;
    displayName: string;
};

const props = withDefaults(
    defineProps<{
        label: string;
        confirmed?: boolean;
        error?: string;
        value?: string;
        required?: boolean;
        name?: string;
    }>(),
    {
        confirmed: false,
        error: undefined,
        label: '',
        name: undefined,
        required: false,
        value: undefined
    }
);

const emit = defineEmits(['input', 'blur', 'countryCodeChange']);

const appStore = useAppStore();
const phoneRootRef = ref<null | HTMLElement>(null);
const scrollContainerElement: Ref<null | Element> = ref(null);
const phoneCountryDialogRef = ref<null | Element>(null);
const phoneInputRef = ref<InstanceType<typeof VTextInput> | null>(null);
const phoneCountrySearchRef = ref<InstanceType<typeof VTextInput> | null>(null);
const getCountries = computed(() => {
    return countries
        .filter((country) => country.phoneCode != null)
        .map((country) => {
            let keys = Object.keys(country.translations);
            let values = Object.values(country.translations);
            let translatedName = '';
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] == appStore.locale.code) {
                    translatedName = values[i] as string;
                    break;
                }
            }
            return {
                code: country.code,
                displayName: translatedName || country.native,
                nativeName: country.native,
                phoneCode: country.phoneCode,
                translatedName: translatedName
            } as ICountry;
        });
});
const searchValue = ref<string>('');
const getFilteredCountryCodeList = computed(() => {
    let search = searchValue.value.toLocaleLowerCase();
    search = search.replace('+', '');

    return getCountries.value.filter((country) => {
        if (!searchValue.value) return true;
        if (country.nativeName && country.nativeName.toLocaleLowerCase().includes(search)) return true;
        if (country.translatedName && country.translatedName.toLocaleLowerCase().includes(search)) return true;
        if (country.phoneCode != null && country.phoneCode.includes(search)) return true;
        return false;
    });
});
const classes = computed(() => ({
    'v-phone': true,
    'v-phone--near-bottom': isNearBottom.value,
    'v-phone--show-country-code': props.value && activeCountryCode.value,
    'v-phone--show-dialog': showDialog.value
}));
const showDialog = ref<boolean>(false);
const isNearBottom = ref<boolean>(false);
const internalValue = ref('');
const activeCountryDialCode = ref<string>('');
const activeCountryCode = ref<string>('');

onMounted(() => {
    ensureActiveCountryCode();
    // phoneInputRef.value?.$el.addEventListener('focus', openDialogIfNeed);
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
    // phoneInputRef.value?.$el.removeEventListener('focus', openDialogIfNeed);
    appStore.removeBackRouteBlocker('phone-country-dialog');
});

watch(
    () => appStore.getCountryCode,
    () => {
        ensureActiveCountryCode();
    }
);

watch(
    () => props.value,
    (value, oldValue) => {
        if (value == oldValue) return;
        setInternalValue(value || '');
    }
);

watch(
    () => internalValue.value,
    (value, oldValue) => {
        if (value == oldValue) return;
        setInternalValue(value);
    }
);

watch(
    () => phoneCountrySearchRef.value,
    () => {
        searchValue.value = '';
        // phoneCountrySearchRef.value?.focus();
    }
);

watch(
    () => activeCountryCode.value,
    (value) => {
        emit('countryCodeChange', value);
    }
);

watch(showDialog, (showDialogValue) => {
    if (showDialogValue) {
        if (phoneRootRef.value) {
            phoneRootRef.value.style.setProperty('--phone-container-client-height', `${phoneRootRef.value.clientHeight}px`);
            scrollContainerElement.value = getScrollContainerDirect(phoneRootRef.value);
            calculateNearBottom();
        }

        appStore.addBackRouteBlocker({
            fn: () => {
                showDialog.value = false;
            },
            key: 'phone-country-dialog'
        });

        setTimeout(() => {
            if (phoneCountryDialogRef.value !== null) {
                scrollToElement(phoneCountryDialogRef.value, 0, true);
            }

            document.addEventListener('click', handleClickOutside);
        }, 100);
    } else {
        document.removeEventListener('click', handleClickOutside);
        appStore.removeBackRouteBlocker('phone-country-dialog');

        if (internalValue.value === '' && activeCountryDialCode.value !== '') {
            phoneInputRef.value?.focus();
        }
    }
});

const focus = () => {
    if (phoneRootRef.value) {
        scrollToElement(phoneRootRef.value, 0, true);
    }

    if (activeCountryDialCode.value === '') {
        showDialog.value = true;
    } else if (phoneInputRef.value !== null) {
        phoneInputRef.value.focus();
    }
};

const openDialogIfNeed = () => {
    if (activeCountryDialCode.value === '') {
        phoneInputRef.value?.blur();
        showDialog.value = true;
    }
};

const handleClickOutside = (event: Event) => {
    if (showDialog.value && phoneCountryDialogRef.value && !phoneCountryDialogRef.value.contains(event.target as Node)) {
        showDialog.value = false;
        emit('blur');
    }
};

const calculateNearBottom = () => {
    if (scrollContainerElement.value === null || phoneInputRef.value === null) {
        isNearBottom.value = false;
        return;
    }

    let rect = phoneInputRef.value ? phoneInputRef.value.$el.getBoundingClientRect() : { height: 0, top: 0 };
    let top = rect.bottom;
    isNearBottom.value = top > scrollContainerElement.value.clientHeight / 2;
};

const ensureActiveCountryCode = () => {
    if (!activeCountryDialCode.value) {
        let country = getCountries.value.find((c) => c.code == appStore.getCountryCode);
        if (country) {
            activeCountryDialCode.value = country.phoneCode || '';
            activeCountryCode.value = country.code || '';
        }
    }
};

let notValidInputTimer: null | number = null;

const setInternalValue = (value: string) => {
    let parsed = parsePhoneNumber(value);
    ensureActiveCountryCode();

    if (typeof parsed === 'undefined') {
        parsed = parsePhoneNumber(('+' + activeCountryDialCode.value + value).replace(/\s/g, ''));
    }

    if (typeof parsed === 'undefined') {
        if (notValidInputTimer !== null) {
            clearTimeout(notValidInputTimer);
        }

        // notValidInputTimer = window.setTimeout(() => {
        //     emit('input', '');
        // }, 500);
    } else {
        if (parsed.countryCallingCode) {
            activeCountryDialCode.value = parsed.countryCallingCode.toString();
            activeCountryCode.value = parsed.country || '';
        }
        let international = parsed.formatInternational();
        if (international.startsWith('+' + activeCountryDialCode.value)) {
            internalValue.value = international.replace('+' + activeCountryDialCode.value, '').trim();
        } else {
            internalValue.value = international;
        }
        // if(parsed.isValid()){
        emit('input', ('+' + activeCountryDialCode.value + internalValue.value).replace(/\s/g, ''));
        // }
    }

    if (!value) {
        internalValue.value = '';
        emit('input', '');
    }
};

const handleSelectCountryCode = (dialCode: string) => {
    activeCountryDialCode.value = dialCode;
    showDialog.value = false;
    setInternalValue(internalValue.value);
};

const handleSearchEnter = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (getFilteredCountryCodeList.value.length > 0) {
        handleSelectCountryCode(getFilteredCountryCodeList.value[0].phoneCode || '');
    }
};

defineExpose({
    focus
});
</script>

<template>
    <div :class="classes" ref="phoneRootRef">
        <div class="phone-input">
            <VTextInput
                ref="phoneInputRef"
                :label="props.label"
                :placeholder="$t('forms.phone.placeholder')"
                :name="props.name"
                autocomplete="tel"
                keyboardType="numeric"
                :required="props.required"
                :error="props.error"
                :confirmed="props.confirmed"
                fullWidth
                :value="internalValue"
                @focus="openDialogIfNeed"
                @input="internalValue = $event"
            />
        </div>
        <div class="country-dial-code-button" @click="showDialog = !showDialog">
            <span>+{{ activeCountryDialCode }}</span>
            <VIcon name="arrow-down-s-fill" :size="20" />
        </div>
        <div class="country-code" v-if="props.value && activeCountryCode">
            <span>{{ activeCountryCode }}</span>
        </div>
        <Transition name="to-down">
            <div class="country-dialog" v-if="showDialog" ref="phoneCountryDialogRef">
                <div class="dialog-heading">
                    <div class="heading-search">
                        <VTextInput
                            ref="phoneCountrySearchRef"
                            fullWidth
                            clearable
                            :value="searchValue"
                            @input="searchValue = $event"
                            :placeholder="$t('forms.phone.selectCountryCode')"
                            @keydownEnter="handleSearchEnter"
                        />
                    </div>
                    <div class="heading-close">
                        <VButton icon="close-line" @click="showDialog = false" variant="text" />
                    </div>
                </div>
                <div class="dialog-content">
                    <div class="countries">
                        <template v-for="country in getFilteredCountryCodeList" :key="country.code">
                            <div class="country" @click="handleSelectCountryCode(country.phoneCode)">
                                <span>+{{ country.phoneCode }}</span>
                                <span>{{ country.displayName }}</span>
                            </div>
                        </template>
                        <div class="noresult" v-if="getFilteredCountryCodeList.length == 0">
                            <span>{{ $t('forms.phone.noResult') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
