<script lang="ts" setup>
import useAuthStore from '@/stores/auth/auth-store';

const authStore = useAuthStore(); 
</script>

<template>
    <template v-if="authStore.loaded">
        <template v-if="authStore.isAuthed">
            <slot name="auth"></slot>
        </template>
        <template v-else>
            <slot name="unauth"></slot>
        </template>
    </template>
    <template v-else>
        <div class="v-skeleton">
            <div class="bone"></div>
        </div>
    </template>
</template>
