<script lang="ts" setup>
import VIcon from '@/components/UI/VIcon.vue';
import screen from '@/plugins/screen';
import useSearchBarStore from '@/stores/query/search-bar-store';
import { useAppStore } from '@/stores/app-store';
import { onBeforeMount, onBeforeUnmount } from 'vue';

const appStore = useAppStore();
const searchBarStore = useSearchBarStore();

onBeforeMount(() => {
    appStore.addBackRouteBlocker({
        fn: () => {
            searchBarStore.closeAllDialogs();
        },
        key: 'search-form-dialog'
    });
});

onBeforeUnmount(() => {
    appStore.removeBackRouteBlocker('search-form-dialog');
});
</script>

<template>
    <div class="v-search-dialog">
        <div class="dialog-heading">
            <div class="dialog-header">
                <div class="dialog-icon" v-if="$slots.icon">
                    <slot name="icon" />
                </div>
                <div class="dialog-title" v-if="$slots.title">
                    <slot name="title" />
                </div>
            </div>
            <div class="dialog-interaction" v-if="$slots.interaction">
                <slot name="interaction" />
            </div>
        </div>
        <div class="dialog-body" v-if="$slots.body">
            <slot name="body" />
        </div>
        <!-- eğer footer slotu varsa -->

        <div class="dialog-footer" v-if="$slots.footer">
            <slot name="footer" />
        </div>
        <div class="dialog-close" v-if="screen.isMobile" role="button" @click="searchBarStore.closeAllDialogs()">
            <VIcon name="close-line" />
        </div>
    </div>
</template>
