import { defineStore } from 'pinia';

import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';
import type { FilterGroup, FilterItem, FilterResponse } from '@/types/query/filter-list';
import { FilterSortMode } from '@/types/query/filter-list';

export interface FilterListStore {
    groups: FilterGroup[];
    loaded: boolean;
    loading: boolean;
    requestedFilters: {
        code: string;
        id: number;
    }[];
    sortMode: FilterSortMode;
}

const useFilterListStore = defineStore('filter-list-store', {
    actions: {
        loadFilters() {
            if (import.meta.env.SSR || this.loading) return; // TODO! disabled for ssr mode. maybe move to component
            this.loading = true;
            // const pageLoaderStore = usePageLoaderStore();
            // pageLoaderStore.subscribe(this);
            api.get('/query/filters')
                .then((response: ApiAxiosResponse<FilterResponse>) => {
                    const groups = response.data.data.staticFilters;
                    this.$patch({ groups });

                    this.requestedFilters.forEach((requested) => {
                        const group = groups.find((g) => g.code === requested.code);
                        if (group === undefined) return;
                        const item = group.items.find((i) => i.id === requested.id);
                        if (item === undefined) return;
                        this.updateFilter(group, item, true);
                    });
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                    // pageLoaderStore.unsubscribe(this);
                });
        },
        switchSortMode() {
            // switch mode to next, if its last then go to first
            const modes = Object.values(FilterSortMode);
            const currentIndex = modes.indexOf(this.sortMode);
            const nextIndex = currentIndex + 1 >= modes.length ? 0 : currentIndex + 1;
            this.sortMode = modes[nextIndex] as FilterSortMode;
        },
        updateFilter(group: FilterGroup, item: FilterItem, value: boolean) {
            if (group.isAllowMultiple === false) {
                group.items.forEach((i: FilterItem) => {
                    i.isSelected = false;
                });
            }
            item.isSelected = value;
        }
    },

    getters: {
        getFilters(state) {
            return state.groups;
        },
        getSortMode(state) {
            return state.sortMode;
        },
        isLoaded(state) {
            return state.loaded;
        }
    },

    state: () =>
        ({
            groups: [] as FilterGroup[],
            loaded: false,
            loading: false,
            requestedFilters: [] as { code: string; id: number }[],
            sortMode: FilterSortMode.SMART
        }) as FilterListStore
});

export default useFilterListStore;
