import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import useAuthStore from '@/stores/auth/auth-store';
import useAuthModalStore from '@/stores/auth/modal-store';
import type * as IRequest from '@/types/auth/login/request';
import type * as IResponse from '@/types/auth/login/response';
import type { ApiAxiosResponse } from '@/types/general';

interface PStore {
    isLoading: boolean;
    payload: IRequest.Payload;
    isModalOpened: boolean;
}
const useLoginStore = defineStore('login-store', {
    actions: {
        closeModal() {
            this.isModalOpened = false;
        },

        login() {
            this.isLoading = true;
            api.post('/auth/login', this.payload)
                .then((response: ApiAxiosResponse<IResponse.Data>) => {
                    if (response.data.status_code === 200) {
                        useAuthStore().setLogin({
                            accessToken: response.data.data.accessToken,
                            authProvider: response.data.data.authProvider,
                            tokenType: response.data.data.tokenType
                        });
                        useAuthModalStore().closeLoginModal();
                    } else {
                        // TODO! ne oluyor?
                    }
                })
                .catch(() => {
                    // this lines deprecated because error messages moved to axios plugin
                    // let message = error.response.data.message;
                    // if(error.response.status === 401){
                    //     message = i18n.global.t('auth.login.error');
                    // }
                    // toast(message, {
                    //     autoClose: 1000,
                    //     type: 'error',
                    // });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        openModal() {
            this.isModalOpened = true;
        },

        setPayloadEmail(email: string) {
            this.payload.email = email;
        },

        setPayloadPassword(password: string) {
            this.payload.password = password;
        }
    },

    getters: {
        getPayloadEmail(state) {
            return state.payload.email;
        },
        getPayloadPassword(state) {
            return state.payload.password;
        }
    },

    state: () =>
        ({
            isLoading: false,
            isModalOpened: false,
            payload: {
                email: '',
                password: ''
            }
        }) as PStore
});

export default useLoginStore;
