<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, type Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import CurrencySwitcher from '@/components/CurrencySwitcher/Horizontal.vue';
import VIcon from '@/components/UI/VIcon.vue';
import { type IRouterLocalizedLocation, useAppStore } from '@/stores/app-store';
import emitter from '@/utils/emitter';

const appStore = useAppStore();
const alternateLocaleLinks: Ref<IRouterLocalizedLocation[]> = ref(appStore.routerLocalizedLocations);
const route = useRoute();

if (import.meta.env.SSR) {
    const check = async () => {
        let c = 0;
        while (appStore.routerLocalizedLocations.length === 0 && c < 250) {
            await new Promise((resolve) => setTimeout(resolve, 10));
            c++;
        }
        return appStore.routerLocalizedLocations;
    };
    alternateLocaleLinks.value = await check();
}
watch(
    () => appStore.routerLocalizedLocations,
    (value) => {
        alternateLocaleLinks.value = value;
    }
);

// define show dropdown
const showDialog = ref(false);
// main element html node ref by typscirpt
const elementRef = ref<any>(null);

const handleTrigger = (event: Event) => {
    event.preventDefault();
    showDialog.value = !showDialog.value;
};
const handleClose = (event: Event) => {
    event.preventDefault();
    showDialog.value = false;
};
const handleClickOutside = (event: Event) => {
    // event.preventDefault();
    if (elementRef.value && !elementRef.value.contains(event.target)) {
        showDialog.value = false;
    }
};

// add click event listener to document
onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    emitter.on('locale-code:changed', closeDialog);
    emitter.on('currency:change', closeDialog);
});

// remove click event listener to document
onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
    emitter.off('locale-code:changed', closeDialog);
    emitter.off('currency:change', closeDialog);
});

watch(route, () => {
    showDialog.value = false;
});

const getActiveLocaleCode = computed(() => appStore?.locale?.code || 'tr');

const closeDialog = () => {
    showDialog.value = false;
};

const getAlternateLocaleLinks = computed(() => {
    let links: IRouterLocalizedLocation[] = [];
    alternateLocaleLinks.value.forEach((link) => {
        links.push({
            ...link,
            route: {
                ...link.route,
                query: route.query
            }
        });
    });
    return links;
});

const classes = computed(() => ({
    'show-dialog': showDialog.value,
    'v-locale-switcher-dropdown': true
}));
</script>

<template>
    <div :class="classes" ref="elementRef">
        <div class="current-locale" @click="handleTrigger">
            <img :src="`${appStore.cdnUrl}/images/flags/${getActiveLocaleCode}.svg`" loading="lazy" />
            <div class="locale-label">{{ getActiveLocaleCode }}</div>
            <VIcon name="arrow-down-s-line" :size="24" />
        </div>

        <div class="locales-dialog">
            <div class="dialog-title">{{ $t('directives.localeSwitcher.selectLanguage') }}</div>
            <div class="locales">
                <template v-for="link in getAlternateLocaleLinks" :key="link.code">
                    <router-link :to="link.route" :class="{ locale: true, active: getActiveLocaleCode === link.code }">
                        <img :src="`${appStore.cdnUrl}/images/flags/${link.code}.svg`" loading="lazy" />
                        <div class="locale-label">{{ link.code }}</div>
                        <div class="locale-radio"></div>
                    </router-link>
                </template>
            </div>
            <CurrencySwitcher />
        </div>
    </div>
</template>
