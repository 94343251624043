import moment from 'moment/min/moment-with-locales';

import { useAppStore } from '@/stores/app-store';

export const toDate = (value: string | Date | null | undefined, format?: string | null | undefined): Date => {
    return moment(value, format || 'YYYY-MM-DD HH:mm').toDate();
};

export const toString = (value: Date | null | undefined, format?: string | null | undefined): string => {
    if (!value) return '';
    return moment(value).format(format || 'YYYY-MM-DD HH:mm');
};

export const humanReadableDate = (value: Date | string | null | undefined, format?: string | null | undefined, formatTo?: string | null | undefined): string => {
    const locale = useAppStore().locale.code;
    if (!value) return '';
    const m = moment(value, format || 'YYYY-MM-DD HH:mm');
    if (!m.isValid()) return '';
    return m.locale(locale).format(formatTo || 'DD MMMM YYYY HH:mm');
};

// HH:mm to minutes
export const clockToMinutes = (value: string): number => {
    return moment.duration(value).asMinutes();
};
// minutes to HH:mm
export const minutesToClock = (value: number): string => {
    return moment().startOf('day').add(value, 'minutes').format('HH:mm');
};
// round minutes to nearest 5, round number, force forward
export const roundMinutes = (value: number, round: number = 30, forward: boolean = true): number => {
    const mod = value % round;
    if (mod === 0) return value;
    return forward ? value + (round - mod) : value - mod;
};

export default {
    humanReadableDate,
    toDate,
    toString
};
