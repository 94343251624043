import { defineStore } from 'pinia';

interface PStore {
    isLoginModalOpened: boolean;
    isPasswordResetModalOpened: boolean;
    isPasswordRestoreModalOpened: boolean;
    isRegisterModalOpened: boolean;
}

const useAuthModalStore = defineStore('auth-modal-store', {
    actions: {
        closeAll() {
            this.isLoginModalOpened = false;
            this.isRegisterModalOpened = false;
            this.isPasswordResetModalOpened = false;
            this.isPasswordRestoreModalOpened = false;
        },
        closeLoginModal() {
            this.isLoginModalOpened = false;
        },
        closePasswordResetModal() {
            this.isPasswordResetModalOpened = false;
        },
        closePasswordRestoreModal() {
            this.isPasswordRestoreModalOpened = false;
        },
        closeRegisterModal() {
            this.isRegisterModalOpened = false;
        },
        openLoginModal() {
            this.isLoginModalOpened = true;
        },
        openPasswordResetModal() {
            this.isPasswordResetModalOpened = true;
        },
        openPasswordRestoreModal() {
            this.isPasswordRestoreModalOpened = true;
        },
        openRegisterModal() {
            this.isRegisterModalOpened = true;
        }
    },

    getters: {
        getAnyModalIsOpen(state: PStore): boolean {
            return state.isLoginModalOpened || state.isPasswordResetModalOpened || state.isPasswordRestoreModalOpened || state.isRegisterModalOpened;
        }
    },

    state: () =>
        ({
            isLoginModalOpened: false,
            isPasswordResetModalOpened: false,
            isPasswordRestoreModalOpened: false,
            isRegisterModalOpened: false
        }) as PStore
});

export default useAuthModalStore;
