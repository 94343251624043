import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';

import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export interface IPageOfficeDefaultTemplateContentResponse {
    image: null | string;
    latCode: null | string;
    longCode: null | string;
    phoneNumber: null | string;
    address: null | string;
    email: null | string;
    openHours: null | string;
    type: null | string;
    location: null | string;
}

export interface IPageOfficeDefaultStore extends IPageOfficeDefaultTemplateContentResponse {
    loaded: boolean;
    loading: boolean;
}

export const usePageOfficeDefaultStore = defineStore('page-office-default', {
    actions: {
        async setFormApi(): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<IPageOfficeDefaultTemplateContentResponse>) => {
                    this.$reset();

                    this.$patch(
                        Object.assign(
                            {
                                loaded: true,
                                loading: false
                            },
                            response.data.data
                        )
                    );
                    status = true;
                })
                .catch(() => {
                    this.$reset();

                    this.loaded = true;
                    this.loading = false;
                    status = false;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });

            return status;
        }
    },

    getters: {
        getData(state) {
            return state;
        }
    },

    state: () =>
        ({
            address: null,
            email: null,
            image: null,
            latCode: null,
            loaded: false,
            loadedForKey: null,
            loading: false,
            location: null,
            longCode: null,
            openHours: null,
            phoneNumber: null,
            type: null
        }) as IPageOfficeDefaultStore
});
