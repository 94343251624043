import { defineStore } from 'pinia';

import { api } from '@/plugins/axios';
import type { ApiAxiosResponse } from '@/types/general';

import { useNodeStore } from './node-store';
import { usePageLoaderStore } from './page-loader-store';

export interface Priorities {
    isExists: boolean;
    name: string;
    summary: string;
    content: string;
    items: Item[];
}

export interface Item {
    icon: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface WhatDoWeItem {
    icon: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface VisionMissionItem {
    image: string;
    title: string;
    content: string;
    orderRank: number;
}

export interface BottomSection {
    name: string;
    content: string;
    orderRank: number;
}

export interface IPageAboutIndexTemplateContentResponse {
    priorities: Priorities;
    whatDoWeItems: WhatDoWeItem[];
    visionMissionItems: VisionMissionItem[];
    bottomSections: BottomSection[];
    image: string;
}

export interface IPageAboutIndexStore extends IPageAboutIndexTemplateContentResponse {
    loaded: boolean;
    loadedForKey: null | string;
    loading: boolean;
}

export const usePageAboutIndexStore = defineStore('page-about', {
    actions: {
        async setFormApi(loadedForKey: string): Promise<boolean> {
            const nodeStore = useNodeStore();
            const pageLoaderStore = usePageLoaderStore();
            pageLoaderStore.subscribe(this);
            let status = false;
            this.loading = true;

            await api
                .post('/nodes/template-content', nodeStore.getTemplateContentPayload)
                .then((response: ApiAxiosResponse<IPageAboutIndexStore>) => {
                    this.$reset();

                    this.$patch(
                        Object.assign(
                            {
                                loaded: true,
                                loadedForKey: loadedForKey,
                                loading: false
                            },
                            response.data.data
                        )
                    );
                    status = true;
                })
                .catch(() => {
                    this.$reset();

                    this.loaded = true;
                    this.loading = false;
                    status = false;
                })
                .finally(() => {
                    pageLoaderStore.unsubscribe(this);
                });

            return status;
        },
        async setFromApiIfNeed(loadedForKey: string): Promise<null | boolean> {
            if (loadedForKey === this.loadedForKey) {
                return null;
            }

            return await this.setFormApi(loadedForKey);
        }
    },

    getters: {
        getData(state) {
            return state;
        }
    },

    state: () =>
        ({
            bottomSections: [],
            image: '',
            loaded: false,
            loadedForKey: null,
            loading: false,
            priorities: {
                content: '',
                isExists: false,
                items: [],
                name: '',
                summary: ''
            },
            visionMissionItems: [],
            whatDoWeItems: []
        }) as IPageAboutIndexStore
});
