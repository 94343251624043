export interface FilterItem {
    id: number,
    orderRank: number,
    name: string,
    description: string,
    
    isSelected: boolean,
}; 

export interface FilterGroup {
    code: string,
    name: string,
    isAllowMultiple: boolean,
    items: FilterItem[],
}; 

export interface Filter {
    groups: FilterGroup[]; 
    isLoaded: boolean;
    isLoading: boolean;
}

export interface FilterResponse {
    staticFilters: FilterGroup[]; 
};

export enum FilterSortMode {
    SMART = 'smart',
    PRICE_ASC = 'priceAsc',
}