<script lang="ts" setup>
import { ref, watch } from 'vue';
import parsePhoneNumber from 'libphonenumber-js';
import { useRoute, useRouter } from 'vue-router';
import * as yup from 'yup';
import VPhone from '@/components/Forms/Phone.vue';
import VButton from '@/components/Forms/VButton.vue';
import VTextInput from '@/components/Forms/VTextInput.vue';
import VModal from '@/components/Modal/Modal.vue';
import i18n from '@/locales/locale';
import useLoginStore from '@/stores/auth/login-store';
import useFirebaseLoginStore from '@/stores/auth/firebase-login-store';
import useAuthModalStore from '@/stores/auth/modal-store';
import usePasswordResetStore from '@/stores/auth/password-reset-store';
import usePasswordRestoreStore from '@/stores/auth/password-restore-store';
import useRegisterStore from '@/stores/auth/register-store';
import { Validator, YupTest } from '@/utils/validator';
import { useAppStore } from '@/stores/app-store';

const appStore = useAppStore();
const authModalStore = useAuthModalStore();
const registerStore = useRegisterStore();
const loginStore = useLoginStore();
const firebaseLoginStore = useFirebaseLoginStore();
const passwordResetStore = usePasswordResetStore();
const passwordRestoreStore = usePasswordRestoreStore();
const t = i18n.global.t;
const route = useRoute();
const router = useRouter();

// Login From
const loginEmailComponent = ref<Element | null>(null);
const loginPasswordComponent = ref<Element | null>(null);
const loginValidator = new Validator({
    email: {
        component: loginEmailComponent,
        test: () => YupTest(yup.string().email(t('auth.login.validations.email-invalid')).required(t('auth.login.validations.email-required')), loginStore.getPayloadEmail)
    },
    password: {
        component: loginPasswordComponent,
        test: () => YupTest(yup.string().required(t('auth.login.validations.password-required')).min(8, t('auth.login.validations.password-length')), loginStore.getPayloadPassword)
    }
});

watch(
    () => authModalStore.getAnyModalIsOpen,
    (anyModalIsOpenValue) => {
        if (anyModalIsOpenValue) {
            appStore.addBackRouteBlocker({
                fn: () => {
                    authModalStore.closeAll();
                },
                key: 'modal'
            });
        } else {
            appStore.removeBackRouteBlocker('modal');
        }
    }
);

watch(
    () => authModalStore.isLoginModalOpened,
    () => {
        loginStore.setPayloadEmail('');
        loginStore.setPayloadPassword('');
        loginValidator.reset();
    }
);

const onLoginFormSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    if (loginValidator.validate()) {
        loginStore.login();
    }
};
// Login To

// Register From
const registerFirstNameComponent = ref<Element | null>(null);
const registerLastNameComponent = ref<Element | null>(null);
const registerEmailComponent = ref<Element | null>(null);
const registerPasswordComponent = ref<Element | null>(null);
const registerRePasswordComponent = ref<Element | null>(null);
const registerPhoneNumberComponent = ref<Element | null>(null);
const registerValidator = new Validator({
    email: {
        component: registerEmailComponent,
        test: () => YupTest(yup.string().email(t('auth.register.validations.email-invalid')).required(t('auth.register.validations.email-required')), registerStore.getPayloadEmail)
    },
    firstName: {
        component: registerFirstNameComponent,
        test: () => YupTest(yup.string().required(t('auth.register.validations.firstName-required')), registerStore.getPayloadFirstName)
    },
    lastName: {
        component: registerLastNameComponent,
        test: () => YupTest(yup.string().required(t('auth.register.validations.lastName-required')), registerStore.getPayloadLastName)
    },
    password: {
        component: registerPasswordComponent,
        test: () => YupTest(yup.string().required(t('auth.register.validations.password-required')).min(8, t('auth.register.validations.password-length')), registerStore.getPayloadPassword)
    },
    phoneNumber: {
        component: registerPhoneNumberComponent,
        test: () =>
            YupTest(
                yup
                    .string()
                    .required(t('auth.register.validations.phoneNumber-required'))
                    .test('phone-number', t('auth.register.validations.phoneNumber-invalid'), (value) => {
                        const phoneNumber = parsePhoneNumber(value);
                        if (phoneNumber && phoneNumber.isValid()) {
                            return true;
                        }
                        return false;
                    }),
                registerStore.getPayloadPhoneNumber
            )
    },
    rePassword: {
        component: registerRePasswordComponent,
        test: () =>
            YupTest(
                yup.string().required(t('auth.register.validations.rePassword-required')).oneOf([registerStore.getPayloadPassword], t('auth.register.validations.rePassword-match')),
                registerStore.getPayloadRePassword
            )
    }
});

watch(
    () => authModalStore.isRegisterModalOpened,
    () => {
        registerStore.setPayloadFirstName('');
        registerStore.setPayloadLastName('');
        registerStore.setPayloadEmail('');
        registerStore.setPayloadPassword('');
        registerStore.setPayloadRePassword('');
        registerStore.setPayloadPhoneNumber('');
        registerValidator.reset();
    }
);

const onRegisterFormSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    if (registerValidator.validate()) {
        registerStore.register();
    }
};
// Register To

// Password Reset From
const passwordResetEmailComponent = ref<Element | null>(null);
const passwordResetValidator = new Validator({
    email: {
        component: passwordResetEmailComponent,
        test: () => YupTest(yup.string().email(t('auth.passwordReset.validations.email-invalid')).required(t('auth.passwordReset.validations.email-required')), passwordResetStore.getPayloadEmail)
    }
});

watch(
    () => authModalStore.isPasswordResetModalOpened,
    () => {
        passwordResetStore.setPayloadEmail('');
        passwordResetValidator.reset();
    }
);

const onPasswordResetFormSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    if (passwordResetValidator.validate()) {
        passwordResetStore.passwordReset();
    }
};
// Password Reset To

// Password Restore From
const passwordRestoreEmailComponent = ref<Element | null>(null);
const passwordRestorePasswordComponent = ref<Element | null>(null);
const passwordRestoreRePasswordComponent = ref<Element | null>(null);
const passwordRestoreValidator = new Validator({
    email: {
        component: passwordRestoreEmailComponent,
        test: () => YupTest(yup.string().email(t('auth.passwordRestore.validations.email-invalid')).required(t('auth.passwordRestore.validations.email-required')), passwordRestoreStore.getPayloadEmail)
    },
    password: {
        component: registerPasswordComponent,
        test: () => YupTest(yup.string().required(t('auth.passwordRestore.validations.password-required')).min(8, t('auth.passwordRestore.validations.password-length')), passwordRestoreStore.getPayloadPassword)
    },
    rePassword: {
        component: registerRePasswordComponent,
        test: () =>
            YupTest(
                yup.string().required(t('auth.passwordRestore.validations.rePassword-required')).oneOf([passwordRestoreStore.getPayloadPassword], t('auth.passwordRestore.validations.rePassword-match')),
                passwordRestoreStore.getPayloadRePassword
            )
    }
});

const onPasswordRestoreFormSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    if (passwordRestoreValidator.validate()) {
        passwordRestoreStore.passwordRestore().then(() => {
            router.replace({ path: route.path, query: {} });
        });
    }
};
</script>
<template>
    <!-- Login Modal From -->
    <VModal :active="authModalStore.isLoginModalOpened" @close="authModalStore.closeLoginModal">
        <template #header>
            <h3>{{ $t('auth.login.title') }}</h3>
        </template>
        <form method="post" action="" @submit="onLoginFormSubmit" class="space-y-6">
            <VTextInput
                ref="loginEmailComponent"
                :label="$t('auth.login.email')"
                type="email"
                name="email"
                :placeholder="$t('auth.login.email')"
                autocomplete="on"
                required
                :value="loginStore.getPayloadEmail"
                @input="
                    loginStore.setPayloadEmail($event);
                    loginValidator.run();
                "
                @blur="loginValidator.enableValidation('email')"
                full-width
                :disabled="loginStore.isLoading"
                :error="loginValidator.errors.value?.email"
            />

            <VTextInput
                ref="loginPasswordComponent"
                :label="$t('auth.login.password')"
                type="password"
                name="password"
                :placeholder="$t('auth.login.password')"
                autocomplete="current-password"
                required
                :value="loginStore.getPayloadPassword"
                @input="
                    loginStore.setPayloadPassword($event);
                    loginValidator.run();
                "
                @blur="loginValidator.enableValidation('password')"
                full-width
                :disabled="loginStore.isLoading"
                :error="loginValidator.errors.value?.password"
            />
            <VButton :label="$t('auth.login.passwordReset')" type="button" color="secondary" variant="text" @click="authModalStore.openPasswordResetModal" />

            <VButton :label="$t('auth.login.login')" type="submit" fullWidth :loading="loginStore.isLoading" />
            <input type="hidden" name="autocomplete" value="on" />
            <input type="hidden" name="autofocus" value="email" />
        </form>
        <hr />
        <VButton
            @click="firebaseLoginStore.loginWithApple"
            :label="$t('auth.login.loginWithApple')"
            type="button"
            fillWidth
            :loading="firebaseLoginStore.isLoading"
            prepend-icon="apple-fill"
            variant="tonal"
            v-if="appStore.capacitor.platform !== 'android'"
        />
        <hr />
        <VButton
            @click="firebaseLoginStore.loginWithGoogle"
            :label="$t('auth.login.loginWithGoogle')"
            type="button"
            fillWidth
            :loading="firebaseLoginStore.isLoading"
            prepend-icon="google-fill"
            variant="tonal"
        />
        <hr />
        <VButton
            @click="firebaseLoginStore.loginWithFacebook"
            :label="$t('auth.login.loginWithFacebook')"
            type="button"
            fillWidth
            :loading="firebaseLoginStore.isLoading"
            prepend-icon="facebook-fill"
            variant="tonal"
        />
    </VModal>
    <!-- Login Modal To -->

    <!-- Register Modal From -->
    <VModal :active="authModalStore.isRegisterModalOpened" @close="authModalStore.closeRegisterModal">
        <template #header>
            <h3>{{ $t('auth.register.title') }}</h3>
        </template>
        <form method="post" action="" @submit="onRegisterFormSubmit" class="space-y-6">
            <VTextInput
                ref="registerFirstNameComponent"
                :label="$t('auth.register.firstName')"
                type="text"
                name="firstName"
                :placeholder="$t('auth.register.firstName')"
                autocomplete="on"
                required
                full-width
                :value="registerStore.getPayloadFirstName"
                @input="
                    registerStore.setPayloadFirstName($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('firstName')"
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.firstName"
            />
            <VTextInput
                ref="registerLastNameComponent"
                :label="$t('auth.register.lastName')"
                type="text"
                name="lastName"
                :placeholder="$t('auth.register.lastName')"
                autocomplete="on"
                required
                full-width
                :value="registerStore.getPayloadLastName"
                @input="
                    registerStore.setPayloadLastName($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('lastName')"
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.lastName"
            />
            <VTextInput
                ref="registerEmailComponent"
                :label="$t('auth.register.email')"
                type="email"
                name="email"
                :placeholder="$t('auth.register.email')"
                autocomplete="email"
                keyboardType="email"
                required
                full-width
                :value="registerStore.getPayloadEmail"
                @input="
                    registerStore.setPayloadEmail($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('email')"
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.email"
            />
            <VTextInput
                ref="registerPasswordComponent"
                :label="$t('auth.register.password')"
                type="password"
                name="password"
                :placeholder="$t('auth.register.password')"
                autocomplete="current-password"
                required
                :value="registerStore.getPayloadPassword"
                @input="
                    registerStore.setPayloadPassword($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('password')"
                full-width
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.password"
            />
            <VTextInput
                ref="registerRePasswordComponent"
                :label="$t('auth.register.rePassword')"
                type="password"
                name="rePassword"
                :placeholder="$t('auth.register.rePassword')"
                autocomplete="current-password"
                required
                :value="registerStore.getPayloadRePassword"
                @input="
                    registerStore.setPayloadRePassword($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('rePassword')"
                full-width
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.rePassword"
            />
            <VPhone
                ref="registerPhoneNumberComponent"
                :label="$t('auth.register.phoneNumber')"
                name="phone"
                :placeholder="$t('auth.register.phoneNumber')"
                required
                :value="registerStore.getPayloadPhoneNumber"
                @input="
                    registerStore.setPayloadPhoneNumber($event);
                    registerValidator.run();
                "
                @blur="registerValidator.enableValidation('phoneNumber')"
                full-width
                :disabled="registerStore.isLoading"
                :error="registerValidator.errors.value?.phoneNumber"
            />
            <VButton :label="$t('auth.register.register')" type="submit" full-width :loading="registerStore.isLoading" />
            <input type="hidden" name="autocomplete" value="on" />
            <input type="hidden" name="autofocus" value="email" />
        </form>
    </VModal>
    <!-- Register Modal To -->

    <!-- Password Reset Modal From -->
    <VModal :active="authModalStore.isPasswordResetModalOpened" @close="authModalStore.closePasswordResetModal">
        <template #header>
            <h3>{{ $t('auth.passwordReset.title') }}</h3>
        </template>
        <form method="post" action="" @submit="onPasswordResetFormSubmit" class="space-y-6">
            <VTextInput
                ref="passwordResetEmailComponent"
                :label="$t('auth.passwordReset.email')"
                type="email"
                name="email"
                :placeholder="$t('auth.passwordReset.email')"
                autocomplete="email"
                keyboardType="email"
                required
                full-width
                :value="passwordResetStore.getPayloadEmail"
                @input="
                    passwordResetStore.setPayloadEmail($event);
                    passwordResetValidator.run();
                "
                @blur="passwordResetValidator.enableValidation('email')"
                :disabled="passwordResetStore.isLoading"
                :error="passwordResetValidator.errors.value?.email"
            />
            <VButton :label="$t('auth.passwordReset.passwordReset')" type="submit" full-width :loading="passwordResetStore.isLoading" />
            <input type="hidden" name="autocomplete" value="on" />
            <input type="hidden" name="autofocus" value="email" />
        </form>
    </VModal>
    <!-- Password Reset Modal To -->

    <!-- Password Restore Modal From -->
    <VModal :active="authModalStore.isPasswordRestoreModalOpened" @close="authModalStore.closePasswordRestoreModal">
        <template #header>
            <h3>{{ $t('auth.passwordRestore.title') }}</h3>
        </template>
        <form method="post" action="" @submit="onPasswordRestoreFormSubmit" class="space-y-6">
            <VTextInput
                ref="passwordRestoreEmailComponent"
                :label="$t('auth.passwordRestore.email')"
                type="email"
                name="email"
                :placeholder="$t('auth.passwordRestore.email')"
                autocomplete="email"
                keyboardType="email"
                required
                full-width
                :readonly="passwordRestoreStore.emailFieldIsDisabled"
                :value="passwordRestoreStore.getPayloadEmail"
                @input="
                    passwordRestoreStore.setPayloadEmail($event);
                    passwordRestoreValidator.run();
                "
                @blur="passwordRestoreValidator.enableValidation('email')"
                :error="passwordRestoreValidator.errors.value?.email"
                :disabled="passwordRestoreStore.emailFieldIsDisabled"
            />
            <VTextInput
                ref="passwordRestorePasswordComponent"
                :label="$t('auth.passwordRestore.password')"
                type="password"
                name="password"
                :placeholder="$t('auth.register.password')"
                autocomplete="current-password"
                required
                :value="passwordRestoreStore.getPayloadPassword"
                @input="
                    passwordRestoreStore.setPayloadPassword($event);
                    passwordRestoreValidator.run();
                "
                @blur="passwordRestoreValidator.enableValidation('password')"
                full-width
                :disabled="passwordRestoreStore.isLoading"
                :error="passwordRestoreValidator.errors.value?.password"
            />
            <VTextInput
                ref="passwordRestoreRePasswordComponent"
                :label="$t('auth.passwordRestore.rePassword')"
                type="password"
                name="rePassword"
                :placeholder="$t('auth.passwordRestore.rePassword')"
                autocomplete="current-password"
                required
                :value="passwordRestoreStore.getPayloadRePassword"
                @input="
                    passwordRestoreStore.setPayloadRePassword($event);
                    passwordRestoreValidator.run();
                "
                @blur="passwordRestoreValidator.enableValidation('rePassword')"
                full-width
                :disabled="passwordRestoreStore.isLoading"
                :error="passwordRestoreValidator.errors.value?.rePassword"
            />
            <VButton :label="$t('auth.passwordRestore.passwordRestore')" type="submit" full-width :loading="passwordRestoreStore.isLoading" />
            <input type="hidden" name="autocomplete" value="on" />
            <input type="hidden" name="autofocus" value="email" />
        </form>
    </VModal>
    <!-- Password Restore Modal To -->
</template>
