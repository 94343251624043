<script lang="ts" setup>
import { computed } from 'vue';

import VIcon from '@/components/UI/VIcon.vue';

const props = withDefaults(
    defineProps<{
        icon?: string | null;
        message?: string | null;
        noborder?: boolean | null;
        noicon?: boolean | null;
        type?: string | null;
    }>(),
    {
        icon: null,
        message: null,
        noborder: false,
        noicon: false,
        type: 'info'
    }
);

const icon = computed(() => {
    if (props.icon) {
        return props.icon;
    }
    switch (props.type) {
        case 'success':
            return 'checkbox-circle-fill';
        case 'info':
            return 'information-line';
        case 'warning':
            return 'alert-fill';
        case 'error':
            return 'spam-2-fill';
    }
});

const classes = computed(() => ({
    'has-icon': !props.icon,
    noborder: props.noborder,
    'v-alert': true,
    [`type-${props.type}`]: true
}));
</script>

<template>
    <div :class="classes">
        <VIcon :size="36" v-if="!noicon" :name="icon" />
        <div class="message">
            <slot>
                <div v-html="message" />
            </slot>
        </div>
    </div>
</template>
