import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import useAuthStore from '@/stores/auth/auth-store';
import type * as IRequest from '@/types/auth/firebase-login/request';
import type * as IResponse from '@/types/auth/firebase-login/response';
import type { ApiAxiosResponse } from '@/types/general';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import type { SignInResult } from '@capacitor-firebase/authentication/dist/esm/definitions';
import useAuthModalStore from '@/stores/auth/modal-store';

interface PStore {
    isLoading: boolean;
    payload: IRequest.Payload;
}

const useFirebaseLoginStore = defineStore('firebase-login-store', {
    actions: {
        clearPayload() {
            Object.assign(this.payload, {
                displayName: null,
                email: null,
                emailVerified: false,
                idToken: null,
                isAnonymous: false,
                isLogin: false,
                phoneNumber: null,
                provider: null,
                uid: null
            });
        },

        async loginSetFirebaseOnApi() {
            this.isLoading = true;
            const idTokenRequest = await FirebaseAuthentication.getIdToken();
            this.payload.idToken = idTokenRequest.token;

            api.post('/auth/firebase-login', this.payload)
                .then(async (response: ApiAxiosResponse<IResponse.Data>) => {
                    if (response.data.status_code === 200) {
                        useAuthStore().setLogin({
                            accessToken: response.data.data.accessToken,
                            authProvider: response.data.data.authProvider,
                            tokenType: response.data.data.tokenType
                        });
                        useAuthModalStore().closeLoginModal();
                    } else {
                        // TODO! ne oluyor?
                        await FirebaseAuthentication.signOut();
                    }
                })
                .catch(async () => {
                    await FirebaseAuthentication.signOut();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        async loginWithApple() {
            this.clearPayload();
            this.isLoading = true;

            try {
                const result = await FirebaseAuthentication.signInWithApple({ scopes: ['email'] });
                this.setPayloadViaResult('apple', result);
                setTimeout(() => {
                    this.loginSetFirebaseOnApi();
                }, 100);
            } catch (error) {
                // TODO! show error message - vazgeçme durumu da ayrı gösterilmeli
            }

            this.isLoading = false;
        },

        async loginWithFacebook() {
            this.clearPayload();
            this.isLoading = true;

            try {
                const result = await FirebaseAuthentication.signInWithFacebook({ scopes: ['email'] });
                this.setPayloadViaResult('facebook', result);
                setTimeout(() => {
                    this.loginSetFirebaseOnApi();
                }, 100);
            } catch (error) {
                // TODO! show error message - vazgeçme durumu da ayrı gösterilmeli
            }

            this.isLoading = false;
        },

        async loginWithGoogle() {
            this.clearPayload();
            this.isLoading = true;

            try {
                const result = await FirebaseAuthentication.signInWithGoogle({ scopes: ['email'] });
                this.setPayloadViaResult('google', result);
                setTimeout(() => {
                    this.loginSetFirebaseOnApi();
                }, 100);
            } catch (error) {
                // TODO! show error message - vazgeçme durumu da ayrı gösterilmeli
            }

            this.isLoading = false;
        },

        setPayloadViaResult(provider: IRequest.TProvider, firebaseResult: SignInResult) {
            if (firebaseResult.user === null) {
                this.clearPayload();
                return;
            }

            Object.assign(this.payload, {
                displayName: firebaseResult.user.displayName,
                email: firebaseResult.user.email, //  || firebaseResult.additionalUserInfo?.profile?.email || null
                emailVerified: firebaseResult.user.emailVerified,
                idToken: firebaseResult.credential?.idToken || null,
                isAnonymous: firebaseResult.user.isAnonymous,
                isLogin: true,
                phoneNumber: firebaseResult.user.phoneNumber,
                provider: provider,
                uid: firebaseResult.user.uid
            });
        }
    },

    getters: {},

    state: () =>
        ({
            isLoading: false,
            payload: {
                displayName: null,
                email: null,
                emailVerified: false,
                idToken: null,
                isAnonymous: true,
                isLogin: false,
                phoneNumber: null,
                provider: null,
                uid: null
            }
        }) as PStore
});

export default useFirebaseLoginStore;
